/* eslint-disable no-fallthrough */

import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Logger, I18n } from 'aws-amplify';
import { GenericTable } from './../tables';
import { nullableStringPropValidator, nullableObjectPropValidator } from '../../../js/localUtils';
import { thresholdTypeFromDataType } from '../defaults';

const TwoResultTable = ({ data, type, thresholdData }) => {
  const logger = new Logger('TwoResultTable');
  const numToShow = 7;
  if (!data || !type || !thresholdData) return null;

  logger.debug(`type: ${type}`);

  const thresholdResponses = [
    { key: 'above', customClass: 'outThreshold', icon: (<span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M91,78.21,55.06,16a5.84,5.84,0,0,0-10.12,0L9,78.21A5.84,5.84,0,0,0,14.05,87H86A5.84,5.84,0,0,0,91,78.21Zm-37.1-2A5.29,5.29,0,0,1,50,77.54a5.43,5.43,0,0,1-3.94-1.35,6,6,0,0,1,0-7.64,5.52,5.52,0,0,1,4-1.3,5.35,5.35,0,0,1,3.89,1.33,5.88,5.88,0,0,1,0,7.58ZM53.75,62H46.37L44.82,32.46H55.3Z" fill="#F7931E" /></svg></span>) },
    { key: 'below', customClass: 'outThreshold', icon: (<span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M91,78.21,55.06,16a5.84,5.84,0,0,0-10.12,0L9,78.21A5.84,5.84,0,0,0,14.05,87H86A5.84,5.84,0,0,0,91,78.21Zm-37.1-2A5.29,5.29,0,0,1,50,77.54a5.43,5.43,0,0,1-3.94-1.35,6,6,0,0,1,0-7.64,5.52,5.52,0,0,1,4-1.3,5.35,5.35,0,0,1,3.89,1.33,5.88,5.88,0,0,1,0,7.58ZM53.75,62H46.37L44.82,32.46H55.3Z" fill="#F7931E" /></svg></span>) },
    //{ key: 'within', customClass: 'inThreshold', icon: (<span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><title>Icon</title><path d="M35.63,87.1,18.75,90.6a46.33,46.33,0,0,0-7.46,2A1.38,1.38,0,0,1,9.4,91.32q0-19.36,0-38.72a1.37,1.37,0,0,1,.92-1.3c5.66-1.87,11.93-1.85,17.26-4.53.63-.32,1.4-.67,2-.28a1.9,1.9,0,0,1,.63,1C34.47,59.42,38,71.8,37.86,84.43a2.86,2.86,0,0,1-.44,1.85,3,3,0,0,1-1.79.82" fill="#39B54A"/><path d="M38,83c6.32.64,18.33,2.72,24.62,1.92,2.75-.36,12.71-.58,13.53-.25a8.31,8.31,0,0,0,10.51-9.33,1.07,1.07,0,0,1,.51-1.07A7.18,7.18,0,0,0,88.6,63.12,1,1,0,0,1,88.41,62a9.64,9.64,0,0,0-.48-9.13c-1.26-2,.13-4.59.38-7a7.38,7.38,0,0,0-6.07-7.77c-2.36-.67-6,.92-8.38,1.3-5.11.82-8.56.24-13.54-1.22-2-.59-4.42-2-3.6-4.27A3.59,3.59,0,0,1,58.48,32c6.81-3.68,11.71-11.66,10.14-19.27a7.28,7.28,0,0,0-2.88-4.83c-1.63-1-4.14-.76-5.07.94A6.92,6.92,0,0,0,60.1,11a16.08,16.08,0,0,1-3.79,7.81c-3.44,3.86-8.54,5.86-12.18,9.52C38.67,33.81,35.59,44,29.81,49.11" fill="none" stroke="#39B54A" strokeLinecap="square" strokeMiterlimit="1" strokeWidth="5"/></svg></span>) }
    { key: 'within', customClass: 'inThreshold', icon: (<span></span>) }

  ];

  const vitalsInterpretation = function (typeValue, typeThreshold) {

    if (typeThreshold) {
      if (typeValue > typeThreshold.maximum) {
        return thresholdResponses[0];
      }
      if (typeValue < typeThreshold.minimum) {
        return thresholdResponses[1];
      } else { return thresholdResponses[2]; }

    } else {
      return 0;
    }
  } //vitalsInterpretation
  //temporary variables to immutably map over the data passed in as a prop
  let _data, caseUnit, interpretation;
  _data = {
    item: [],
    fullName: '',
  };

  // [[LOCALIZATION REQUIRED]]
  switch (type) {
    case 'heartRate':
    case 'pulseox':
    case 'pulse oximeter':
    case 'oxygen levels':
      _data.fullName = 'Pulse Ox';

      case 'Pulsoximeter':
      _data.fullName = 'Pulsoximeter';

      Object.keys(data.oxygen.slice(-1 * numToShow)).forEach(key => {
        key = data.oxygen.length - 1 - key;
        interpretation = vitalsInterpretation(data.oxygen[key].value, thresholdData.Oxygen);
        if (interpretation.key === 'within') {
          interpretation = vitalsInterpretation(data.heartRate[key].value, thresholdData.BPM);
        }
        _data.item.push({
          value: [
            { key: '', value: data.oxygen[key].value, unit: '% -' },
            { key: '', value: data.heartRate[key].value, unit: ' BPM' }],
          timestamp: data.oxygen[key].timestamp,
          dateTime: checkDate(data.oxygen[key].timestamp),
          icon: interpretation.icon,
          customClass: interpretation.customClass
        })
      })

      break;
    case 'bloodpressure':
    case 'blood pressure':
      _data.fullName = 'Blood Pressure';
      
      case 'Blutdruck':
        _data.fullName = 'Blutdruck';

      Object.keys(data.systolic.slice(-1 * numToShow)).forEach(key => {
        key = data.systolic.length - 1 - key;
        interpretation = vitalsInterpretation(data.systolic[key].value, thresholdData.Systolic);
        if (interpretation.key === 'within')
          interpretation = vitalsInterpretation(data.diastolic[key].value, thresholdData.Diastolic);
        _data.item.push({
          value: [
            { key: '', value: data.systolic[key].value, unit: ' /' },
            { key: '', value: data.diastolic[key].value, unit: '' }
          ],
          timestamp: data.systolic[key].timestamp,
          dateTime: checkDate(data.systolic[key].timestamp),
          icon: interpretation.icon,
          customClass: interpretation.customClass
        })
      });

      break;
    case 'temperature':
    case 'thermometer':
      caseUnit = '°F';
    case 'weight':
      if (!caseUnit) {
        caseUnit = 'lbs';
      }
    case 'glucose':
      if (!caseUnit) {
        caseUnit = 'mg/dL';
      }
    default: 
      let caseName = type[0].toUpperCase() + type.slice(1);
      _data.fullName = caseName;

      Object.keys(data[type].slice(-1 * numToShow)).forEach(key => { 
        key = data[type].length - 1 - key;
        interpretation = vitalsInterpretation(data[type][key].value, thresholdData[thresholdTypeFromDataType(type)]);
        _data.item.push({
          value: [
            { key: '', value: data[type][key].value, unit: caseUnit },
          ],
          timestamp: data[type][key].timestamp,
          dateTime: checkDate(data[type][key].timestamp),
          icon: interpretation.icon,
          customClass: interpretation.customClass
        });
      });
      break;

  }// end switch

  const headings = {
    customClass: "TBodyandTRclass",
    items: [
      { value: `${I18n.get('Day')}, ${I18n.get('Month')}` },
      { customClass: 'textCenter', value: `${I18n.get('Time')}` },
      { customClass: 'textRight', value: `${I18n.get('Result')}` },
      { value: '' },
    ]
  };
  const rows = _data.item.map(_item => {
    let cellClass = _item.value.length > 1 ? "resultDoubleValue" : "resultValue";
    let aVitalResult = `${_item.value[0].key}${_item.value[0].value}${_item.value[0].unit}`;
    _item.value.slice(1).forEach(anotherResult => {
      aVitalResult += `
      ${anotherResult.key}${anotherResult.value}${anotherResult.unit}`;
    });
    let rowItems = {
      customClass: _item.customClass,
      items: [
        { value: _item.dateTime.date },
        { customClass: 'textCenter tableCellWidth6', value: _item.dateTime.time },
        { customClass: `textRight ${cellClass}`, value: aVitalResult },
        { customClass: 'tableIcon', value: _item.icon }
      ]
    };

    return { customClass: _item.customClass, items: rowItems };
  });

  return (
    <div className="ContainerHistoryWrapper">
      <div className="ContainerHistory ContainerGradient VitalHistorySize">
        <div className="TitleVital">{_data.fullName} {I18n.get('History')}</div>
        <hr className="LineTitle" />
        <div className="tableWrapper">
        <GenericTable
          headings={headings}
          rows={rows}
        />
        </div>
      </div>
      <div className="ContainerHistoryBlur ActualBlur VitalHistoryBlurSize"></div>
    </div>
  );
};

function checkDate(timestamp) {
  const localOffset = moment.parseZone(moment().local()).utcOffset();  //this returns utc offset number - for mountain returns -420 (7 hours worth of Minutes) 
  return {
    date: moment(timestamp).utcOffset(localOffset).format('ddd, MMM DD'),
    time: moment(timestamp).utcOffset(localOffset).format('h:mm A')
  };

}//end of check date function

TwoResultTable.propTypes = {
  data: nullableObjectPropValidator,
  type: nullableStringPropValidator,
  isListening: PropTypes.bool.isRequired,
};

export default TwoResultTable;
