import {AFKAnimationBehavior, MedButtonPressBehavior} from '.';
import store from '../_GlobalStateStore/GlobalStateStore';

export class BehaviorManager {
  behaviors = [];

  constructor() {
    // this.behaviors.push(new AFKAnimationBehavior());
    this.behaviors.push(new MedButtonPressBehavior());

    AFKAnimationBehavior.setup();

    this.behaviors.forEach(behavior => {
      behavior.setupListeners();
      store.getActions().pushBehavior(behavior);
    });
  }
}
