import axios from 'axios';
import { Hub, Logger } from 'aws-amplify';
import store from '../src/_GlobalStateStore/GlobalStateStore';

// from selectors.js
// TODO: move this to a common file path
const toFahrenheit = kelvin => Math.round((kelvin - 273) * (9 / 5) + 32);
// const toCelsius = kelvin => Math.round(kelvin - 273);

const getWeather = results => results && results.data;

const getCondition = weatherResults => weatherResults
  && weatherResults.weather
  && weatherResults.weather[0]
  && weatherResults.weather[0].main;

const getTemperature = weatherResults => weatherResults
  && weatherResults.main
  && weatherResults.main.temp
  && toFahrenheit(weatherResults.main.temp);

//  if(store.getState().primaryLanguage = "en" || "en-US" || "en-GB" || "en-AU")  {
//   let getTemperature = weatherResults => weatherResults
//   && weatherResults.main
//   && weatherResults.main.temp
//   && toFahrenheit(weatherResults.main.temp);
//  }
//   else {
//   let getTemperature = weatherResults => weatherResults
//   && weatherResults.main
//   && weatherResults.main.temp
//   && toCelsius(weatherResults.main.temp);
//   }	
	

const logger = new Logger('WeatherManager');

const weatherURL = 'https://api.openweathermap.org/data/2.5/weather';
const key = 'ec409bfc2bb12171fa45b9ddbb822fa0'; // TODO: rotate key and move this behind an API request (necessary for free API??)


export class WeatherManager {
  constructor() {
    WeatherManager.getLocation().then(() => {
      WeatherManager.getWeather();
    });

    // update every five minutes
    this.intervalId = setInterval(() => {
      WeatherManager.getWeather();
    }, 300000);
  }

  static getLocation() {
    return new Promise((res, rej) => {
      navigator.geolocation.getCurrentPosition((position) => {
        const positionObject = {};
        if ('coords' in position) {
          positionObject.coords = {};

          if ('latitude' in position.coords) {
            positionObject.coords.latitude = position.coords.latitude;
          }
          if ('longitude' in position.coords) {
            positionObject.coords.longitude = position.coords.longitude;
          }
        }
        WeatherManager.latitude = positionObject.coords.latitude;
        WeatherManager.longitude = positionObject.coords.longitude;
        console.log('WeatherManager.longitude: ', WeatherManager.longitude);
        res({
          latitude: WeatherManager.latitude,
          longitude: WeatherManager.longitude,
        });
      }, (err) => {
        rej(err);
      });
    });
  }

  static async getWeather() {
    try {
      const { longitude, latitude } = await WeatherManager.getLocation();
      const params = {
        params: {
          lat: latitude,
          lon: longitude,
          appid: key,

        },
      };

      const res = await axios.get(weatherURL, params);

      if (!getWeather(res)) {
        logger.error('failed to get weather data');
        return;
      }

      const weather = getWeather(res);
      const condition = getCondition(weather);
      this.condition = condition;
      logger.debug('condition: ', this.condition);

      if (condition === 'Clear') {
        Hub.dispatch('cancelWeather');
      } else {
        Hub.dispatch('startWeather', { data: condition });
      }

      return condition;
    } catch (e) {
      // log and suppress
      logger.debug('error getting weather from OpenWeatherMap: ', e);
    }
  }

  static getCurrentCondition() {
    return this.condition;
  }
}
