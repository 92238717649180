import * as BABYLON from 'babylonjs';
import { Hub, Logger } from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore'


export async function initCuckooClock(scene) {
    const logger = new Logger('initCuckooClock.js');

    // Clock Mesh
    const clockGeo = scene.getMeshByName("CuckooClock_Geo")
    clockGeo.alwaysSelectAsActiveMesh = true


    // Clock animation groups
    const clockIntro = scene.getAnimationGroupByName("clockIntro")
    const clockLoop = scene.getAnimationGroupByName("clockLoop");
    const clockOutro = scene.getAnimationGroupByName("clockOutro");
    clockIntro.stop();

    
    // Clock Hands Functions
    let currentHours = new Date().getHours();
    let currentMinutes = new Date().getMinutes();

    var minutesHand = scene.getBoneByName("MinutesHand_JNT").getTransformNode();
    var hoursHand = scene.getBoneByName("HoursHand_JNT").getTransformNode();


    // Cuckoo Clock Alarm Sound
    const cuckooClockAlarm = new BABYLON.Sound("",
        "https://cdn.ecg-health.com/assets/sounds/cuckooClockAlarm_v01.wav", scene, null, {
        loop: true,
        autoplay: false
    });


    // Clock animation
    const _cuckooClockStart = () => {
        clockLoop.stop();
        clockIntro.play(false);
        cuckooClockAlarm.stop();
        BABYLON.Quaternion.FromEulerAnglesToRef(0, 0, -currentMinutes * 0.1047198, minutesHand.rotationQuaternion)
        BABYLON.Quaternion.FromEulerAnglesToRef(0, 0, (-currentHours - currentMinutes / 60) * 0.523599, hoursHand.rotationQuaternion)
    };
    const _cuckooClockLoop = () => {
        clockLoop.play(true);
        cuckooClockAlarm.stop();
        cuckooClockAlarm.play();
    };
    const _cuckooClockStop = () => {
        clockLoop.stop();
        clockOutro.play(false);
        cuckooClockAlarm.stop();
    };

    const _cuckooClock = async (capsule) => {
        const { payload } = capsule;

        logger.debug(`Turning cuckoo clock ${payload.data}`)

        switch (payload.data) {
            case 'start':
                _cuckooClockStart();
                break;
            case 'loop':
                _cuckooClockLoop();
                break;
            case 'stop':
                _cuckooClockStop();
                break;
            default:
                logger.debug('Unlisted cuckoo clock state :', payload.data);
        }
    }


    // Clock listener
    Hub.listen('cuckooClock', _cuckooClock);

}