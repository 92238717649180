import { I18n } from 'aws-amplify';

const dict = {
    de: {
        'blood pressure' : 'Blutdruck',
        'oxygen levels' : 'Pulsoximeter',
        'History' : 'Anzeigen',
        'history' : 'anzeigen',
        'retake' : 'wiederholen',
        'Retake' : 'Wiederholen',

        'Day' : 'Wochentag',
        'day' : 'Wochentag',
        'Month' : 'Monat',
        'month' : 'Monat',
        'Time' : 'Zeit',
        'time' : 'Zeit',
        'Date' : 'Datum',
        'date' : 'Datum',
        
        'Result': 'Ergebnisse',
        'result': 'Ergebnisse',

        'degree' : 'Grad',
        'degrees' : 'Grad',
        'Degree' : 'Grad',
        'Degrees' : 'Grad',
        'celsius' : 'Celsius', 
        'Celsius' : 'Celsius',
        
        'Percent' : 'Prozent',
        'percent' : 'Prozent',
        'Over' : 'über',
        'over' : 'über',

        'Beats per Minute' : 'Schläge pro Minute',
        'BPM' : 'SpM',
        'bpm' : 'SpM',
    }  
};

export function initDict() {
    I18n.putVocabularies(dict);
}