import { Logger } from 'aws-amplify';
import { autoCreds } from '../CredentialsModule';

// set global state to the customerID and Name according to Auth data
export const setCustomerStateInfo = async () => {
    const logger = new Logger('SetCustomerStateInfo');
    // await autoCreds
    //   .deploy("app")
    //   .then(async () => {
    //     logger.debug("autoCreds deployed");
    //     // const user = await changecurrentUser(getUser(logger));
    //     // logger.debug('user', user);
    //   })
    //   .catch((err) => {
    //     logger.error("autoCreds deployment threw an error: ", err);
    //   });
  };
  