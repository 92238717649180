import { map, pluck, filter, take } from 'rxjs/operators';
import { Parser } from 'binary-parser';
import round from 'lodash/fp/round';
import fpMap from 'lodash/fp/map';
import compose from 'lodash/fp/compose';
import{ makeBluetoothHook, keepObjSum} from '../js';

/*
 * Service ID, and Characteristic ID for PulseOx
 */
const serviceId = 'cdeacb80-5235-4c07-8846-93a37ee6b86d';
const characteristicId = 'cdeacb81-5235-4c07-8846-93a37ee6b86d';
const numSamples = 18;

/*
 * Parses one emission from the pulseOx bluetooth device
 *
 */

const pulseOxParser = new Parser()
  .endianess('big')
  .uint8('dataType')
  .choice('parsedData', {
    tag: 'dataType',
    choices: {
      129: new Parser().endianess('big').uint8('pulseRate').uint8('o2Sat').uint8('perf'),
    },
    defaultChoice: new Parser().endianess('big'),
  });
const parsePulseOx = (binary) => pulseOxParser.parse(binary);

/*
 * The case for when the data is empty (see docs)
 *
 */
const notNull = ({ pulseRate, o2Sat, perf }) =>
  pulseRate !== 255 && o2Sat !== 127 && perf !== 0;

/*
 * The pipeline for how the data goes from,
 * Bluetooth -> Summed Reading
 *
 */
export const readPulseOx = (btData$) =>
  btData$.pipe(
    pluck('target'),
    pluck('value'),
    map(parsePulseOx),
    filter((data) => data.dataType === 129),
    pluck('parsedData'),
    filter(notNull),
    take(numSamples),
    keepObjSum({ pulseRate: 0, o2Sat: 0, perf: 0 }),
  );

/*
 * Formatting the final emission from the observable
 * { pulseRate, o2Sat, perf } -> [mean(pulseRate), mean(o2Sat), mean(perf)]
 *
 */
const total = ([tot]) => tot;
const length = ([, lastId]) => lastId + 1;
const mean = (a) => total(a) / length(a);
const format = fpMap(compose(round, mean));
export const formatAverages = ({ pulseRate, o2Sat, perf }) =>
  format([pulseRate, o2Sat, perf]);

export const getPercent = ({ pulseRate }) =>
  (pulseRate[1] + 1) / numSamples;

/*
 * The Custom Hook
 */
const usePulseOx = makeBluetoothHook({
  formatter: formatAverages,
  reader: readPulseOx,
  characteristicId,
  getPercent,
  serviceId,
});

export default usePulseOx;