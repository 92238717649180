import { Hub, Logger } from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore';
import { ElementManagerSetup } from '../elements';

const logger = new Logger('App');

ElementManagerSetup.setup();

export function getSceneLoaded() {
  const sceneLoaded = async () => {
    try {
      // defer creating the activity manager until we have a reference to the sceneController
      // const activityManager = new ActivityManager();
      // activityManager.putSceneController(newSceneController);

      try {
        if (!store.getState().user.userData) await store.getActions().user.pullUserData();
      } catch (e) {
        logger.warn('error pulling user model');
      }
      logger.debug('currentUser', store.getState().user.userData.id);

      // if this is the first run, go to the calibration and power on scenes
      // else continue to the home scene
      Hub.listen('loadInitialScene', () => {
        if (
          store.getState().user.userData.firstRun !== null
          && store.getState().user.userData.firstRun
        ) {
          Hub.dispatch('loadSceneByName', 'PowerOn');
        } else {
          Hub.dispatch('notFirstRun');
          Hub.dispatch('loadSceneByName', 'Home');
        }
      });
    } catch (e) {
      logger.error('[APP] sceneLoaded() ERROR', e);
    }
  };
  return sceneLoaded;
}
