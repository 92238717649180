import React, {useEffect} from 'react';
import {Location, Router} from '@reach/router';
import AWS from 'aws-sdk';
import {Auth, API, Logger, Hub, graphqlOperation, signInButton} from 'aws-amplify';

import {LexRuntime} from 'aws-sdk';
import {useStoreState, useStoreActions} from 'easy-peasy';
import {AppScene, CameraViewer, AppSplashScreen } from './containers';
import {
  ElementManager,
  SceneVitals,
  Home,
  AppMessages,
  AppScreenFader,
  AppDialer,
  MedicationCompliance
} from './components'; // eslint-disable-line import/named
import {setHomePath, onRouteChange} from './js';
import store from './_GlobalStateStore/GlobalStateStore';
// import ActivityManager from "./ActivityManager";
import {
  requestAudio,
  requestLocation,
  getSceneLoaded,
  setSubscription,
  setCustomerStateInfo,
  initConnect,
  initMedicationSnoozeHandler,
  initPolly,
  getVoiceFromLanguageCode,
  disableTTS,
  enableTTS
} from './js';
import { getUser as getUserQuery } from './_GlobalStateStore/custom_graphql/getUser';
import getVitalsData from './components/SceneVitals/getVitalsData';

import { Captions } from './components/AssetsScene';
// import MedicationSchedulingFlow from './MedReminderManager';

import './styles/styles.scss';
import './App.css';

const logger = new Logger('App');

requestAudio();
requestLocation();

// TODO: move to preloaded state
store.getActions().setLexRuntime(new LexRuntime({ region: window.ADDISON_REGION }));



// _effect();

/**
 * The main driver of the application. Renders all of the React components in the scene,
 * as well as the canvas for the Babylon engine. Also handles all initialization logic
 * with react hooks.
 * @param {React.Props} props
 */
const App = props => {
  logger.debug('App.props: ', props);
  const currentUser = useStoreState(state => state.user.userData);
  const pullUserData = useStoreActions(actions => actions.user.pullUserData);
  const [sceneLoaded, setSceneLoaded] = React.useState(() => {});
  const [snoozeCleanup, setSnoozeCleanup] = React.useState();

  const _effect = async () => {
    logger.debug('_effect');
    const pullUserData = async () => {
      const token = await Auth.currentSession();
      const creds = await Auth.currentCredentials();
      const id = token.idToken.payload['cognito:username'];
      logger.debug('in PullUserData');
      logger.debug('[GlobalStateStore] id =', id);
      try {
        const results = await API.graphql(graphqlOperation(getUserQuery, {id}));
        logger.debug('results of pullUserData: ', results);
  
        store.getActions().user.pullUserDataSuccess(results.data.getUser);
        logger.debug('data: ', results);
      } catch (e) {
        logger.warn('[GlobalStateStore] pullUserData failed', e);
      }
      // const getDemographics = (group) => {
      // const token = Auth.currentSession();
      logger.debug('Before options');
      const options = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token.idToken.jwtToken,
          id: token.idToken.payload['cognito:username'],
        },
      };
      logger.debug('past Options');
      // fetch customers
      return fetch(
        'https://m4bnyqp68l.execute-api.us-east-1.amazonaws.com/prod/addison',
        options
      ).then(response => {
        if (!response.ok) {
          return response.text().then(result => Promise.reject(new Error(result)));
        }
        return response.json().then(data => {
          if(!data || !data[0] || !data[0].data) return null;
          logger.debug('getDemographics', data[0].data.contacts);
          window.demographics = data[0].data.contacts;
          return data[0];
        });
      });
    };
  
    let currentUser = store.getState().user.userData;
    if (!currentUser) {
      await pullUserData();
    }
    logger.debug('currentUser: ', currentUser);
  
    const deviceTypeToShortCode_DE = {
      ECG_PROHEALTH_SP: '__airflowDE__',
      ECG_PROHEALTH_GLUCOSE: '__glucoseDE__',
      ECG_PROHEALTH_BP: '__bloodPressureDE__',
      ECG_PROHEALTH_WEIGHT: '__weightDE__',
      ECG_PROHEALTH_PULSE: '__pulseOximeterDE__',
      ECG_PROHEALTH_TEMP: '__temperatureDE__',
    }

    const deviceTypeToShortCode = {
      ECG_PROHEALTH_SP: '__airflow__',
      ECG_PROHEALTH_GLUCOSE: '__glucose__',
      ECG_PROHEALTH_BP: '__bloodPressure__',
      ECG_PROHEALTH_WEIGHT: '__weight__',
      ECG_PROHEALTH_PULSE: '__pulseOximeter__',
      ECG_PROHEALTH_TEMP: '__temperature__',
    };
  
    let deviceButtons;

    switch(store.getState().primaryLanguage) {
      case 'en-US':
      case 'en-AU':
      case 'en-GB':
        deviceButtons = store.getState().user.userData.deviceTypes.map(deviceType => {
          return deviceTypeToShortCode[deviceType];
        });
        break;
      case 'de-AT':
      case 'de-DE':
        deviceButtons = store.getState().user.userData.deviceTypes.map(deviceType => {
          return deviceTypeToShortCode_DE[deviceType];
        });
    }

    logger.debug('deviceButtons: ', deviceButtons);
    store.getActions().setDeviceButtons(deviceButtons);
  };

  /**
   * navigate to the root path (https://myurl.com/) on reload
   */
  useEffect(() => {
    setHomePath(); // set the path back to home at App mount
    window.addEventListener('beforeunload', setHomePath);
    window.addEventListener('beforeunload', () => {
      window._scene && window._scene.dispose();
      window._engine && window._engine.dispose();
    });
    setCustomerStateInfo();
    getVitalsData();
    initMedicationSnoozeHandler();

    // const _snoozeEffect = async () => {
      // const _snoozeCleanup = await initMedicationSnoozeHandler();
      // setSnoozeCleanup(_snoozeCleanup);
    // }

    // _snoozeEffect();

    try {
      initConnect();
    } catch(e) {
      logger.warn(`error initializing call functionality: `, e);
    }
    
    return () => {
      window.removeEventListener('beforeunload', setHomePath);
      // snoozeCleanup && snoozeCleanup();
      // setSnoozeCleanup(undefined);
    };
  }, []);

  useEffect(() => {
    setSubscription();
    _effect();
    //TODO
    return () => {};
  }, []);

  /**
   * refresh the AWS credentials
   */
  useEffect( () => {
  }, []);

  useEffect(() => {
    logger.debug('deviceButtons effect');
    pullUserData();
    _effect();
  }, []);

  // use location props in Router - DC
  // render function
  return (
    <>
      <ElementManager />
      <Captions />
      <AppScene />
      <AppSplashScreen />
      <AppScreenFader />
      <Location>
        {({ location }) => (
          <Router location={location} onChange={onRouteChange}>
            <Home path="/" location={location} />
            <AppDialer location={location} path="/call" />
            <AppMessages path="/message" />
            <SceneVitals location={location} path="sceneVitals/*" />
            <CameraViewer location={location} path="/" />
            <MedicationCompliance location={location} path="medicationCompliance/*" />
          </Router>
        )}
      </Location>
    </>
  );
};

export default App;
