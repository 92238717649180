import store from '../../_GlobalStateStore/GlobalStateStore';

export function displaySentence(someText) {
  console.log('sometext:', someText);
const lexToolingMod = store.getState().lexToolingMod;

  // const captionHTML = document.getElementById("captions");

  // all valid aws polly ssml tags
  const regex = /<(?=\/?(prosody[ >]|mark[ >]|break[ >]|emphasis[ >]|lang[ >]|p[ >]|phenome[ >]|s[ >]|say-as[ >]|speak[ >]|sub[ >]|w[ >]|amazon)).+?>/g;

  const spaceRegex = /(\s)+/g;
  // run after sending to Polly

  // Replace lexTool:key marks with caption modifications as per defined
  // in worldData.lexToolingMod entries.  Before entries can modify
  // captions, they must
  // 1) define a speech function to replace (or pass on) matching text
  // 2) match the current message (not necessarily the current sentence)
  //
  console.log(`%csomeText:(before): ${someText}`, 'background: #0ECBDF; color: #FFFFFF');
  Object.keys(lexToolingMod).forEach(key => {
    //
    if (lexToolingMod[key].match) {
      if (lexToolingMod[key].replaceRegexCaption)
        someText = lexToolingMod[key].replaceRegexCaption(someText);
    }
  });
  let temp = someText.replace(regex, '');
  console.log(`%csomeText: (after): ${temp}`, 'background: #0EBADF; color: #FFFFFF');

  temp = temp.replace(spaceRegex, ' ');
  console.log('temp:', temp);
   //captionHTML.innerHTML = temp;
  return temp; //rather than update innerHTML, let's do something a little more React-friendly and return the text to be rendered inline
}
