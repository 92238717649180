import React from 'react';
// import { onCreateMessage } from '../../graphql/subscriptions';
// import { navigate } from '@reach/router';

const AppMessages = ({ currentMessage }) => {  
  if(!currentMessage) return null;

  return <div>{currentMessage}</div>;
} 

export default AppMessages;