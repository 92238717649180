// AppSplashScreen.jsx

import React, { Component } from 'react';
import { Hub, Logger } from 'aws-amplify';

export default class AppSplashScreen extends Component {
  getInitialState() {
    return {
      on: true,
      url: 'https://cdn.ecg-health.com/splash_screen_2019_1624x1080.mp4',
      currentFadeClass: 'fadeIn',
      hasPlayed: false,
    };
  }

  constructor(props) {
    super(props);
    // this.hasPlayed = props.hasPlayed;
    // this.props = props;

    this.getInitialState = this.getInitialState.bind(this);
    this.toggle = this.toggle.bind(this);
    this.hide = this.hide.bind(this);
    this.show = this.show.bind(this);

    this.state = this.getInitialState();
    this.logger = new Logger('AppSplashScreen');

    Hub.listen('hideSplashScreen', () => {
      this.hide();
    });

    Hub.listen('showSplashScreen', () => {
      this.show();
    });

    Hub.listen('fadeInSplash', () => {
      this.setState({ currentFadeClass: 'fadeIn' });
    });

    Hub.listen('fadeOutSplash', () => {
      this.setState({ currentFadeClass: 'fadeOut' });
    });
  } // end ctor

  toggle() {
    this.setState({ on: !this.state.on }); // toggle
  }

  show() {
    this.setState({ on: true, currentFadeClass: 'fadeIn' });

    setTimeout(() => {
      this.hide();
    }, 1000);
  }

  hide() {
    this.setState({ on: false, currentFadeClass: 'fadeOut' });
  }

  componentDidMount() {
    // hide the splash screen after 10 seconds, if no signal is received
    // setTimeout( () => {
    //   this.hide()
    // }, 10000);
  }

  render() {
    // if (this.state.hasPlayed || !this.state.on) return null;

    return (
      <div
        data-testid="splash-screen-testid"
        className={`Video-Container ${this.state.currentFadeClass} ${this.state.on ? '' : 'hidden'}`}
      >
        <video
          style={{ zIndex: '1' }}
          id="splash-container"
          autoPlay
          muted
        >
          <source src={this.state.url} type="video/mp4" />
        </video>
      </div>
    );
  }
}
