import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';

export async function postBluetoothResult(event) {
    const creds = await Auth.currentCredentials();
    const { accessKeyId, secretAccessKey, sessionToken } = creds;

    const lambda = new AWS.Lambda({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region: window.ADDISON_REGION
    });

    const Payload = JSON.stringify(event);

    const params = {
        FunctionName: 'addisoncare-bluetooth-ingestion-service',
        Payload
    };

    const res = await lambda.invoke(params).promise();

    return res;
}