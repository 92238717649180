/**
 * MedReminderAnim
 *
 * @author Taylor Bunker
 */


import React from 'react';

import Lottie from 'react-lottie'
import animation from './medRemindAnim.json'
import './MiniReminderAnim.scss'

const MiniReminderAnim = () => {
  return (
    <div className='miniReminderAnim'><Lottie
      options={{
        animationData: animation
      }}
    /></div>
  );
}

export default MiniReminderAnim;
