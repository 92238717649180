/**
 * MedManagementTable
 *
 * @author Michael Lyon
 * @author Kitt Phi 7/29/2019 2:00 PM
 */

import React, { useEffect } from 'react';
import moment from 'moment';
import { Logger, Hub } from 'aws-amplify';
import store from '../../../_GlobalStateStore/GlobalStateStore'; 

import GenericTable from '../../GenericTable/GenericTable';

const MedManagementTable = ({ data, tableName }) => {
  const logger = new Logger('MedManagementTable');
  logger.debug('Checking Data...', data);
  data = JSON.parse(data);

  useEffect(() => { 
    store.getActions().eventManager.addListener({ 
      channel: 'tableNext', 
      callback: () => { 
        Hub.dispatch('tableNext'); 
      }, 
    }); 
    store.getActions().eventManager.addListener({ 
      channel: 'tablePrevious', 
      callback: () => { 
        Hub.dispatch('tablePrevious'); 
      }, 
    }); return () => {
      store.getActions().eventManager.removeListener({ 
        channel: 'tableNext',
      }); 
      store.getActions().eventManager.removeListener({ 
        channel: 'tablePrevious',
      });
    }; 
  }, []);

  return (
    <GenericTable
      tableName={tableName}
      footerText={`${moment().format('dddd h:mmA')}`}
      data={data}
    />
  );
};

export default MedManagementTable;
