/**
 * Generic Table
 *
 * @author Michael Lyon
 */

import React, { useState, useEffect } from 'react';
import { Hub, Logger } from 'aws-amplify';

const GenericTable = ({ tableName, footerText, data }) => {
  const logger = new Logger('GenericTable');
  const [currentRowsShown, setCurrentRowsShown] = useState(data.slice(0, 4));
  const [, setStartRowIndex] = useState(0);
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    Hub.listen('tableNext', () => {
      setStartRowIndex((prevStartRowIndex) => {
        // logger.debug('prevStartRowIndex NEXT', prevStartRowIndex);
        // logger.debug(`prevStartRowIndex NEXT data[${prevStartRowIndex + 4}, ${prevStartRowIndex + 8}]`);
        setCurrentRowsShown(data.slice(prevStartRowIndex + 4, prevStartRowIndex + 8));
        return prevStartRowIndex + 4;
      });
    });

    Hub.listen('tablePrevious', () => {
      setStartRowIndex((prevStartRowIndex) => {
        // logger.debug('prevStartRowIndex PREV', prevStartRowIndex);
        // logger.debug(`prevStartRowIndex PREV data[${prevStartRowIndex - 4},
        // ${prevStartRowIndex}]`);
        setCurrentRowsShown(data.slice(prevStartRowIndex - 4, prevStartRowIndex));
        return prevStartRowIndex - 4;
      });
    });

    Hub.listen('tabletState', (status) => {
      logger.debug('STATUS', status.payload['2']);
      setIsListening(status.payload['2'] === 'e');
    });

    // fill missing rows with placeholders
    while (data.length % 4 !== 0) {
      data = [...data, { //eslint-disable-line react-hooks/exhaustive-deps
        filler1: '-',
        filler2: '-',
        filler3: '-',
      },
      ];
    }

    setCurrentRowsShown(data.slice(0, 4));
  }, []);


  return (
    <div className="containers__left-center">
      <div className="generic-table">
        <h1 className="generic-table__title">{tableName}</h1>
        <div className="generic-table__table">
          <div className="generic-table__row generic-table__row--header">
            {Object.keys(data[0]).map(key => <h2>{key}</h2>)}
          </div>
          { currentRowsShown.map((row, index) => (
            <div className={`generic-table__row ${index % 2 === 0 && 'generic-table__row--even'}`}>
              {Object.keys(row).map(key => <h3>{row[key]}</h3>)}
            </div>
          )) }
        </div>
        <div className="generic-table__footer">
          <img
            className="generic-table__addison"
            src={isListening ? 'https://addison-project-anim-files.s3.amazonaws.com/UI+Images/MedChart_Listening.jpg'
              : 'https://addison-project-anim-files.s3.amazonaws.com/UI+Images/MedChart_NotListening.jpg'
            }
            alt={isListening ? "Addison Listening" : "Addison Not Listening"}
          />
          <div className="generic-table__date-time">
            <h1>{footerText}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenericTable;
