import { useState, useCallback } from 'react';
import bleConnect from './bleConnect.js';

const defaultOnConnect = () => { };

const makeBluetoothHook = ({
    serviceId,
    characteristicId,
    otherServices,
    reader,
    formatter,
    getPercent,
}) => (
    onConnect = defaultOnConnect,
    ) => {
        const [lastReading, setLastReading] = useState(null);
        //   const { setError, error, clearError } = useError();

        const error = 'preserve shape';
        const clearError = () => { };
        const clearReading = useCallback(async () => setLastReading(null), []);
        const kickOffReading = useCallback(async () => {
            try {
                const readings = reader(await bleConnect(serviceId, characteristicId, otherServices));
                readings.subscribe(setLastReading);
                onConnect();
            } catch (err) {
                console.error(err);
                //   setError(err);
            }
        }, [setLastReading, onConnect]);
        return [
            lastReading && formatter(lastReading),
            lastReading && getPercent(lastReading),
            kickOffReading,
            error,
            clearError,
            clearReading,
        ];
    };

export default makeBluetoothHook; 