// import autoCreds, { amplifyConfig } from '../CredentialsModule'; // eslint-disable-line no-unused-vars
import awsmobile from '../aws-exports';

const introConfig = require('../config/tlsScene.json');
const introConfigStaging = require('../config/tlsSceneStaging.json');
const introConfigProd = require('../config/tlsSceneProd.json');


const {
    aws_content_delivery_bucket  
} = awsmobile;


export const chooseConfig = () => {
    if (
      aws_content_delivery_bucket.includes('-dev')
      && !aws_content_delivery_bucket.includes('-staging')
    ) {
      return introConfig;
    }
    if (aws_content_delivery_bucket.includes('-staging')) {
      return introConfigStaging;
    }
    if (aws_content_delivery_bucket.includes('-prod')) {
      return introConfigProd;
    }
    return null;
};

