import * as BABYLON from 'babylonjs';
import { Logger } from 'aws-amplify'; 
import { assignLightmapOnMaterial } from '.';

/**
 * @param {BABYLON.AssetContainer} container AssetContainer for the currently loaded scene
 * @param {object} lightmaps object containing lightmaps config 
 */
export function initLightmaps(container, lightmaps) {
    const logger = new Logger('initLightmaps');
    const { rootUrl, postfixUrl, meshes } = lightmaps;

    for (const meshName of meshes) {
        const currentMesh = container.meshes.find( val => val.name === meshName || val.id === meshName);

        if(!currentMesh) {
            logger.error(`no mesh with name ${meshName} currently exists!`);
            continue;
        }

        const url = rootUrl + meshName + postfixUrl;
        const currentMeshLightmap = new BABYLON.Texture(url, container.scene);
        currentMeshLightmap.name = currentMesh.name + postfixUrl;

        if (!currentMesh.material) {
            logger.warn('no material assigned to the current mesh. ', currentMesh);
            continue;
        } else if (!currentMesh.material.subMaterials) {
            assignLightmapOnMaterial(
                currentMesh.material,
                currentMeshLightmap
            );
        } else if (currentMesh.material.subMaterials) {
            assignLightmapOnMaterial(
                currentMesh.material,
                currentMeshLightmap
            );

            for (var j = 0; j < currentMesh.material.subMaterials.length; j++) {
                assignLightmapOnMaterial(
                    currentMesh.material.subMaterials[j],
                    currentMeshLightmap
                );
            }
        }
    };
}