import * as BABYLON from 'babylonjs';
import { Logger } from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore';

export async function initParticle(config, scene) {
    const logger = new Logger('initParticle.js');
    const { snippetIds, emitterPosition, snippetTextures, flipParticles } = config;
    const [x, y, z] = emitterPosition;

    const zipped = snippetIds.map(function (id, i) {
        return [id, snippetTextures[i]];
    });

    for (const [snippetId, snippetTexture] of zipped) {

        const particle = await BABYLON.ParticleHelper.CreateFromSnippetAsync(snippetId, scene, false);

        console.log("store.getState().currentParticleNames: ", store.getState().currentParticleNames);

        const foundParticle = store.getState().currentParticleNames.find(val => {
            console.log("val: ", val);
            console.log("particleId: ", particle);    
            return val === particle.id;
        });

        if (foundParticle) {
            logger.debug(`particle system ${particle.id} already exists, skipping creation`);
            particle.dispose();
            continue;
        }

        store.getActions().pushCurrentParticleNames(particle.id);
        store.getActions().pushCurrentParticleSystems(particle);

        console.log("particle: ", particle);
        particle.emitter = new BABYLON.Vector3(x, y, z);
        particle.particleTexture = new BABYLON.Texture(snippetTexture, scene);
        particle.minInitialRotation = flipParticles
        particle.maxInitialRotation = flipParticles
        particle.start();
    }
};
