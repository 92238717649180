import { scan } from 'rxjs/operators';
import map from 'lodash/fp/map';
import reduce from 'lodash/fp/reduce';
import merge from 'lodash/fp/merge';
// import uncurry from 'utils/uncurry';
import _ from 'lodash';

/*
 * reduceToObj :: [{ key1: val1 }, { key2: val2 }] -> { key1: val1, key2: val2 }
 */

// const reduceToObj = <A>(objArr: GenObj<A>[]): GenObj<A> =>
//   reduce<GenObj<A>, GenObj<A>>(uncurry(merge), {})(objArr);

// const reduceToObj = (arr) => {
//     const parsed = {};
//     for(const obj of arr) {
//         for(const key in obj) {
//             parsed[key] = obj[key];
//         }
//     }
// }


// const reduceToObj = (objArr) => reduce(uncurry(merge), {})(objArr);

const reduceToObj = (arr) => _.reduce(arr, _.merge, {});

const reduced = reduceToObj([{key1: 'val1'}, {key2: 'val2'}]);
console.log("reduced: ", reduced);

const oVal = (a) => (key) => (a[key] && a[key][0]) || 0;
const eVal = (a) => a[1][0];
const fieldKey = (a) => a[0];

/*
 *  addObj :: { a: [num1, index] } -> { a: [num2, index] } -> number -> { a: [num1 + num2, index] }
 */
// export const addObj = (a: GenObj<[number, number]>) => (b: GenObj<[number, number]>) => (
//     index: number,
//   ): GenObj<[number, number]> =>
//     reduceToObj(
//       map<[string, [number, number]], GenObj<[number, number]>>((item) => ({
//         [fieldKey(item)]: [eVal(item) + oVal(b)(fieldKey(item)), index],
//       }))(Object.entries(a)),
//     );

    export const addObj = (a) => (b) => (index) =>
        reduceToObj(
          map((item) => ({
            [fieldKey(item)]: [eVal(item) + oVal(b)(fieldKey(item)), index],
          }))(Object.entries(a)),
        );
        
/*
 * objSumEmb :: { key: number } -> index -> { key: [number, index] }
 * Embelishes an object from { key: number } -> { key: [total, index] }
 */
// export const objSumEmb = (a: GenObj<number>) => (index: number): GenObj<[number, number]> =>
//   reduceToObj(
//     map<[string, number], GenObj<[number, number]>>(([key, number]) => ({
//       [key]: [number, index],
//     }))(Object.entries(a)),
//   );

  export const objSumEmb = (a) => (index) =>
  reduceToObj(
    map(([key, number]) => ({
      [key]: [number, index],
    }))(Object.entries(a)),
  );

/*
 *  keepObjSum :: { key: number } -> OperatorFunction { key: [total, index] }
 *  Keeps a running total (and latest index) for all values so far by object key
 */
// const keepObjSum = (initialShape: GenObj<number>) =>
//   scan<GenObj<number>, GenObj<[number, number]>>(
//     (acc, next, index) => addObj(acc)(objSumEmb(next)(index))(index),
//     objSumEmb(initialShape)(0),
//   );
  
  const keepObjSum = (initialShape) =>
  scan(
    (acc, next, index) => addObj(acc)(objSumEmb(next)(index))(index),
    objSumEmb(initialShape)(0),
  );

export default keepObjSum;