import React from 'react';
import MiniMedManagement from '../../SceneMedManagement/MiniMedManagement/MiniMedManagement';
import MedManagementTable from '../../SceneMedManagement/MedManagementTable/MedManagementTable';

const SceneMedicationManagementElementHandler = (elementAttributes, isHiding, handleClick) => {
  switch (elementAttributes.type) {
    case 'miniMedManagement':
      return (
        // eslint-disable-next-line react/jsx-filename-extension
        <MiniMedManagement />
      );

    case 'medManagementTable':
      return <MedManagementTable data={elementAttributes.data} tableName="Review Medications" />;

    case 'upcommingManagementTable':
      return <MedManagementTable data={elementAttributes.data} tableName="Upcoming Medications" />;
    default:
      return undefined;
  }
};

export default SceneMedicationManagementElementHandler;
