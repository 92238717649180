import {
  API, graphqlOperation, Auth, Logger, I18n
} from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore';
import { getUser } from '../_GlobalStateStore/custom_graphql/getUser';

/**
 * call the getUser query and memoize the results in the global store
 */
export const pullUserData = async () => {
  const logger = new Logger('pullUserData');
  const session = await Auth.currentSession();
  const id = session.idToken.payload['cognito:username'];
  logger.debug(`id = ${id}`);
  try {
    const results = await API.graphql(graphqlOperation(getUser, { id }));
    store.getActions().user.pullUserDataSuccess(results.data.getUser);

    if (results.data.getUser && results.data.getUser.primaryLanguage && results.data.getUser.primaryLanguage.code) {
      store.getActions().setPrimaryLanguage(results.data.getUser.primaryLanguage.code);
      const langOnly = store.getState().primaryLanguage.substring(0, 2);
      I18n.setLanguage(langOnly);
    }

    return results.data.getUser;
  } catch (e) {
    logger.warn('[GlobalStateStore] pullUserData failed', e);
  }
};
