import React, { useState, useEffect } from 'react';
import { Hub } from 'aws-amplify';
import axios from 'axios';
import Icon from '../../_ElementManager/Icon.js';
import moment from 'moment';
import localization from 'moment/locale/de';
import { useStoreState } from 'easy-peasy';
import store from '../../../_GlobalStateStore/GlobalStateStore';

import './classNames.scss';

import { getWeather, getCondition, getTemperatureF, getTemperatureC } from './selectors';

let verticalLine = (<div className={'verticalLineNotification'} style ={{margin: 'auto'}}></div>);

// --- TODO: Move to AWS Secrets ---
const weatherAPI = {
  url: 'https://api.openweathermap.org/data/2.5/weather',
  key: 'ec409bfc2bb12171fa45b9ddbb822fa0',
  getLoc(lat, lon) {
    return {
      params: {
        lat,
        lon,
        appid: this.key,
      },
    };
  },
};

const fetchWeather = (url, location) => {
  return axios.get(url, location);
};


export default function NotificationBar() {
  const [time, setTime] = useState(null);
  const [date, setDate] = useState(null);
  const [positionData, setPositionData] = useState(null);

  const [weather, setWeather] = useState(null);
  const [temp, setTemp] = useState(null);
  const [condition, setCondition] = useState(null);
  const [degree, setDegree] = useState(null);

  const lang = useStoreState(state => state.primaryLanguage);
  /* --- Sets the time --- */
  /* --- Rerenders component only --- */
  /* --- when the minute updates --- */
  useEffect(() => {
    const lang = store.getState().primaryLanguage;
    if (lang === "en" || lang === "en-US" || lang === "en-GB" || lang === "en-AU") {
      setTime(moment().format('h:mm A'));
    } 
    else if(lang === "de" || lang === "de-AT" || lang === "de-DE") {
      setTime(moment().format('HH:mm'));
  }
    const timeUpdate = setInterval(() => {
      if (lang === 'en' || lang === 'en-US' || lang === 'en-GB' || lang === 'en-AU') {
        moment.locale('en');
        setTime(moment().format('h:mm A'));
        setDate(moment().format('L'));
      } else if (lang === 'de' || lang === 'de-AT' || lang === 'de-DE'){
        moment.locale('de');
        setTime(moment().format('LT'));
        setDate(moment().format('l'));
      }
    }, 1000);
    return () => clearInterval(timeUpdate);
  }, []);

  /* --- Sets the position on initial load --- */
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => setPositionData(coords),
        err => {
          console.error(`Unable to retrieve current location for weather! err: ${err}`);
        },
        { maximumAge: 0, enableHighAccuracy: true },
      );
    } else {
      console.error('Location services not enabled in this browser!');
    }
  }, []);

  /* --- Sets the weather --- */
  /* --- based on position --- */
  useEffect(() => {
    if (positionData && !weather) {
      const { latitude, longitude } = positionData;
      const url = weatherAPI.url;
      const location = weatherAPI.getLoc(latitude, longitude);
      fetchWeather(url, location)
        .then(results => {
          const weatherResults = getWeather(results);
          setWeather(weatherResults);
          setCondition(getCondition(weatherResults));
          const lang = store.getState().primaryLanguage;
          //LOCALIZATION
          if (lang === "en" || lang === "en-US" || lang === "en-GB" || lang === "en-AU") {
            setTemp(getTemperatureF(weatherResults));
          } 
          else if(lang === "de" || lang === "de-AT" || lang === "de-DE") {
            setTemp(getTemperatureC(weatherResults));
          }
        })
        .catch(err => console.error(`Unable to fetch weather! Error: ${err}`));
    }
  }, [positionData, weather]);

  /* --- Sets the weather --- */
  /* --- every 15 minutes (900,000 ms) --- */
  /* --- If the weather hasn't changed, --- */
  /* --- the component won't rerender --- */
  useEffect(() => {
    const weatherUpdate = setInterval(() => {
      if (positionData && weather) {
        const { latitude, longitude } = positionData;
        const url = weatherAPI.url;
        const location = weatherAPI.getLoc(latitude, longitude);
        fetchWeather(url, location)
          .then(results => {
            const weatherResults = getWeather(results);
            setWeather(weatherResults);
            setCondition(getCondition(weatherResults));
            const lang = store.getState().primaryLanguage;
            //LOCALIZATION
            if (lang === "en" || lang === "en-US" || lang === "en-GB" || lang === "en-AU") {
              setTemp(getTemperatureF(weatherResults));
            } 
            else if(lang === "de" || lang === "de-AT" || lang === "de-DE") {
              setTemp(getTemperatureC(weatherResults));
            }
          })
          .catch(err => console.error(`Unable to fetch weather! Error: ${err}`));
      }
    }, 900000);
    return () => clearInterval(weatherUpdate);
  }, [positionData, weather]);

  /* --- Emits weather data --- */
  /* --- whenever the weather changes --- */
  useEffect(() => {
    if (weather) Hub.dispatch('weatherRetrieved', { data: weather });
  }, [weather]);

useEffect(()=>{
  const lang = store.getState().primaryLanguage;
  if (lang === "en" || lang === "en-US" || lang === "en-GB" || lang === "en-AU") {
    const _degree = (temp && ` ${temp}°F`) || '—°F';
    setDegree(_degree);
  } 
  else {
    const _degree = (temp && ` ${temp}°C`) || '—°C';
    setDegree(_degree);
  }
},[temp])

  

  return (
    <div className="NotificationBar elmNotification elmAnimSlideDown">
      <div className="NotificationBar_Temperature">
      <div className="NotificationBar_Icon">{(condition && Icon(condition)) || '—'}</div>
      {degree}
      {verticalLine}
      <div  className= "NotificationBar_Time elmNotificationTime">{time}</div>
      <div  className= "NotificationBar_Date elmNotificationDate">{date}</div> 
    </div>
    </div>
  );
}
