import * as BABYLON from 'babylonjs';

export function initSceneOptimizer(scene) {
    //freeze all materials -- we aren't using any shaders
    for (const mat of scene.materials) {
        mat.freeze();
    }

    for (const mesh of scene.meshes) {
        mesh.freezeWorldMatrix();
        mesh.doNotSyncBoundingInfo = true;
    }

    // scene.freezeActiveMeshes();
    scene.autoClear = false; // Color buffer
    scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously

    var options = new BABYLON.SceneOptimizerOptions();
    options.addOptimization(new BABYLON.HardwareScalingOptimization(0, 1));

    // Optimizer
    var optimizer = new BABYLON.SceneOptimizer(scene, options);

    BABYLON.SceneOptimizer.OptimizeAsync(scene);
}