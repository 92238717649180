import { action, thunk } from 'easy-peasy';
import moment from 'moment';
import store from '../GlobalStateStore';
import { Logger } from 'aws-amplify';

const logger = new Logger('medReminders.js');

const model = {
  schedule: [],
  midnightFetchId: -1,

  deactivateReminder: thunk((actions, time) => {
    store.getState().medReminders.schedule.forEach(element => {
      if (element.time === time) {
        clearTimeout(element.reminderId);
        actions.removeReminder(time);
      }
    });
  }),

  createReminder: thunk((actions, reminder) => {
    const reminderIndex = store
      .getState()
      .medReminders.schedule.findIndex(element => element.time === reminder);
    if (reminderIndex < 0) {
      const reminderTime = moment(reminder, 'HH:mm a');
      const timeDiff = reminderTime.diff(moment());
      const scene = {
        url:
          'https://sumerian.us-east-1.amazonaws.com/20180801/projects/Addison%20Care%20-%20v2/release/authTokens?sceneId=2c683709c697452686f10d41c5fe7c6f.scene',
        sceneId: '2c683709c697452686f10d41c5fe7c6f.scene',
        region: 'us-east-1',
      };

      logger.debug(`creating reminder at ${reminder}`);

      const reminderId = setTimeout(() => {
        logger.debug('running from reminder');
        store.getActions().eventManager.emit({ channel: 'loadScene', data: scene });
        actions.removeReminder(reminder);
      }, timeDiff);

      const storedReminder = { time: reminder, reminderId };

      actions.addReminder(storedReminder);
    }
  }),

  updateMidnightReminderId: action((state, reminderId) => {
    state.midnightFetchId = reminderId;
  }),

  addReminder: action((state, reminder) => {
    state.schedule = state.schedule.concat(reminder);
  }),

  removeReminder: action((state, time) => {
    state.schedule = state.schedule.filter(reminder => reminder.time !== time);
  }),
};

export default model;
