import { Auth, Hub, Logger, API, graphqlOperation } from 'aws-amplify';
import { autoCreds } from '../CredentialsModule';
import { onCreateMessage } from '../graphql/subscriptions';
import { navigate } from '@reach/router';
import store from '../_GlobalStateStore/GlobalStateStore';


export const setSubscription = async () => {
  const logger = new Logger('SubscriptionHandler');
  const HOME_SCENE_UTTERANCE = 'play received message';
  logger.debug('creating message subscription');
  // await autoCreds.deploy("App");
  const { payload } = (await Auth.currentSession()).idToken;
  const userID = payload['cognito:username'];

  const subscribe = API.graphql(
    // graphqlOperation(onCreateMessage, { owner: await Auth.currentAuthenticatedUser() }),
    graphqlOperation(onCreateMessage, { owner: userID }),
  ).subscribe({
    error: (data) => {
      logger.debug('subscription error: ', data);
    },
    next: (data) => {
      logger.debug('raw sub data: ', data);
      const item = data.value.data.onCreateMessage;
      logger.debug('message received, message obj: ', item);
      const { transcript } = item;

      store.getActions().setCurrentMessage(transcript);
      Hub.dispatch('updateSessionAttributes', {
        data: transcript,
      });

      const playMessageHandler = () => {
        // if(currentStatus === IDLE && priority !== SEVERE)
        logger.debug('playMessageHandler invoked');
        navigate('/message');
        setTimeout(
          () => Hub.dispatch(
            'post_to_lex',
            HOME_SCENE_UTTERANCE,
          ),
          2000,
        );
      };

      playMessageHandler();

      Hub.listen('cancelMessage', () => {
        store.getActions().setCurrentMessage(undefined);
      });
    },
  });

    logger.debug("subscribe: ", subscribe);
    store.getActions().setMessageSubscription(subscribe);
    return subscribe;
  };
