import { Hub } from 'aws-amplify';
import { postToLex } from '.';

export function snoozeReminder(snoozeCount) {

}

export async function initMedicationSnoozeHandler() {
    //download the schedules, and initiate the cron reminders

    const FIFTEEN_SECONDS_MS = 15000;
    const THIRTY_SECONDS_MS = 30000;
    const FIFTEEN_MINUTES_MS = 900000;

    const _snoozeMedicationsFirst = (capsule) => {
        const { payload } = capsule;
        const snoozeAttempt = payload.data;

        setTimeout( () => {
            postToLex('placeholder', {
                name: 'MEDICATIONS_TAKE_SNOOZE_SECOND_EVENT'
            });
        }, THIRTY_SECONDS_MS);
    }

    const _snoozeMedicationsFinal = (capsule) => {
        const { payload } = capsule;
        const snoozeAttempt = payload.data;

        setTimeout( () => {
            postToLex('placeholder', {
                name: 'MEDICATIONS_TAKE_SNOOZE_FINAL_EVENT'
            });
        }, THIRTY_SECONDS_MS);
    }

    Hub.listen('snoozeMedicationFirst', _snoozeMedicationsFirst);
    Hub.listen('snoozeMedicationFinal', _snoozeMedicationsFinal);

    const cleanup = () => {
        Hub.remove('snoozeMedicationFirst', _snoozeMedicationsFirst);
        Hub.listen('snoozeMedicationFinal', _snoozeMedicationsFinal);
    }

    return cleanup;
}