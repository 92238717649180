import * as BABYLON from 'babylonjs';
import { Hub, Logger } from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore'

export async function initAudio(scene) {
    const logger = new Logger('initAudio.js');

    // Audio Files
    const faeJazz = new BABYLON.Sound("faeJazz", "https://cdn.ecg-health.com/assets/sounds/Jazz_BackgroundMusic.wav", scene, null, {loop:true, autoplay: false, volume: 0.2});


    // Audio Functions
    const _faeJazzStart = () => {
        faeJazz.play()
    };
    const _faeJazzStop = () => {
        faeJazz.stop()
    };


    // Players
    const _playAudio = async (capsule) => {
        const { payload } = capsule;

        logger.debug(`playing music: ${payload.data}`)

        switch (payload.data) {
            case 'faeJazzStart':
                _faeJazzStart();
                break;
            case 'faeJazzStop':
                _faeJazzStop();
                break;
            default:
                logger.debug('unlisted music: ', payload.data);
        }
    }


    // Listeners
    Hub.listen('audio', _playAudio);

}