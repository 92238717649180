import * as BABYLON from 'babylonjs'; //hack for Host system (would prefer ES6)
import store from '../_GlobalStateStore/GlobalStateStore';

export function setupScene(engine) {   
    const scene = new BABYLON.Scene(engine);
    scene.useRightHandedSystem = true;
    scene.fogColor.set(0.5, 0.5, 0.5);
    // scene.autoClear = false; // Color buffer
    // scene.autoClearDepthAndStencil = false; // Depth and stencil, obviously

    //store a global reference to the scene object, because it sometimes breaks
    //when referencing it from the redux store. Same for engine. 
    window._engine = engine;
    window._scene = scene;

    // save references to the Scene and Engine objects in the redux store
    store.getActions().setSceneRoot(scene);
    store.getActions().setEngine(engine);


    return scene;
}