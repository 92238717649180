/* eslint-disable max-len */
import { action, thunk } from 'easy-peasy';
import { Hub, Logger } from 'aws-amplify';
import store from '../GlobalStateStore';

import sumerianEmits from '../sumerianEmits.json';

const logger = new Logger('eventManager');

const model = {
  sceneController: null,
  setSceneController: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.sceneController = payload;
  }),

  sumerianEmits,

  addListener: thunk(async (actions, { channel, callback }, helpers) => {
    const sceneController = await helpers.getState().sceneController;

    if (!sceneController) {
      return;
    }

    sceneController.sumerian.SystemBus.addListener(channel, callback);
  }),

  removeListener: thunk(async (actions, { channel }, helpers) => {
    const sceneController = await helpers.getState().sceneController;

    if (!sceneController) {
      return;
    }

    sceneController.sumerian.SystemBus.removeAllOnChannel(channel);
  }),

  emit: thunk(async (actions, { channel, data }, helpers) => {
    // store.getState().logger.info(`%cemitting ${channel} with payload of ${JSON.stringify(data, null, 2)}`, 'color:grey;')

    const sceneController = await helpers.getState().sceneController;

    if (!sceneController) {
      return;
    }
    
    logger.info(`%cemitting ${JSON.stringify(channel)} with payload of ${JSON.stringify(data, null, 2)}`, 'color:grey;');

    sceneController.sumerian.SystemBus.emit(channel, data);
  }),

  initHubDispatchFromSumerianEmit: thunk(() => {
    store.getState().eventManager.sumerianEmits.forEach((event) => {
      store.getState().eventManager.sceneController.sumerian.SystemBus.addListener(event, (data) => {
        // store.getState().logger.info(`%cevent ${event} with payload ${JSON.stringify(data, null, 2)} detected from Sumerian, dispatching from Hub now...`, 'color:grey;');
        Hub.dispatch(event, data);
      });
    });
  }),

};

export default model;
