import * as BABYLON from 'babylonjs';
import { Hub } from 'aws-amplify';

export async function initVideoTextures(scene) {
    // Bounding Box (Glow)
    scene.getMeshByName("Tablet_Screen").showBoundingBox = true;
    scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0, 0.68, 1);
    scene.getBoundingBoxRenderer().showBackLines = false;


    // Screen Material
    const ScreenMat = scene.getMaterialByName("Tablet_Screen_MAT");

    // Glow Layer
    const glowLayer = new BABYLON.GlowLayer("glow", scene)
    glowLayer.intensity = 1;

    // Video Texture List
    const tabletDefaultTexture = new BABYLON.VideoTexture("", "https://cdn.ecg-health.com/assets/props/tablet+videos/Tablet_Default_v01.mp4", scene, true, true);
    const tabletDefaultGlowTexture = new BABYLON.VideoTexture("", "https://cdn.ecg-health.com/assets/props/tablet+videos/Tablet_Default_Glow_v02.mp4", scene, true, true);

    const listeningTexture = new BABYLON.VideoTexture("", "https://cdn.ecg-health.com/assets/props/tablet+videos/Listening_v03.mp4", scene, true, true);
    const listeningTexture_de = new BABYLON.VideoTexture("", "https://cdn.ecg-health.com/assets/props/tablet+videos/Listening_de_v01.mp4", scene, true, true);


    const privacyTexture = new BABYLON.VideoTexture("", "https://cdn.ecg-health.com/assets/props/tablet+videos/Privacy_v03.mp4", scene, true, true);
    const privacyWaveformTexture = new BABYLON.VideoTexture("", "https://cdn.ecg-health.com/assets/props/tablet+videos/Privacy_Equalizer_v01.mp4", scene, true, true);

    const catBananaTexture = new BABYLON.VideoTexture("", "https://cdn.ecg-health.com/assets/props/tablet+videos/Cat_Banana_v01.mp4", scene, true, true);
    const catCheeseTexture = new BABYLON.VideoTexture("", "https://cdn.ecg-health.com/assets/props/tablet+videos/Cat_Cheese_v01.mp4", scene, true, true);
    const catWiggleTexture = new BABYLON.VideoTexture("", "https://cdn.ecg-health.com/assets/props/tablet+videos/Cat_Wiggle_v01.mp4", scene, true, true);

    const socialDarkTexture = new BABYLON.VideoTexture("", "https://cdn.ecg-health.com/assets/props/tablet+videos/Social_Media_Dark_v02.mp4", scene, true, true);
    const socialLightTexture = new BABYLON.VideoTexture("", "https://cdn.ecg-health.com/assets/props/tablet+videos/Social_Media_Light_v02.mp4", scene, true, true);

    // Array of all videos to be paused on each key press
    const videoTextureList = [tabletDefaultTexture, tabletDefaultGlowTexture, listeningTexture, listeningTexture_de, privacyTexture, privacyWaveformTexture, catBananaTexture, catCheeseTexture, catWiggleTexture, socialDarkTexture, socialLightTexture]

    const _pauseAllVideos = async (capsule) => {
        const _pausePromises = videoTextureList.map(val => val.video.pause());
        await Promise.all(_pausePromises);

        // videoTextureList.forEach((val) => {
        //     val.video.pause()
        // })
    }

    // Default
    ScreenMat.albedoTexture = tabletDefaultTexture;
    ScreenMat.emissiveTexture = tabletDefaultTexture;
    await tabletDefaultTexture.video.play()
    scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0.27, 0.64, 0.89);


    //on 
    const _defaultTexture = async (capsule) => {
        await _pauseAllVideos()
        ScreenMat.albedoTexture = tabletDefaultTexture;
        ScreenMat.emissiveTexture = tabletDefaultTexture;
        ScreenMat.emissiveColor = new BABYLON.Color3(0.211, 0.152, 0.388);
        await Promise.all([
            tabletDefaultTexture.video.play(),
            tabletDefaultGlowTexture.video.play()
        ]);
        scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0.27, 0.64, 0.89);
    };
    Hub.listen('DefaultTabletScreen', _defaultTexture)
    Hub.listen('StopPrivacyMode', _defaultTexture);


    const _privacyTexture = async (capsule) => {
        await _pauseAllVideos()
        ScreenMat.albedoTexture = privacyTexture;
        ScreenMat.emissiveTexture = privacyTexture;
        ScreenMat.emissiveColor = new BABYLON.Color3(0.17, 0.17, 0.17);
        await privacyTexture.video.play()
        scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0.8, 0.16, 0.16);
    }
    Hub.listen('StartPrivacyMode', _privacyTexture);

    const _privacyWaveformTexture = async (capsule) => {
        await _pauseAllVideos()
        ScreenMat.albedoTexture = privacyWaveformTexture;
        ScreenMat.emissiveTexture = privacyWaveformTexture;
        ScreenMat.emissiveColor = new BABYLON.Color3(0.4, 0.4, 0.4);
        await privacyWaveformTexture.video.play()
        scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0.8, 0.16, 0.16);
    }
    Hub.listen('StartPrivacyWaveForm', _privacyWaveformTexture);


    const _listeningTexture = async (capsule) => {
        await _pauseAllVideos()
        ScreenMat.albedoTexture = listeningTexture;
        ScreenMat.emissiveTexture = listeningTexture;
        ScreenMat.emissiveColor = new BABYLON.Color3(0.15, 0.15, 0.15);
        await listeningTexture.video.play()
        scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0.23, 0.93, 0.06);
    }
    Hub.listen('addisonBtnListening', _listeningTexture);

    const _listeningTexture_de = async (capsule) => {
        await _pauseAllVideos()
        ScreenMat.albedoTexture = listeningTexture_de;
        ScreenMat.emissiveTexture = listeningTexture_de;
        ScreenMat.emissiveColor = new BABYLON.Color3(0.15, 0.15, 0.15);
        await listeningTexture_de.video.play()
        scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0.23, 0.93, 0.06);
    }
    Hub.listen('addisonBtnListening_de', _listeningTexture_de);


    const _catBananaTexture = async (capsule) => {
        await _pauseAllVideos()
        ScreenMat.albedoTexture = catBananaTexture;
        ScreenMat.emissiveTexture = catBananaTexture;
        ScreenMat.emissiveColor = new BABYLON.Color3(0.1, 0.1, 0.1);
        catBananaTexture.video.play()
        scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0.91, 0.78, 0.67);
    }
    Hub.listen('StartCatBanana', _catBananaTexture);


    const _catCheeseTexture = async (capsule) => {
        await _pauseAllVideos()
        ScreenMat.albedoTexture = catCheeseTexture;
        ScreenMat.emissiveTexture = catCheeseTexture;
        ScreenMat.emissiveColor = new BABYLON.Color3(0.1, 0.1, 0.1);
        await catCheeseTexture.video.play()
        scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0.91, 0.78, 0.67);
    }
    Hub.listen('StartCatCheese', _catCheeseTexture);


    const _catWiggleTexture = async (capsule) => {
        await _pauseAllVideos()
        ScreenMat.albedoTexture = catWiggleTexture;
        ScreenMat.emissiveTexture = catWiggleTexture;
        ScreenMat.emissiveColor = new BABYLON.Color3(0.1, 0.1, 0.1);
        await catWiggleTexture.video.play()
        scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0.91, 0.78, 0.67);
    }
    Hub.listen('StartCatWiggle', _catWiggleTexture);


    const _socialDarkTexture = async (capsule) => {
        await _pauseAllVideos()
        ScreenMat.albedoTexture = socialDarkTexture;
        ScreenMat.emissiveTexture = socialDarkTexture;
        ScreenMat.emissiveColor = new BABYLON.Color3(0.2, 0.2, 0.2);
        await socialDarkTexture.video.play()
        scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0.27, 0.64, 0.89);
    }
    Hub.listen('StartSocialDark', _socialDarkTexture);


    const _socialLightTexture = async (capsule) => {
        await _pauseAllVideos()
        ScreenMat.albedoTexture = socialLightTexture;
        ScreenMat.emissiveTexture = socialLightTexture;
        ScreenMat.emissiveColor = new BABYLON.Color3(0.15, 0.15, 0.15);
        await socialLightTexture.video.play()
        scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(0.27, 0.64, 0.89);
    }
    Hub.listen('StartSocialLight', _socialLightTexture);
}