import React from 'react'; 
 
import MiniMedReminder from '../../SceneMedReminders/MiniMedReminder/MiniMedReminder'; 
import MedReminderTable from '../../SceneMedReminders/MedReminderTable/MedReminderTable'; 
import MiniReminderAnim from '../../SceneMedReminders/MiniMedReminder/MiniReminderAnim'; 
 
//testing 
// import MiniMedReminder from '../../SceneMedReminders/MiniMedReminder/MiniMedReminder'; 
 
 
 
const SceneMedRemindersElementHandler = (elementAttributes, isHiding, handleClick) => { 
  switch (elementAttributes.type) { 
    case 'miniMedReminder': 
      return ( 
        <MiniMedReminder /> 
      ); 
 
    case 'medReminderTable': 
    if(!elementAttributes.data) return null;
      return ( 
        <MedReminderTable data={JSON.parse(elementAttributes.data[0])} time={elementAttributes.data[1]} /> 
      ); 
 
    case 'miniReminderAnim': 
      return ( 
        <MiniReminderAnim /> 
      ); 
    default: 
      return undefined; 
  } 
 
} 
 
export default SceneMedRemindersElementHandler; 