import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';


/**
 * This fuction will trigger lambda( name: bactivityDetectionAddison) that will stored
 * a pose detection to a dynamoDB.
 */
async function storeActivity() {  
  const creds = await Auth.currentCredentials();
  
  const { accessKeyId, secretAccessKey, sessionToken } = creds;
  const lambda = new AWS.Lambda({ accessKeyId, secretAccessKey, sessionToken, region: window.ADDISON_REGION });

  const session = await Auth.currentSession();
  const { jwtToken, payload } = session.idToken;
  const username = payload['cognito:username'];

  const account = {
    account: username,
  };

  //TODO: dynamically update FunctionName based on region 
  const lambdaParams = {
    FunctionName: 'activityDetectionAddison',
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify(account),
  };

  const data = await lambda.invoke(lambdaParams).promise();
  console.log(' Activity Detection stored in DB ', data);
}
export default storeActivity;
