import { map, pluck, take, skipWhile } from 'rxjs/operators';
import { Parser } from 'binary-parser';
import round from 'lodash/fp/round';
import fpMap from 'lodash/fp/map';
import compose from 'lodash/fp/compose';
import { makeBluetoothHook, keepObjSum } from '../js';

/*
 * Service ID, and Characteristic ID for PulseOx
 */
const serviceId = 0xfff0;
const otherServices = [0x180a];
const characteristicId = 0xfff4;
const numSamples = 1;

const sphygParser = new Parser()
  .endianess('big')
  .bit1('unitFlag')
  .bit1('timestampFlag')
  .bit1('pulseRateFlag')
  .bit1('userIdFlag')
  .bit1('measureStatusFlag')
  .bit1('testStatusFlag')
  .bit1('isNormal')
  .bit1('isTest')
  .uint8('sysHigh')
  .uint8('sysLow')
  .uint8('diaHigh')
  .uint8('diaLow')
  .uint8('mapHigh')
  .uint8('mapLow')
  .uint8('pulseRateHigh')
  .uint8('pulseRateLow');

const parseSphyg = (binary) => sphygParser.parse(binary);

/*
 *  Coerce units to mmhg
 */
const mmHg = (unitFlag) => unitFlag === 0;
const fromKPa = (kpa) => (760 / 101.325) * kpa;
const handleUnits = ({ sysLow, unitFlag, diaLow, pulseRateLow }) => ({
  systolic: mmHg(unitFlag) ? sysLow : fromKPa(sysLow),
  diastolic: mmHg(unitFlag) ? diaLow : fromKPa(diaLow),
  pulseRate: pulseRateLow,
});

/*
 * The pipeline for how the data goes from,
 * Bluetooth -> Summed Reading
 *
 */
export const readSphyg = (btData$) =>
  btData$.pipe(
    pluck('target'),
    pluck('value'),
    skipWhile((b) => b.byteLength < 12),
    map(parseSphyg),
    map(handleUnits),
    take(numSamples),
    keepObjSum({ systolic: 0, diastolic: 0, pulseRate: 0 }),
  );

  /*
 * Formatting the final emission from the observable
 * { sysHigh, sysLow, ... } -> [mean(sysHigh), mean(sysLow), ...]
 *
 */
const total = ([tot]) => tot;
const length = ([, lastId]) => lastId + 1;
const mean = (a) => total(a) / length(a);
export const formatAverages = ({ systolic, diastolic, pulseRate }) =>
  fpMap(compose(round, mean))([systolic, diastolic, pulseRate]);
export const getPercent = ({ systolic }) =>
  (systolic[1] + 1) / numSamples;

  /*
 * The Custom Hook
 */
const useSphygmo = makeBluetoothHook({
    formatter: formatAverages,
    reader: readSphyg,
    characteristicId,
    otherServices,
    getPercent,
    serviceId,
  });

export default useSphygmo;