import React from 'react';
// import moment from 'moment';

// import MiniMedReminder from '../../SceneMedReminders/MiniMedReminder/MiniMedReminder';
// import MedReminderTable from '../../SceneMedReminders/MedReminderTable/MedReminderTable';

import MoodChart from '../../SceneActivityMonitoring/MoodChart/MoodChart';





const SceneActivityMonitoringElementHandler = (elementAttributes, isHiding, handleClick) => {

  switch (elementAttributes.type) {
    case 'moodChart':
      return (
        <MoodChart />
      );

    default:
      return undefined;
  }

}

export default SceneActivityMonitoringElementHandler;