import { Logger } from 'aws-amplify';
import _ from 'lodash';
import moment from 'moment';

const logger = new Logger('VitalsHelpers');


export const typeToSlotValue = {
  bloodpressure: 'blood pressure',
  glucose: 'glucose',
  temperature: 'temperature',
  weight: 'weight',
  pulseox: 'oxygen levels',
};

export const normalRanges = {
  temperature: {
    low: 95,
    high: 99,
  },
  heartRate: {
    low: 60,
    high: 100,
  },
  systolic: {
    low: 90,
    high: 120,
  },
  diastolic: {
    low: 60,
    high: 80,
  },
  oxygen: {
    low: 92,
    high: 100,
  },
  weight: {
    low: 20,
    high: 200,
  },
  glucose: {
    low: 100,
    high: 250,    
  },
}

export const typeToUnit = {
  temperature: 'ºF',
  thermometer: 'Temperature',
  heartRate: 'BPM',
  pulseox: '%',
  systolic: '',
  diastolic: '',
  oxygen: '%',
  weight: 'lbs',
}

export const typeToPretty = {
  systolic: 'Systolic',
  diastolic: 'Diastolic',
  bloodpressure: 'Systolic / Diastolic',
  glucose: 'Glucose',
  heartRate: 'Beats per Minute',
  pulseox: 'Oxygen levels / BPM',
  oxygen: 'Oxygen',
  weight: 'Weight',
  temperature: 'Temperature',
  thermometer: 'Temperature',
}


/**
 * adds randomly generated data to patient object
 * 
 * @param {object} patient 
 * @param {string} type
 * 
 * @return {object} 
 */
export const generateData = (patient, type, setVitalsData) => {
    let newPatient = { ...patient };

    logger.debug("patient: ", patient);

    for (let i = 0; i < 10; i++) {
        newPatient[type].push({
            timestamp: moment().subtract(
                Math.random() * 10000,
                'minutes',
            ),
            value: Math.floor(
                Math.random() * ((normalRanges[type].high - normalRanges[type].low)) + (normalRanges[type].low)
            )
        });
    }

    return newPatient;
}

export const typeFromItem = (element) => {
    if (element.Event === "vitals" && element.Vital.includes("pulseox") === true) {
        return "pulseox";
    } else if (element.Event === "vitals" && element.Vital.includes("weight") === true) {
        return "weight";
    } else if (element.Event === "vitals" && element.Vital.includes("glucose") === true) {
        return "glucose";
    } else if (element.Event === "vitals" && element.Vital.includes("thermometer") === true) {
        return "thermometer";
    } else if (element.Event === "vitals" && element.Vital.includes("bloodpressure") === true) {
        return "bloodpressure";
    } else {
        // logger.debug("typeFromItem given an item that doesn't appear to match any known vital types!");
        return null;
    }
}



export const slotTypeValueFromItem = (element) => {
  if (element.Event === "vitals" && element.Vital.includes("pulseox") === true) {
      return "oxygen levels";
  } else if (element.Event === "vitals" && element.Vital.includes("weight") === true) {
      return "weight";
  } else if (element.Event === "vitals" && element.Vital.includes("glucose") === true) {
      return "glucose";
  } else if (element.Event === "vitals" && element.Vital.includes("thermometer") === true) {
      return "temperature";
  } else if (element.Event === "vitals" && element.Vital.includes("bloodpressure") === true) {
      return "blood pressure";
  } else {
    //   logger.debug("typeFromItem given an item that doesn't appear to match any known vital types!");
      return null;
  }
}


// format vitals that come in from subscription
export const formatVitalsData = (vitals) => {

    // deconstruct GraphQL query
    let {
        value: {
            data: {
                onCreateAneltoEvents: {
                    BPM,
                    PulseAvg,
                    Systolic,
                    Diastolic,
                    RawData,
                    Thermometer,
                    Glucose,
                    Value,
                    Weight
                }
            }
        }
    } = vitals

    let onCreateAneltoEvents = vitals.value.data.onCreateAneltoEvents;

    // _.includes(RawData, )

    // logger.debug("onCreateAneltoEvents: ", onCreateAneltoEvents);

    // let type = typeFromItem(onCreateAneltoEvents);
    // logger.debug("typeFromItem in formatVitalsData: ", type);

    let type = slotTypeValueFromItem(onCreateAneltoEvents);
    logger.debug("slotTypeValueFromItem in formatVitalsData: ", type);

    // change non-read values to N/A
    Thermometer = (_.includes(RawData, "thermometer")) ? Value : 'N/A';
    Glucose = (_.includes(RawData, "glucose")) ? Value : 'N/A';
    Weight = (_.includes(RawData, "weight")) ? Value : 'N/A';

    // format to data object
    const aneltoReading = {
        BPM,
        Oxygen: PulseAvg,
        Systolic,
        Diastolic,
        Thermometer,
        Glucose,
        Weight,
    }

    // remove junk
    const readData = _.pickBy(aneltoReading, o => !_.startsWith(o, "N"))

    // return formatted vitals object
    return {
        ...readData,
        type,
        Timestamp: new Date().toJSON().toString(),
    }
}


/**
 * 
 * @param {array} data -- array of patient data
 * @param {int} offset -- y axis domain offset
 * 
 * @return {object}
 */
export const calculateDomain = (data, offset) => {
    let result = {
        dataMinY: Number.MAX_SAFE_INTEGER,
        dataMaxY: 0,
        dataMinX: Number.MAX_SAFE_INTEGER,
        dataMaxX: 0,
    }

    data.forEach((obj) => {
        if (result.dataMinY > obj.value)
            result.dataMinY = obj.value;
        else if (result.dataMaxY < obj.value)
            result.dataMaxY = obj.value;

        if (result.dataMinX > obj.timestamp)
            result.dataMinX = obj.timestamp;
        else if (result.dataMaxX < obj.timestamp)
            result.dataMaxX = obj.timestamp;
    });

    return {
        dataMinY: result.dataMinY - offset,
        dataMaxY: result.dataMaxY + offset,
        dataMinX: result.dataMinX - 100000000,
        dataMaxX: moment().valueOf() + 100000000,
    }
}


export const thresholdTypeFromDataType = (dataType) => {
    switch (dataType){
        case "temperature":
        case "thermometer":
            return "Thermometer";
        case "weight":
            return "Weight";
        case "glucose":
            return "Glucose";
        case "diastolic":
            return "Diastolic";
        case "systolic":
            return "Systolic";
        case "oxygen":
            return "Oxygen";
        case "heartRate":
            return "BPM";
        default:
            return null;
    }
}
/**
 * 
 * @param {string} type 
 * @param {object} currentMinMax 
 * 
 * @return {object}
 */
export const replaceMissingThreshold = (type, currentMinMax) => {
    let _minThreshold, _maxThreshold;
    switch (type) {
      case 'Thermometer':
      case 'Temperature':
        _minThreshold = currentMinMax.minimum || normalRanges.temperature.low;
        _maxThreshold = currentMinMax.maximum || normalRanges.temperature.high;
        break;
      case 'Weight':
        _minThreshold = currentMinMax.minimum || normalRanges.weight.low;
        _maxThreshold = currentMinMax.maximum || normalRanges.weight.high;
        break;
      case 'Glucose':
        _minThreshold = currentMinMax.minimum || normalRanges.glucose.low;
        _maxThreshold = currentMinMax.maximum || normalRanges.glucose.high;
        break;
      case 'Diastolic':
        _minThreshold = currentMinMax.minimum || normalRanges.diastolic.low;
        _maxThreshold = currentMinMax.maximum || normalRanges.diastolic.high;
        break;
      case 'Systolic':
        _minThreshold = currentMinMax.minimum || normalRanges.systolic.low;
        _maxThreshold = currentMinMax.maximum || normalRanges.systolic.high;
        break;
      case 'Oxygen':
        _minThreshold = currentMinMax.minimum || normalRanges.oxygen.low;
        _maxThreshold = currentMinMax.maximum || normalRanges.oxygen.high;
        break;
      case 'BPM':
        _minThreshold = currentMinMax.minimum || normalRanges.heartRate.low;
        _maxThreshold = currentMinMax.maximum || normalRanges.heartRate.high;
        break;
        default: 
        logger.warn('invalid vital type');
    }// end switch
    return { minimum: _minThreshold, maximum: _maxThreshold };
  }