export function setHostRotation(capsule) {
    const { payload } = capsule;

    const { x, y, z } = payload.data;

    const node = window._scene.getNodeByID('Addison');

    node.rotation.x = typeof x === 'number' ? x : node.rotation.x;
    node.rotation.y = typeof y === 'number' ? y : node.rotation.y;
    node.rotation.z = typeof x === 'number' ? z : node.rotation.z;
}