import React from 'react';
import VitalsScatterChart from '../../SceneVitals/VitalsScatterChart/VitalsScatterChart';
import { useStoreState } from 'easy-peasy';

import _ from 'lodash';

const SceneVitalsElementHandler = (elementAttributes, isHiding, handleClick) => {

  const currentVitalType = useStoreState(state => state.currentVitalType);

  switch (elementAttributes.type) {    

    /**
     * VITALS DIV
     * -
     */
    case "vitalsView":
      return (
        <div 
          className={
            /* CLASSES */
            'elmVitalView'
          } 
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
          }}
        > 
          { 
            _.map(
              _.pickBy(
                elementAttributes.vitalsDat, o => !_.startsWith(o,"2")
              ), (value, key) => (
                <h2>{`${key} - ${value}`}</h2>
              )
            )
          }
          {elementAttributes.text} 
        </div>
      );

    /**
     * SCATTER CHART
     * -
     */
    case "scatterChart":
      let data = {
        patients: [
          {
            systolic: [],
            diastolic: [],
            glucose: [],
            heartRate: [],
            oxygen: [],
            weight: [],
            temperature: [],
          }
        ]
      };

      return (
        <VitalsScatterChart 
          data={data}
          type={currentVitalType}
        />
      );

    default:
      return undefined;

  }
}

export default SceneVitalsElementHandler;