/**
 * @author Michael Lyon
 * @author Kitt Phi 7/29/2019 2:00 PM
 */

import React from 'react';
import moment from 'moment';

let dumbyData = [
  {
    "meds": [
      {
        "Medication Name": "test1",
        "Time": "08:00:00",
        "Dosage": "1",
        "Amount": "1234",
      },
      {
        "Medication Name": "test2",
        "Time": "08:00:00",
        "Dosage": "123",
        "Amount": "1512",
      },
      {
        "Medication Name": "test3",
        "Time": "08:00:00",
        "Dosage": "112",
        "Amount": "1423",
      },
      {
        "Medication Name": "test4",
        "Time": "08:00:00",
        "Dosage": "534",
        "Amount": "12",
      },
      {
        "Medication Name": "test5",
        "Time": "08:00:00",
        "Dosage": "531",
        "Amount": "12",
      },
      {
        "Medication Name": "test6",
        "Time": "08:00:00",
        "Dosage": "15",
        "Amount": "43",
      }
    ],
    "Time": "08:00:00",
  },
];

const MiniMedManagement = () => {
  const reminderTime = `${moment().format('dddd')} ${moment(`1/1/2000 ${dumbyData[0].Time}`).format('h:ssA')}`

  return (
    <div className='mini-med-management'>
      <h1>Medication Management</h1>
      <div className='mini-med-management__date-time'>
        <h2>{reminderTime}</h2>
      </div>
    </div>
  );
}

export default MiniMedManagement; 