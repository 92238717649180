import { Logger, Auth, Hub } from 'aws-amplify';
import { Lambda } from 'aws-sdk';
import store from '../_GlobalStateStore/GlobalStateStore';
import { pullUserData, RecognitionHandler, lexHandler } from '.';

const logger = new Logger('postToLex');

Hub.listen('postChatbotEvent', (capsule) => {
  const eventName = capsule.payload.data;
  postToLex('placeholder', {
    name: eventName
  })
});

export async function postToLex(input = 'placeholder', event) {
    // [Added 8/7/2019 by Martin Jencka]
    // We need to tell the system that we just posted to lex. Otherwise, the tablet will turn on when we hit a button during a speech.
    // This is, admittedly, a bit of a hacky solution, but due to how the slot gets returned it's the easiest solution for now.
    // In the future, lets find a better way about this, maybe by passing some sort of flag through the bot? Not sure at the moment.
    window.waitForPost = true;

    // We then need to use a timeout to make sure that after a short amount of time the waitForPost flag gets turned off.
    // We do this because only a few milliseconds are needed for this workaround to actually function.
    setTimeout(() => {
      window.waitForPost = false;
    }, 1000);

    // Resume normal post to lex stuff.

    const username = store.getState().username;

    const userData = store.getState().user.userData;

    if (!userData) {
      try {
        await pullUserData();
        const languageCode = (store.getState().user.userData && store.getState().user.userData.primaryLanguage && store.getState().user.userData.primaryLanguage.code) || 'en-US';
        store.getActions().setPrimaryLanguage(languageCode);
        logger.debug(`detected language: ${languageCode}`);
      } catch (e) {
        //suppress
      }
    }

    store.getState().hostEntity.TextToSpeechFeature.stop();
    if (!window.privacyMode) Hub.dispatch('DefaultTabletScreen');
    store.getActions().setLexParamsInputStreams(input);
    store.getActions().setLexSpeaking(false);
    RecognitionHandler.passiveListening();

    // sumerian.lexSpeaking = false;
    Hub.dispatch('stopSpeech');
    // logger.debug(`sending to dialogflow => ${JSON.stringify(store.getState().lexParams)}`);
    // logger.debug(`deviceButtons: `, store.getState().deviceButtons);

    //TODO: allow toggleable language rather than hardcode to "correct" language for profile
    //TODO: reference initial language from user profile

    const _Payload = {
      inputTranscript: input,
      language: store.getState().primaryLanguage,
      sessionAttributes: {
        ...store.getState().lexParams.sessionAttributes,
        languageCode: store.getState().primaryLanguage,
        username,
        deviceButtons: store.getState().deviceButtons,
        vitalAttributes: store.getState().latestVitalAttributes
      },
      requestAttributes: store.getState().lexParams.requestAttributes,
    }

    //jkeys 10/28/20 -- support events
    if(event) {
      delete _Payload.inputTranscript;
      _Payload.isEvent = true;
      _Payload.eventDetails = {
        name: event.name,
      }
    }

    const Payload = JSON.stringify(_Payload);

    // logger.debug('Payload: ', Payload);

    const creds = await Auth.currentCredentials();
    const { accessKeyId, secretAccessKey, sessionToken } = creds;

    const lambda = new Lambda({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region: window.ADDISON_REGION
    });

    var lexRequest = lambda.invoke({
      FunctionName: 'react-to-dialogflow-proxy',
      Payload
    })

    lexRequest.send(function (err, _data) {
      logger.debug("sending lex request");
      if (!err) {
        const data = JSON.parse(_data.Payload);
        try {
          lexHandler(data).catch(e => logger.error(e));
        } catch(e) {
          logger.error(e);
        }
      }
      else (logger.error(err))
    });
}