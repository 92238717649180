// localUtils.js

import { Auth, Logger } from 'aws-amplify';
import { navigate } from '@reach/router';

import Iot from 'aws-sdk/clients/iot'; // eslint-disable-line import/no-extraneous-dependencies

const logger = new Logger('LocalUtils');

function nullableObjectPropValidator(props, propName, componentName) {
  const propValue = props[propName] // the actual value of `email` prop
  if (propValue === null) return
  if (propValue === undefined) return
  if (typeof propValue === 'object') return
  return new Error(`${componentName} only accepts undefined, null, or object`)
}

function nullableStringPropValidator(props, propName, componentName) {
  const propValue = props[propName] // the actual value of `email` prop
  if (propValue === null) return
  if (propValue === undefined) return
  if (typeof propValue === 'string') return
  return new Error(`${componentName} only accepts undefined, null, or string`)
}

function getCookieInformation(name) {
  const cookieItems = decodeURIComponent(document.cookie)
    .split(';');
  for (let i = 0; i < cookieItems.length; i += 1) {
    const index = cookieItems[i].search(`${name}=`);
    if (index > -1) { return cookieItems[i].substr(index + name.length + 1); } // +1 for the '='
  }
  return null;
}

/**
 * [attachIotPolicy description]
 * @return {[type]} [description]
 */
async function attachIotPolicy() {
  try {
    const iot = new Iot();
    const policyName = 'ernest_health_iot_policy';
    const credentials = Auth.essentialCredentials();

    // eslint-disable-next-line no-underscore-dangle
    const target = credentials._identityId;

    const { policies } = await iot.listAttachedPolicies({ target }).promise();

    if (!policies.find(policy => policy.policyName === policyName)) {
      await iot.attachPolicy({ policyName, target }).promise();
    }
  } catch (e) {
    // eslint-disable-next-line max-len
    // logger.debug(`error describing thing with thingName of ${gCameraName}, error: ${JSON.stringify(e, null, 2)}`);
    logger.debug('Error stack', JSON.stringify(e.stack, null, 2)); // original error message
  }
}


const setPath = (newPath) => { navigate(newPath); };
const setHomePath = () => { setPath('/'); };
const onRouteChange = (evt) => { logger.debug('onChange Router event: ', evt); };

export {
  getCookieInformation,
  attachIotPolicy,
  setPath,
  setHomePath,
  onRouteChange,
  nullableObjectPropValidator,
  nullableStringPropValidator
};
