import * as BABYLON from 'babylonjs';
import { Hub, Logger } from 'aws-amplify';

import { WeatherManager } from '../WeatherManager';
import { Vector3 } from 'babylonjs';

const weatherManager = new WeatherManager();

export { weatherManager };

export async function initWeatherParticles(scene) {
    const logger = new Logger('initParticles.js');


    //Manipulator to control posRot of weather particle emitters
    const weatherManipulator = BABYLON.Mesh.CreatePlane("weatherManipulator", 1.0, scene);
    weatherManipulator.position = new BABYLON.Vector3(6.5,4,-13);
    weatherManipulator.rotation.y = (333/180)*Math.PI;

    //TODO: reference particle systems from snippets
    let currentParticle;

    const _cancelWeather = () => {
        currentParticle && currentParticle.dispose();
    }

    const _startRain = async () => {        
        currentParticle = await BABYLON.ParticleHelper.CreateFromSnippetAsync("G0I0FF#5", scene, false);
        logger.debug('currentParticle: ', currentParticle);
        currentParticle.emitter = weatherManipulator; 
        currentParticle.start();
    };

    const _startSnow = async () => {
        currentParticle = await BABYLON.ParticleHelper.CreateFromSnippetAsync("3WF6UI#3", scene, false);
        currentParticle.emitter = weatherManipulator; 
        currentParticle.start();
    };

    const _startWind = async () => {
        currentParticle = await BABYLON.ParticleHelper.CreateFromSnippetAsync("9QUP5N#4", scene, false);
        currentParticle.emitter = weatherManipulator; 
        currentParticle.start();
    };

    const _startWeather = async (capsule) => {
        const { payload } = capsule;
        _cancelWeather();

        logger.debug(`starting particle effect for ${payload.data} weather type`);
        
        switch(payload.data) {
            case 'Rain':
                _startRain();
                break;
            case 'Snow':
                _startSnow();
                break;
            case 'Windy': 
            case 'Clouds':
                _startWind();
                break;
            default:
                logger.debug('invalid weather type for particle support');
        }
    }


    Hub.listen('startWeather', _startWeather);
    Hub.listen('cancelWeather', _cancelWeather);
}

