import HOST from 'amazon-sumerian-hosts/dist/host.babylon.js';
import * as BABYLON from 'babylonjs';
import { pullUserData, getVoiceFromLanguageCode } from '../js';
import store from '../_GlobalStateStore/GlobalStateStore';
import { I18n } from 'aws-amplify';


// Initialize the host
export async function createHost(
  character,
  audioAttachJoint,
  voice,
  engine,
  idleClip,
  faceIdleClip,
  lipsyncClips,
  gestureClips,
  gestureConfig,
  emoteClips,
  blinkClips,
  poiClips,
  poiConfig,
  lookJoint,
  bindPoseOffset,
  scene,
  camera,
) {
  // const HOST = await import('amazon-sumerian-hosts/dist/host.babylon.js');

  delete window._host;

  // Add the host to the render loop
  const host = new HOST.HostObject({ owner: character });
  window._host = host;
  // Set up text to speech

  const { userData } = store.getState().user;

  if (!userData) {
    await pullUserData();
  }

  const languageCode = store.getState().user.userData.primaryLanguage.code;
  if(!store.getState().primaryLanguage) {
    store.getActions().setPrimaryLanguage(languageCode);
    //slice the first two characters since I18n only expects language, not locale
    const langOnly = store.getState().primaryLanguage.substring(0, 2);
    I18n.setLanguage(langOnly);
  }

  const _voice = getVoiceFromLanguageCode(languageCode);


  host.addFeature(HOST.aws.TextToSpeechFeature, true, {
    scene,
    attachTo: audioAttachJoint,
    voice: _voice,
    engine,
  });

  // Set up animation
  host.addFeature(HOST.anim.AnimationFeature);

  // Base idle
  host.AnimationFeature.addLayer('Base');
  host.AnimationFeature.addAnimation('Base', idleClip.name, HOST.anim.AnimationTypes.single, {
    clip: idleClip,
    from: 1 / 30,
    to: idleClip.to,
  });
  host.AnimationFeature.playAnimation('Base', idleClip.name);

  // Face idle
  host.AnimationFeature.addLayer('Face', {
    blendMode: HOST.anim.LayerBlendModes.Additive,
  });
  BABYLON.AnimationGroup.MakeAnimationAdditive(faceIdleClip);
  host.AnimationFeature.addAnimation('Face', faceIdleClip.name, HOST.anim.AnimationTypes.single, {
    clip: faceIdleClip,
    from: 1 / 30,
    to: faceIdleClip.to,
  });
  host.AnimationFeature.playAnimation('Face', faceIdleClip.name);

  // Blink
  host.AnimationFeature.addLayer('Blink', {
    blendMode: HOST.anim.LayerBlendModes.Additive,
    transitionTime: 0.075,
  });
  blinkClips.forEach((clip) => {
    BABYLON.AnimationGroup.MakeAnimationAdditive(clip);
  });
  host.AnimationFeature.addAnimation('Blink', 'blink', HOST.anim.AnimationTypes.randomAnimation, {
    playInterval: 3,
    subStateOptions: blinkClips.map(clip => ({
      name: clip.name,
      loopCount: 1,
      clip,
    })),
  });
  host.AnimationFeature.playAnimation('Blink', 'blink');

  // Talking idle
  host.AnimationFeature.addLayer('Talk', {
    transitionTime: 0.75,
    blendMode: HOST.anim.LayerBlendModes.Additive,
  });
  host.AnimationFeature.setLayerWeight('Talk', 0);
  const talkClip = lipsyncClips.find(c => c.name === 'stand_talk');
  BABYLON.AnimationGroup.MakeAnimationAdditive(talkClip);
  lipsyncClips.splice(lipsyncClips.indexOf(talkClip), 1);
  host.AnimationFeature.addAnimation('Talk', talkClip.name, HOST.anim.AnimationTypes.single, {
    clip: talkClip,
  });
  host.AnimationFeature.playAnimation('Talk', talkClip.name);

  // Gesture animations
  host.AnimationFeature.addLayer('Gesture', {
    transitionTime: 0.5,
    blendMode: HOST.anim.LayerBlendModes.Additive,
  });

  gestureClips.forEach((clip) => {
    const { name } = clip;
    const config = gestureConfig[name];
    BABYLON.AnimationGroup.MakeAnimationAdditive(clip);

    if (config !== undefined) {
      // Add the clip to each queueOption so it can be split up
      config.queueOptions.forEach((option, index) => {
        option.clip = clip;
        option.to /= 30.0;
        option.from /= 30.0;
      });
      host.AnimationFeature.addAnimation('Gesture', name, HOST.anim.AnimationTypes.queue, config);
    } else {
      host.AnimationFeature.addAnimation('Gesture', name, HOST.anim.AnimationTypes.single, {
        clip,
        loopCount: 1,
      });
    }
  });

  // Emote animations
  host.AnimationFeature.addLayer('Emote', {
    transitionTime: 0.5,
  });

  emoteClips.forEach((clip) => {
    const { name } = clip;
    host.AnimationFeature.addAnimation('Emote', name, HOST.anim.AnimationTypes.single, {
      clip,
      loopCount: 1,
    });
  });

  // Viseme poses
  host.AnimationFeature.addLayer('Viseme', {
    transitionTime: 0.12,
    blendMode: HOST.anim.LayerBlendModes.Additive,
  });
  host.AnimationFeature.setLayerWeight('Viseme', 0);
  const blendStateOptions = lipsyncClips.map((clip) => {
    BABYLON.AnimationGroup.MakeAnimationAdditive(clip);
    return {
      name: clip.name,
      clip,
      weight: 0,
      from: 1 / 30,
      to: 2 / 30,
    };
  });
  host.AnimationFeature.addAnimation('Viseme', 'visemes', HOST.anim.AnimationTypes.freeBlend, {
    blendStateOptions,
  });
  host.AnimationFeature.playAnimation('Viseme', 'visemes');

  // POI poses
  const children = character.getDescendants(false);
  poiConfig.forEach((config) => {
    host.AnimationFeature.addLayer(config.name, {
      blendMode: HOST.anim.LayerBlendModes.Additive,
    });

    // Find each pose clip and make it additive
    config.blendStateOptions.forEach((clipConfig) => {
      const clip = poiClips.find(clip => clip.name === clipConfig.clip);
      BABYLON.AnimationGroup.MakeAnimationAdditive(clip);
      clipConfig.clip = clip;
      clipConfig.from = 1 / 30;
      clipConfig.to = 2 / 30;
    });

    host.AnimationFeature.addAnimation(
      config.name,
      config.animation,
      HOST.anim.AnimationTypes.blend2d,
      { ...config },
    );

    host.AnimationFeature.playAnimation(config.name, config.animation);

    // Find and store the reference object
    config.reference = children.find(child => child.name === config.reference);
  });

  // Apply bindPoseOffset clip if it exists
  if (bindPoseOffset !== undefined) {
    host.AnimationFeature.addLayer('BindPoseOffset', {
      blendMode: HOST.anim.LayerBlendModes.Additive,
    });
    host.AnimationFeature.addAnimation(
      'BindPoseOffset',
      bindPoseOffset.name,
      HOST.anim.AnimationTypes.single,
      { clip: bindPoseOffset, from: 1 / 30, to: 2 / 30 },
    );
    host.AnimationFeature.playAnimation('BindPoseOffset', bindPoseOffset.name);
  }

  // Set up Lipsync
  const visemeOptions = {
    layers: [
      {
        name: 'Viseme',
        animation: 'visemes',
      },
    ],
  };
  const talkingOptions = {
    layers: [
      {
        name: 'Talk',
        animation: 'stand_talk',
        blendTime: 0.75,
        easingFn: HOST.anim.Easing.Quadratic.InOut,
      },
    ],
  };
  host.addFeature(HOST.LipsyncFeature, false, visemeOptions, talkingOptions);

  // Set up Gestures
  host.addFeature(HOST.GestureFeature, false, {
    layers: {
      Gesture: { minimumInterval: 1 },
      Emote: {
        blendTime: 0.5,
        easingFn: HOST.anim.Easing.Quadratic.InOut,
      },
    },
  });

  // Set up Point of Interest
  host.addFeature(
    HOST.PointOfInterestFeature,
    false,
    {
      target: camera,
      lookTracker: lookJoint,
      scene,
    },
    {
      layers: poiConfig,
    },
    {
      layers: [{ name: 'Blink' }],
    },
  );

  // scene.onBeforeAnimationsObservable.add(() => {
  //   host.update();
  // });

  window._addisonHost = host;
  return host;
}
