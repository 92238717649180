// import React from 'react';

import { Logger } from 'aws-amplify';


const SFX = (name) => {
    const logger = new Logger('SFX');
    let url = "";
    switch (name) {

        case "click":
            url = "https://ecg-sumerian-assets.s3.amazonaws.com/Audio/SFX/Button_Click_SFX.wav";
            break;
        case "slidein":
            url = "https://ecg-sumerian-assets.s3.amazonaws.com/Audio/SFX/Buttons_SlideIn_SFX_mixdown.wav";
            break;
        case "expand":
            url = "https://ecg-sumerian-assets.s3.amazonaws.com/Audio/SFX/Menu_Expand_SFX_mixdown_V02.wav";
            break;

        case "listen":
            url = "https://ecg-sumerian-assets.s3.amazonaws.com/Audio/SFX/Tablet_Listen_SFX.wav";
            break;
        case "stark":
            url = "https://ecg-sumerian-assets.s3.amazonaws.com/Audio/SFX/Tablet_Stark_SFX.wav";
            break;
        case "stopListen":
            url = "https://ecg-sumerian-assets.s3.amazonaws.com/Audio/SFX/Tablet_StopListen_SFX.wav";
            break;
        case "blur":
            url = "https://ecg-sumerian-assets.s3.amazonaws.com/Audio/SFX/Tutorial_Blur_SFX_mixdown.wav";
            break;

        default:
            url = undefined;
    }

    logger.debug("Playing Sound", url, name);

    if (url) {
        try {
            let audio = new Audio(url);
            audio.play();
        } catch(e) {
            logger.error(`Error playing sound ${name}`);
        }
    }


}

export default SFX;