import React from 'react';
import moment from 'moment';

// custom tooltip for graph
const CustomTooltip = ({ payload, unit }) => {
    // logger.debug("payload: ", payload);
    // logger.debug("unit: ", unit);
    
    if (payload.length === 0) return null;

    if(payload[1].dataKey === "heartRate") {
      unit = "BPM";
    } else if(payload[1].dataKey === "oxygen") {
      unit = "%";
    }

    return (
        <div className='vitals-scatter-chart__custom-tooltip-container'>
      <h2>{ moment(payload[0].value).format('LLLL') }</h2>
      <div className='vitals-scatter-chart__custom-tooltip-value-container'>
        <h1>{`${payload[1].value}`}</h1>
        <p>{unit}</p>
      </div>
    </div>
    );
}

export default CustomTooltip;