import {Hub} from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore';

/**
 * Handle MedButtonPressed state machine
 */
export class MedButtonPressBehavior {
  constructor() {
    this.setupListeners();
  }

  MedButtonPressListener = capsule => {
    const {payload} = capsule;
    const {data} = payload;

    // const host = store.getState().host;
    const scene = store.getState().scene;

    const anim = scene.getAnimationGroupByName(data);

    anim.play(false);
  };

  /**
   * setup relevant Hub listeners
   */
  setupListeners() {
    Hub.listen('MedButtonPress', this.MedButtonPressListener);
  }

  /**
   * tear down relevant Hub listeners
   */
  cleanupListeners() {
    Hub.remove('MedButtonPress', this.MedButtonPressListener);
  }
}
