/**
 * Medication Table
 *
 * @author Devonte Gabriel
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const UpcomingMeds = ({ header, data }) => {
  const [currentRowsShown, setCurrentRowsShown] = useState();
  useEffect(() => {
    if (!data) return;
    // fill missing rows with placeholders
    while (data.length % 4 !== 0) {
      console.log(data[0]);
      data = [...data, { // eslint-disable-line react-hooks/exhaustive-deps
        filler1: '-',
        filler2: '-',
        filler3: '-',
      },
      ];
    }
    setCurrentRowsShown(data.slice(0, 4));
  }, [data]);
  // { currentRowsShown.map((row, index) => (
  //   <div className={`generic-table__row ${index % 2 === 0 && 'generic-table__row--even'}`}>
  //     {Object.keys(row).map(key => <h3>{row[key]}</h3>)}
  //   </div>
  // )) }

  console.log(currentRowsShown);
  if (!currentRowsShown) return null;
  return (
    <>
      <div className="med-table">
        <h1 className="med-table__title">
          {header}
        </h1>
        <div className="med-table__table-container">
          <div className="med-table__table">
            <div className="med-table__row med-table__row--header">
              {Object.keys(data[0]).slice(0, 3).map(key => <h2>{key}</h2>)}
            </div>
            {currentRowsShown.map((med, index) => (
              <div className={`med-table__row ${index % 2 === 0 && 'med-table__row--even'}`}>
                {Object.keys(med).slice(0, 3).map((key, index) => <h3 key={med[index]}>{med[key]}</h3>)}
              </div>
            )) }
          </div>
          <div className="med-table__footer">
            <div className="med-table__date-time">
              <div className="med-table__date-time-header">
                <h2>
                  Status:
                </h2>
              </div>
              <h1> Upcoming Medication</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpcomingMeds;
