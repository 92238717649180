import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';


/**
 * This fuction will trigger lambda PMUFetchMedicationHistory
 */
async function fetchPMUMedicationReminder() {
  const session = await Auth.currentSession();
  const { payload } = session.idToken;
  const username = payload['cognito:username'];

  const body = {
    // eslint-disable-next-line object-shorthand
    username: username,
  };
  const creds = await Auth.currentCredentials();
  const lambda = new AWS.Lambda({
    region: window.ADDISON_REGION,
    sessionToken: creds.sessionToken,
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
  });

  const lambdaParams = {
    FunctionName: 'arn:aws:lambda:us-east-1:479226928101:function:PMUFetchMedication',
    InvocationType: 'RequestResponse',
    Payload: JSON.stringify(body),
  };

  const medData = await lambda.invoke(lambdaParams).promise()
    .then(data => JSON.parse(data.Payload).Items);
  return medData;
}
export default fetchPMUMedicationReminder;
