import {Hub, Logger} from 'aws-amplify';
import store from '../../_GlobalStateStore/GlobalStateStore';

const logger = new Logger('SSMLEventHandler');

function setup() {
  //grab the host, in order to setup the ssmlEvent observable
  const host = store.getState().host;

  host.listenTo(host.TextToSpeechFeature.EVENTS.ssml, evt => {
    logger.debug('evt: ', evt);
    listenerSsml(evt.mark.value);
  });

  store.getState().worldData.listenerSsml = listenerSsml;
}

function listenerSsml(mark) {
  logger.debug('received mark: ', mark);
  // 	let minimark = mark.indexOf("emit:")
  // 	if (minimark > -1){
  // 		let message = mark.slice(5)
  // 		Hub.dispatch(message);
  // 		//logger.debug(message)
  // 		return;
  // 	}
  if (!Array.isArray(mark)) {
    mark = [mark];
  }
  let marker = mark;
  marker.forEach(mark => {
    const data = mark.split(':');
    logger.debug('data: ', data);
    if (mark.startsWith('emit:')) {
      const emitRes = mark.split(':');
      logger.debug(`dispatching to channel ${emitRes[1]}`)
      Hub.dispatch(emitRes[1]);
    } else if (mark.startsWith('gesture')) {
      const dat = mark.split(':');
      Hub.dispatch('gesture', {data: dat[1]});
    } else if (mark.startsWith('emote:')) {
      const dat = mark.split(':');

      Hub.dispatch('emote', {data: dat[1]});
    } else if (mark.startsWith('transitionBackground:')) {
      const dat = mark.split(':');
      Hub.dispatch('transitionBackground', {data: dat[1]}); //sumerian.SystemBus.addListener('transitionBackground', (data) => { }); ===> Hub.listen('transitionBackground', )
      Hub.dispatch('NotAFK');
      Hub.dispatch('privacyAnimOff');
    } else if (mark.startsWith('changeBackground:')) {
      const dat = mark.split(':');
      Hub.dispatch('changeBackground', {data: dat[1]});
    } else if (mark.startsWith('showElement:')) {
      const dat = mark.split(':');
      Hub.dispatch('showElement', {data: dat[1]});
    } else if (mark.startsWith('hideElement:')) {
      const dat = mark.split(':');
      Hub.dispatch('hideElement', {data: dat[1]});
    } else if (mark.startsWith('showGroup:')) {
      const dat = mark.split(':');
      Hub.dispatch('showGroup', {data: dat[1]});
    } else if (mark.startsWith('hideGroup:')) {
      const dat = mark.split(':');
      Hub.dispatch('hideGroup', {data: dat[1]});
    } else if (mark.startsWith('hideGroups')) {
      Hub.dispatch('hideAllGroups');
      logger.debug('%cHiding all groups', 'color: orange;');
    } else if (mark.startsWith('swapGroup:')) {
      const dat = mark.split(':');
      Hub.dispatch('swapGroup', {data: dat[1]});
      logger.debug('%cSwapping Group', 'color: orange;');
    } else if (mark.startsWith('mute')) {
      Hub.dispatch('StartPrivacyMode');
    } else if (mark.startsWith('unmute')) {
      Hub.dispatch('StopPrivacyMode');
    } else if (mark.startsWith('forcePassiveListen')) {
      Hub.dispatch('forcePassiveListening');
    } else if (mark.startsWith('loadBot:')) {
      const dat = mark.split(':');
      Hub.dispatch('loadBot', {data: dat[1]});
    } else if (mark.startsWith('lexPlayScene:')) {
      const dat = mark.split(':');
      Hub.dispatch('lexPlayScene', {data: dat[1]});
    } else if (mark.startsWith('blurOn')) {
      Hub.dispatch('backBlurOn');
    } else if (mark.startsWith('blurOff')) {
      Hub.dispatch('backBlurOff');
    } else if (mark.startsWith('showHistory')) {
      const dat = mark.split(':');
      Hub.dispatch('showHistory', {data: dat[1]});
    } else if (mark.startsWith('showVital')) {
      logger.debug('showVital emitted from Sumerian');
      const dat = mark.split(':');
      Hub.dispatch('showVital', {data: dat[1]});
    } else if (mark.startsWith('takeVital')) {
      const dat = mark.split(':');
      Hub.dispatch('takeVital', {data: dat[1]});
    } else if (mark.startsWith('selectVital')) {
      const dat = mark.split(':');
      Hub.dispatch('selectVital', {data: dat[1]});
    } else if (mark.startsWith('navigateTo')) {
      const dat = mark.split(':');
      logger.debug('navigateTo detected in Lex SSML prompt. Path: ', dat[1]);
      Hub.dispatch('navigateTo', {data: dat[1]});
    } else if (mark.startsWith('callRP')) {
      const dat = mark.split(':');
      Hub.dispatch('responsibleParty', {data: dat[1]});
    } else if (mark.startsWith('clothingcolor')) {
      Hub.dispatch(mark.toLowerCase());
    }

    //Send any non standard emits through to our scenes.
    else {
      const [channel, data] = mark.split(':');

      Hub.dispatch(channel, { data });
    }
  });
}

function cleanup() { }

export const SSMLObject = {
  setup,
  cleanup,
  ssmlHandler: listenerSsml,
};
