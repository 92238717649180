/** 
 * MedReminderTable 
 * 
 * @author Michael Lyon 
 */ 
 
import React, { useEffect } from 'react'; 
import moment from 'moment'; 
import { Hub } from 'aws-amplify'; 
import store from '../../../_GlobalStateStore/GlobalStateStore'; 
 
import GenericTable from '../../GenericTable/GenericTable'; 
 
const MedReminderTable = ({ data, time }) => { 
  useEffect(() => { 
    store.getActions().eventManager.addListener({ 
      channel: 'tableNext', 
      callback: () => { 
        Hub.dispatch('tableNext'); 
      }, 
    }); 
    store.getActions().eventManager.addListener({ 
      channel: 'tablePrevious', 
      callback: () => { 
        Hub.dispatch('tablePrevious'); 
      }, 
    }); return () => {
      store.getActions().eventManager.removeListener({ 
        channel: 'tableNext',
      }); 
      store.getActions().eventManager.removeListener({ 
        channel: 'tablePrevious',
      });
    }; 
  }, []);
 
  return ( 
    <GenericTable 
      tableName="Medication Reminder" 
      footerText={`${moment().format('dddd')} ${moment(`1/1/2000 ${time}`).format('h:ssA')}`} 
      data={data} 
    /> 
  ); 
}; 
 
export default MedReminderTable; 
