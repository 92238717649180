import * as BABYLON from 'babylonjs'; // hack for Host system (would prefer ES6)
import store from '../_GlobalStateStore/GlobalStateStore';

/**
 * initialize the camera and return an instance to it
 * @param {BABYLON.Scene} scene -- the scene to attach the camera to
 */
export function initCamera(scene) {
  // instantiate the camera and position it
  // const camera = new BABYLON.ArcRotateCamera(
  //   'Camera',
  //   BABYLON.Tools.ToRadians(90),
  //   BABYLON.Tools.ToRadians(90),
  //   1,
  //   new BABYLON.Vector3(0, 1.6, 0),
  //   scene
  // );

  const DEFAULT_CAMERA_POS = new BABYLON.Vector3(0.3, 1.6, 1.4);
  const DEFAULT_CAMERA_POS_2 = new BABYLON.Vector3(0, 1.6, -2.6);

  // instantiate the camera and position it
  const camera1 = new BABYLON.FreeCamera('Camera', DEFAULT_CAMERA_POS, scene);
  const camera2 = new BABYLON.FreeCamera('Camera2', DEFAULT_CAMERA_POS_2, scene);

  // camera1.rotation.y = Math.PI;
  camera1.rotation = new BABYLON.Vector3(0.174533, 3.00197, 0);
  camera1.fov = 0.872665;
  camera1.speed = 0.5;
  camera1.inertia = 0.5;

  camera1.minZ = 0.1;
  camera1.maxZ = 1000;
  // camera.setPosition(new BABYLON.Vector3(0, 1.6, 2.6));
  camera1.wheelDeltaPercentage = 0.01;
  camera1.wheelPrecision = 50;

  camera2.minZ = 0.1;
  camera2.maxZ = 1000;
  // camera.setPosition(new BABYLON.Vector3(0, 1.6, 2.6));
  camera2.wheelDeltaPercentage = 0.01;
  camera2.wheelPrecision = 50;
  camera2.speed = 0.5;
  camera2.inertia = 0.5;

  // store a reference in the window because it doesn't need to be garabage collected
  window._defaultCamera = camera1;
  store.getActions().setCamera(camera1);

  const cameras = [camera1, camera2];

  // scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao", camera2);

  store.getActions().setCameras(cameras);

  return cameras;
}
