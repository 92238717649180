/* eslint-disable camelcase */
import { Logger } from 'aws-amplify';
import awsmobile from './aws-exports';

const logger = new Logger('ConfigsModule');

const {
  aws_project_region,
  aws_content_delivery_bucket,
} = awsmobile;

const chooseAndSetEnv = () => {
  if (aws_content_delivery_bucket.includes('-dev') && !aws_content_delivery_bucket.includes('-staging')) {
    window.ADDISON_ENV = 'dev';
    window.ADDISON_REGION = 'us-east-1';
  }
  if (aws_content_delivery_bucket.includes('-staging')) {
    window.ADDISON_ENV = 'staging';
    window.ADDISON_REGION = 'us-west-2';
  }
  if (aws_content_delivery_bucket.includes('-prod')) {
    window.ADDISON_ENV = 'prod';
    window.ADDISON_REGION = 'us-east-1';
  }

  if (aws_content_delivery_bucket.includes('-germandev')) {
    window.ADDISON_ENV = 'german';
    window.ADDISON_REGION = 'eu-west-1';
  }


  return window.ADDISON_ENV;
};

const patchedConfig = () => {
  const env = chooseAndSetEnv();

  logger.debug('chosen env: ', env);

  if (env === 'dev') {
    return {
      aws_cognito_identity_pool_id: 'us-east-1:84ae5c5e-8c9c-4282-953e-ab78156a0fd2',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_BiApgaLXJ',
      aws_user_pools_web_client_id: '3lrikrm8j33itbgfbu99icjnog',
      oauth: {},
      aws_appsync_graphqlEndpoint: 'https://ahligu7qhrggti3ynofjojovee.appsync-api.us-east-1.amazonaws.com/graphql',
      aws_appsync_region: 'us-east-1',
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    };
  } if (env === 'staging' || env === 'german') {
    return {
      aws_cognito_identity_pool_id: 'us-east-1:84ae5c5e-8c9c-4282-953e-ab78156a0fd2',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_BiApgaLXJ',
      aws_user_pools_web_client_id: '3lrikrm8j33itbgfbu99icjnog',
      oauth: {},
      aws_appsync_graphqlEndpoint: 'https://ahligu7qhrggti3ynofjojovee.appsync-api.us-east-1.amazonaws.com/graphql',
      aws_appsync_region: 'us-east-1',
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    };
  }
  return {}; // nothing to patch
};

const patch = patchedConfig();

const patched = {
  ...awsmobile,
  ...patch,
};

const authConfig = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: patched.aws_cognito_identity_pool_id,
    // REQUIRED - Amazon Cognito Region
    region: patched.aws_cognito_region,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: patched.aws_user_pools_id,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: patched.aws_user_pools_web_client_id,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
};
const apiConfig = {
  aws_appsync_graphqlEndpoint: patched.aws_appsync_graphqlEndpoint,
  aws_appsync_region: patched.aws_appsync_region,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
};

// const patched = patchedConfig();

export const amplifyConfig = {
  ...awsmobile,
  ...authConfig,
  ...apiConfig,
};

export default amplifyConfig;
