import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { Logger, Hub } from 'aws-amplify';
import { navigate } from '@reach/router';
import Lottie from 'react-lottie';
import 'amazon-connect-streams'; // get access to the `connect` object

import { CALL_TYPES } from '../../js';

import { ReactComponent as EndCall } from './icons/Icon_CallEndCall.svg';
import { ReactComponent as Help } from './icons/Icon-Call_Alert.svg';
import { ReactComponent as CustomerSupport } from './icons/Icon-Call_CustomerSupport.svg';
import { ReactComponent as Doctor } from './icons/Icon-Call_PocketMD.svg';
import { ReactComponent as ResponsibleParty } from './icons/Icon-Call_ResponsibleParty.svg';

import blueAnimation from './animations/CallScreenAnim_v01_blue.json';
import redAnimation from './animations/CallScreenAnim_v01_red.json';

import './style.css';

/* global connect */

const CallAnimation = ({ animation }) => (
  <div className="dialer__animContainer">
    <Lottie
      isClickToPauseDisabled
      options={{
        animationData: animation,
      }}
    />
  </div>
);

/**
 * interface for interacting with an active call (mostly to render a hang-up/end call button)
 * @param {React.Props} props
 */
export const AppDialer = (props) => {
  const logger = new Logger('AppDialer');

  const endCall = <EndCall />;

  const callType = useStoreState(state => state.callType);
  const [style, setStyle] = useState('support');
  const [callingText, setCallingText] = useState('Customer Support');
  const [currIcon, setCurrIcon] = useState(<CustomerSupport />);
  const [anim, setAnim] = useState(blueAnimation);

  /**
   * set the correct styling depending on the call type
   */
  useEffect(() => {
    if (callType === CALL_TYPES.monitoring) {
      setStyle('help');
      setCallingText('Electronic Caregiver');
      setCurrIcon(<Help />);
      setAnim(redAnimation);
    } else if (callType === CALL_TYPES.doctor) {
      setStyle('doctor');
      setCallingText('PocketMD');
      setCurrIcon(<Doctor />);
      setAnim(blueAnimation);
    } else if (callType === CALL_TYPES.support) {
      setStyle('support');
      setCallingText('Customer Support');
      setCurrIcon(<CustomerSupport />);
      setAnim(blueAnimation);
    }
  }, [callType]);

  // const _endCall = ;

  return (
    <div className={`${style}`}>
      {/* animation */}
      <CallAnimation animation={anim} />
      <div className="callingTextContainer">
        <p className="calling">Calling...</p>
        <br />
        <p className="callingText">{callingText}</p>
      </div>
      <div>
        <div className="callIconBorderContainer">
          <div className="callIcon">{currIcon}</div>
        </div>
      </div>
      <button
        className="endCallButton"
        onClick={(e) => {
          e.preventDefault();
          navigate('/');
          setTimeout(() => Hub.dispatch('hideSplashScreen'), 1000);

          if (!window._connectContact) {
            console.error('no contact to terminate');
            return;
          }

          window._connectContact.clear();
          window._connectContact.destroy({
            success() {
              logger.debug('call ended');
            },
          });

          connect.core.terminate();
          const containerDiv = document.getElementById('containerDiv');
          const iframe = containerDiv.firstElementChild; // assumes there's nothing else in the container
          containerDiv.removeChild(iframe);
          delete window._connectContact;
        }}
      >
        <div className="endCallIcon">{endCall}</div>
        {' '}
        End Call
      </button>
    </div>
  );
};
