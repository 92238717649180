import { Hub, Logger } from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore';
import { postToLex } from '../js';

const logger = new Logger('HomeScene');

async function setup() {
	console.log("....... in home scene setup");
	/* 
	!!! This script handles setting up element creation, and simplifies the process greatly. Simply add your elements and elementgroups to the lists !!!

	You can call an element from lex or a speech file by using the following commands: 
	 
	Displaying an Element: 
	<mark name="showElement:elementName"/>
	
	Hiding an Element:
	<mark name="hideElement:elementName"/>
	
	Showing an Element Group:
	<mark name="showGroup:groupName"/>
	
	Hiding an Element Group:
	<mark name="hideGroup:groupName"/>

	Hiding all non stickied groups:
	<mark name="hideGroups"/>

	Elements are UI things that need animation. Outlines, tables, etc, go here.
	This is where we setup shorthand for speech to call, or for another function to call, without us having to put a whole element summon in a speech file.
	Use the default options as examples to expand upon.
	Use sEmit for a sumerian emit, and emit for an amplify emit. You can have both.
	
	
	 * Button Types:
	Here is a list of all button types we can adjust. This should account for any button in the scene
	All buttons should be modular and allow for any value, with the exception of *maybe* start and stop listening. That may change.

	Each of these is structured like this
	 - id : Description. When you're creating a button, the id of the type is the only thing you should worry about.

	 - $ at beginning of emit means it will send to the sumerian system bus instead of the amplify hub.

	= Top Bar Buttons = (These probably won't need to be changed from defaults, but who knows)
	 - assist : Assist
	 - addison : Addison
	 - listen : Stop Listening / Start Listening (Probably not modular, hardcoded)
	 - tertiary : Tertiary / button on top. Used primarily for testing.

	= Three Button Sidebar =
	 - sBtn1 : Sidebar Button 1
	 - sBtn2 : Sidebar Button 2
	 - sBtn3 : Sidebar Button 3

	= Yes / No Sidebar =
	 - sYes : Sidebar Yes
	 - sNo : Sidebar No

	= Sidebar Other =
	 - sDone : Sidebar Done
	 - sReady : Sidebar Ready (The one with the flag?)


	* Element Types
	Elements are functionally the same as buttons, but do not use any onclick events. This may be graphs, tables, graphics, titles, etc.

	= Tables =
	 - mTable : Medical Table. Used for most tables / Lists
	 - scatterChart : Scatter Chart, default addison scatter chart.
	
	 **************************************************************************************
	 *																					  *
	 * 								  	  Elements										  *
	 * 																					  *
	 **************************************************************************************/

	store.getActions().addMyElements({

	//************** VITALS SCENE *******************

		takeVital: {			text: "Take Vital",			to: "/sceneVitals/takeVital",		type: "sidebarButton1",			sEmit: "post_to_lex:take vital",			icon: "Vitals",				id: "btn1",		},
		TakeVital: {			text: "Take Vital",			to: "/sceneVitals/takeVital",		type: "sidebarButton1",			sEmit: "post_to_lex:take vital",			icon: "Vitals",				id: "btn1",		},

		takeHistory: {			text: "Review History",		to: "/sceneVitals",					type: "sidebarButton2",			sEmit: "post_to_lex:show history",			icon: "VitalsHistory",		id: "btn2",		},
		TakeHistory: {			text: "Review History",		to: "/sceneVitals",					type: "sidebarButton2",			sEmit: "post_to_lex:show history",			icon: "VitalsHistory",		id: "btn2",		},

		vitalDone: {			text: "Vitals",				to: "/sceneVitals",		type: "sidebarDone",			sEmit: "post_to_lex:Vitals",				icon: "Done",		},
		VitalDone: {			text: "Vitals",				to: "/sceneVitals",		type: "sidebarDone",			sEmit: "post_to_lex:Vitals",				icon: "Done",		},

		menuBack: {			text: "Back",			to: "/sceneVitals",			type: "sidebarDone",			sEmit: "post_to_lex:show vital menu",			icon: "Left",		  },

		allBack: {				text: "Back",				to: "/",							type: "sidebarDone",			sEmit: "post_to_lex:done",					icon: "Left",		},

		tutorial: { 			text: "Tutorial", 												type: "tutorial", emit: "tutorial", sEmit: "post_to_lex:tutorial", 			icon: "Tutorial" },
		Tutorial: { 			text: "Tutorial", 												type: "tutorial", emit: "tutorial", sEmit: "post_to_lex:tutorial",			icon: "Tutorial" },

		reload: { 				text: "Reload", 												type: "tertiary", emit: "reload", 											icon: "" },
		Reload: { 				text: "Reload",													type: "tertiary", emit: "reload", 											icon: "" },


	//************** HOME SCENE/universal? *******************

		MedicationsButton: {	text: "Medications", 											type: "sidebarButton1", 	emit: "defaultAnim", 	sEmit:"post_to_lex:medications", 		icon:"Medication",		id:"Sbtn1", }, 	 //Medications select
		VitalsButton: {			text: "Vitals", 												type: "sidebarButton2", 	emit: "defaultAnim", 	sEmit:"post_to_lex:vitals", 			icon:"Vitals", 			id:"btn2", },  //Vitals select
		DoneButton: {			text: "Done", 													type: "sidebarDone", 		emit: "defaultAnim",	sEmit:"post_to_lex:done", 				icon:"Done", 			id:"sDone", }, //Manage Medications select
		BackButton: {			text: "Back",													type: "sidebarDone", 		emit: "defaultAnim",	sEmit:"post_to_lex:done", 				icon:"Left"}, // Back button

		//MotionButton: {			text: "Motion", 												type:"sidebarButton3", 	emit:"defaultAnim",		sEmit:"post_to_lex:motionanalysis", 	icon:"Motion"},			id:"btn3", 	 //Motion select
		//MotionAnalysisButton: {	text: "Test Gait", 												type:"sidebarButton1", 	emit:"defaultAnim",		sEmit:"post_to_lex:motionanalysis", 	icon:"GaitAnalysis"},   id:"btn1", //Motion Analysis select
		
		emergencyButton: { 		text: "Emergency",												type: "sidebarButton1Red", emit: ["defaultAnim", "playHelpScene", "hideAllElements"], sEmit: "post_to_lex:emergency", icon: "Emergency" },
		EmergencyButton: { 		text: "Emergency", 												type: "sidebarButton1Red", emit: ["defaultAnim", "playHelpScene", "hideAllElements"], sEmit: "post_to_lex:emergency", icon: "Emergency" },
		tutorialButton: { 		text: "Tutorial", 												type: "sidebarButton3", emit: "defaultAnim", sEmit: "post_to_lex:tutorial", 									  icon: "Tutorial" },
		TutorialButton: { 		text: "Tutorial", 												type: "sidebarButton3", emit: "defaultAnim", sEmit: "post_to_lex:tutorial", 									  icon: "Tutorial" },

		//PocketMDButton: { 		text: "pocketMD", 												type: "sidebarButton2", emit: ["defaultAnim", "playHelpScene", "hideAllElements"], sEmit: "post_to_lex:pocketMD", icon: "PocketMD" },
		//pocketMDButton: { 		text: "pocketMD", 												type: "sidebarButton2", emit: ["defaultAnim", "playHelpScene", "hideAllElements"], sEmit: "post_to_lex:pocketMD", icon: "PocketMD" },

		//supportButton: { 		text: "Customer Support", 										type: "sidebarButton3", emit: "defaultAnim", sEmit: "post_to_lex:support", 										  icon: "CustomerSupport" },
		//SupportButton: { 		text: "Customer Support", 										type: "sidebarButton3", emit: "defaultAnim", sEmit: "post_to_lex:support", 										  icon: "CustomerSupport" },

		//AlexaButton: { 			type: "alexaButton", 																	emit: "defaultAnim", sEmit: "post_to_lex:Alexa", 										  icon: "Alexa" },
		//alexaButton: { 			type: "alexaButton", 																	emit: "defaultAnim", sEmit: "post_to_lex:Alexa", 										  icon: "Alexa" },
		lowCancel: { 			text: "Back", 													type: "sidebarDone", 	emit: "defaultAnim", sEmit: "post_to_lex:home", icon: "Left" },
		LowCancel: { 			text: "Back", 													type: "sidebarDone", 	emit: "defaultAnim", sEmit: "post_to_lex:home", icon: "Left" },

		backCancel: { 			text: "Cancel",													type: "sidebarDone", 	emit: "defaultAnim", sEmit: "post_to_lex:cancel", icon: "No" },
		BackCancel: { 			text: "Cancel", 												type: "sidebarDone", 	emit: "defaultAnim", sEmit: "post_to_lex:cancel", icon: "No" },

		sampleVideo: { 																			type: "fullscreenVideo", link: "https://addison-project-anim-files.s3.amazonaws.com/Videos/VitalsTutorials/BPTutorial_v01.mp4" },
		SampleVideo: { 																			type: "fullscreenVideo", link: "https://addison-project-anim-files.s3.amazonaws.com/Videos/VitalsTutorials/BPTutorial_v01.mp4" },

	//	Home: 		{ 			text: "Home",       											type: "higher", 	emit: ["cancelTutorial", "lexPlayScene:Home", "defaultAnim"], sEmit: "post_to_lex:home", 		icon: "AddisonHome" },

	//*********************************************************** MEDICATIONS SCENE *******************************************************************************

		TakeMedsButton: {		text: "Take Medications", 		to:"medicationCompliance",		type:"sidebarButton1", 	emit:"defaultAnim", 	sEmit:"post_to_lex:current meds", 		 		icon:"Medication"},		id:"btn1", //Take Medication select
		PreviousMedsButton: {	text: "Previous Medications",	to: "medicationCompliance/previous-medications",	type:"sidebarButton2", 	emit:"defaultAnim", 	sEmit:"post_to_lex:previous medications", 		 		icon:"Medication"}, 	id:"btn2", //Take Medication select
		UpcomingMedsButton: {	text: "Upcoming Medications",	to: "medicationCompliance/upcoming-medications",	type:"sidebarButton3", 	emit:"defaultAnim", 	sEmit:"post_to_lex:upcoming", 		 		icon:"Medication"}, 	id:"btn3", //Take Medication select

		//ManageMedsButton: {		text: "Review Medications", 									type:"sidebarButton2", 	emit:"defaultAnim", 	sEmit:"post_to_lex:reviewMedications", 	 		icon:"Medication"}, 	id:"btn2", //Manage Medications select

		AlarmOffButton: {		text: 'Alarm Off',	to: 'medicationCompliance/alarm-disabled', type: 'sidebarUnderTable2', emit: "defaultAnim", sEmit:"post_to_lex:alarm off",					icon: 'Yes',	},

		MedTakeYes: { 			text: "Yes", 	to: "medicationCompliance/take-medications/ready", 	type: "sidebarYes", emit: "defaultAnim", 	sEmit: "post_to_lex:yes" },
		MedTakeNo: { 			text: "No", 	to: "medicationCompliance/alarm-disabled", 	type: "sidebarNo", emit: "defaultAnim", 	sEmit: "post_to_lex:no" },
		MedTakeNotYet: { 			text: "Not Yet", 	to: "medicationCompliance/alarm-snoozed", 	type: "sidebarNotYet", emit: "defaultAnim", 	sEmit: "post_to_lex:not yet" },

		MedTakeReady: { text: "Ready", type: "sidebarButton1", to: "medicationCompliance/take-medications/ready", sEmit:"post_to_lex:ready", emit: "defaultAnim" },
		MedTakeDone: { text: "Done", type: "sidebarButton1", to: "medicationCompliance", sEmit:"post_to_lex:done", emit: "defaultAnim" },
		MedPreviousDone: { text: "Done", type: "sidebarButton1", to: "medicationCompliance", sEmit:"post_to_lex:done", emit: "defaultAnim" },
		MedUpcomingDone: { text: "Done", type: "sidebarButton1", to: "medicationCompliance", sEmit:"post_to_lex:done", emit: "defaultAnim" },
		


		//*********************************************************** First Addison Experience Scene********************************************************************
		TutorialReadyButton: {	
			text: "Ready", 													
			type:"sidebarButton1", 	
			emit:"defaultAnim", 	
			sEmit:"post_to_lex:ready", 		 		
			icon:"ThumbsUp"}, 		
			id:"btn1", 
			//Start FAE Ready

		NotificationBarFAE: {	 																type:"notifications", 		 																				}, 			id:"btnNotification",
		AddisonButton: {	 text: "Addison", 													type:"addison", 		emit:"defaultAnim", 													icon:"Addison"}, 		id:"btnAddison",


		FavoriteColorButton: {	text: "Favorite Color", 										type:"sidebarButton1", 	emit:"defaultAnim", 	sEmit:"post_to_lex:favorite color", 		 	icon:"Power"}, 			id:"btn1", //Favorite Color Button
		
		RedButton: {			text: "Red", 													type:"sidebarButton7", 	emit:"defaultAnim", 	sEmit:"post_to_lex:red", 		 				 			},			id:"btn1", //Color Choice Button
		OrangeButton: {			text: "Orange", 												type:"sidebarButton8", 	emit:"defaultAnim", 	sEmit:"post_to_lex:orange", 		 				 		},			id:"btn2", //Color Choice Button
		GreenButton: {			text: "Green", 													type:"sidebarButton9", 	emit:"defaultAnim", 	sEmit:"post_to_lex:green", 		 				 			},			id:"btn3", //Color Choice Button
		BlueButton: {			text: "Blue", 													type:"sidebarButton10", emit:"defaultAnim", 	sEmit:"post_to_lex:blue", 		 				 			},			id:"btn4", //Color Choice Button
		PurpleButton: {			text: "Purple", 												type:"sidebarButton11", emit:"defaultAnim", 	sEmit:"post_to_lex:purple", 		 				 		},			id:"btn5", //Color Choice Button
		PinkButton: {			text: "Pink", 													type:"sidebarButton12", emit:"defaultAnim", 	sEmit:"post_to_lex:pink", 		 				 			},			id:"btn6", //Color Choice Button

		TutorialPrivacyButtonOn: { text: "Privacy On", type: "listenFAE", emit: "defaultAnim", sEmit: "post_to_lex:privacy mode", }, id:"btn1",
		TutorialPrivacyButtonOff: { text: "Privacy Off", type: "stopListenFAE", emit: "defaultAnim", sEmit: "post_to_lex:privacy mode", }, id:"btn2",

		YesButton: {			text: "Yes", 													type:"sidebarYes", 			emit:"defaultAnim", 	sEmit:"post_to_lex:yes", 		 				icon:"Yes"}, 			id:"btn1", //Yes Button
		NoButton: {				text: "No", 													type:"sidebarNo", 			emit:"defaultAnim", 	sEmit:"post_to_lex:no", 		 				icon:"No"}, 			id:"btn2", //No Button

		//listen: { text: "", type: "listen", emit: "listen" },

//DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-

	/**************************************************************************************
	 *																					  *
	 * 								    German Elements	- DE								  *
	 * 																					  *
	 *************************************************************************************/

	//************** GERMAN HOME SCENE/universal? *******************

		MedicationsButtonDE: {	text: "Medikamente", 									 		type: "sidebarButton1", 	emit: "defaultAnim", 	 sEmit:"post_to_lex:Medikamente", 			icon:"Medication"}, 	id:"Sbtn1", //Medications select
		VitalsButtonDE: {		text: "Vitalzeichen", 								 			type: "sidebarButton2", 	emit: "defaultAnim", 	 sEmit:"post_to_lex:Vitalzeichen", 			icon:"Vitals"}, 		id:"btn2",  //Vitals select
		DoneButtonDE: {			text: "Gemacht", 												type: "sidebarDone", 		emit: "defaultAnim",	 sEmit:"post_to_lex:Gemacht", 				icon:"Done"}, 			id:"sDone", //Manage Medications select
		BackButtonDE: {			text: "Zurück",													type: "sidebarDone", 		emit: "defaultAnim",	 sEmit:"post_to_lex:Zurück", 				icon:"Left"}, // Back button

		allBackDE: {			text: "Zurück",				to: "/",							type: "sidebarDone",								 sEmit: "post_to_lex:Zurück",				icon: "Left",		},

		
		tutorialButtonDE: { 	text: "Anleitung", 												type: "sidebarButton3", 	emit: "defaultAnim", 	 sEmit: "post_to_lex:Anleitung", 				icon: "Tutorial" },
		TutorialButtonDE: { 	text: "Anleitung", 												type: "sidebarButton3", 	emit: "defaultAnim", 	 sEmit: "post_to_lex:Anleitung", 				icon: "Tutorial" },

		lowCancelDE: { 			text: "Zurück", 												type: "sidebarDone", 		emit: "defaultAnim", 	 sEmit: "post_to_lex:Gemacht", 			icon: "Left" },
		LowCancelDE: { 			text: "Zurück", 												type: "sidebarDone", 		emit: "defaultAnim", 	 sEmit: "post_to_lex:Gemacht", 			icon: "Left" },

//		HomeDE: { 				text: "Startseite",  											type: "higher", 	emit: ["cancelTutorial", "lexPlayScene:Home", "defaultAnim"], sEmit: "post_to_lex:zu Hause",		icon: "AddisonHome" },



	//************** GERMAN MEDICATIONS SCENE *******************

		TakeMedsDE: {		text: "Medikamente Einnehmen", 									type:"sidebarButton1", 	emit:"defaultAnim", 	sEmit:"post_to_lex:Medikamente Einnehmen", 		icon:"Medication"},		id:"btn1", //Take Medication select
		PreviousMedsDE: {	text: "Vorherige Medikamente", 									type:"sidebarButton2", 	emit:"defaultAnim", 	sEmit:"post_to_lex:Vorherige Medikamente", 		icon:"Medication"}, 	id:"btn2", //Take Medication select
		UpcomingMedsDE: {	text: "Nächste Medikamente", 									type:"sidebarButton3", 	emit:"defaultAnim", 	sEmit:"post_to_lex:Nächste Medikamente", 		icon:"Medication"}, 	id:"btn3", //Take Medication select

		takeMedsDE: {		text: "Medikamente Einnehmen", 									type:"sidebarButton1", 	emit:"defaultAnim", 	sEmit:"post_to_lex:Medikamente Einnehmen", 		icon:"Medication"},		id:"btn1", //Take Medication select
		previousMedsDE: {	text: "Vorherige Medikamente", 									type:"sidebarButton2", 	emit:"defaultAnim", 	sEmit:"post_to_lex:Vorherige Medikamente", 		icon:"Medication"}, 	id:"btn2", //Take Medication select
		upcomingMedsDE: {	text: "Nächste Medikamente", 									type:"sidebarButton3", 	emit:"defaultAnim", 	sEmit:"post_to_lex:Nächste Medikamente", 		icon:"Medication"}, 	id:"btn3", //Take Medication select
		

		AlarmOffButtonDE: {	text: 'Alarm Aus ',	to: 'medicationCompliance/alarm-disabled', 		type: 'sidebarUnderTable2', emit: "defaultAnim", 	sEmit:"post_to_lex:Alarm aus",			icon: 'Yes',	},

		MedTakeYesDE: { 	text: "Ja", 		to: "medicationCompliance/take-medications",	type: "sidebarYes", 		emit: "defaultAnim", 	sEmit: "post_to_lex:Ja" },
		MedTakeNoDE: { 		text: "Nein", 		to: "medicationCompliance/alarm-disabled", 		type: "sidebarNo", 			emit: "defaultAnim", 	sEmit: "post_to_lex:Nein" },
		MedTakeNotYetDE: { 	text: "Noch nicht", to: "medicationCompliance/alarm-snoozed", 		type: "sidebarNotYet", 		emit: "defaultAnim", 	sEmit: "post_to_lex:Noch nicht" },

		MedTakeReadyDE: { 	text: "Bereit", type: "sidebarButton1", to: "medicationCompliance/take-medications/ready", sEmit:"post_to_lex:Bereit", emit: "defaultAnim" },
		MedTakeDoneDE: { 	text: "Medikamente", type: "sidebarButton1", to: "medicationCompliance", sEmit:"post_to_lex:Medikamente", emit: "defaultAnim" },
		MedPreviousDoneDE: { text: "Medikamente", type: "sidebarButton1", to: "medicationCompliance", sEmit:"post_to_lex:Medikamente", emit: "defaultAnim" },
		MedUpcomingDoneDE: { text: "Medikamente", type: "sidebarButton1", to: "medicationCompliance", sEmit:"post_to_lex:Medikamente", emit: "defaultAnim" },

		menuBackDE: {			text: "Zurück",					type: "sidebarDone",			sEmit: "post_to_lex:zurück",			icon: "Left",		  },



	//************** GERMAN VITALS SCENE *******************

		// takeVitalDE: {				text: "Messe Vital",		to: "/sceneVitals/takeVital",		type: "sidebarButton1",								sEmit: "post_to_lex:messe vital",			icon: "Vitals",				id: "btn1",		},
		// TakeVitalDE: {				text: "Messe Vital",		to: "/sceneVitals/takeVital",		type: "sidebarButton1",								sEmit: "post_to_lex:messe vital",			icon: "Vitals",				id: "btn1",		},


		// takeHistoryDE: {			text: "Verlauf Anzeigen",	to: "/sceneVitals",					type: "sidebarButton2",		sEmit: "post_to_lex:Verlauf anzeigen",										icon: "VitalsHistory",		id: "btn2",		},
		// TakeHistoryDE: {			text: "Verlauf Anzeigen",	to: "/sceneVitals",					type: "sidebarButton2",		sEmit: "post_to_lex:Verlauf anzeigen",										icon: "VitalsHistory",		id: "btn2",		},

		// backCancelDE: { 			text: "Beenden",												type: "sidebarDone", 		emit: "defaultAnim", 	sEmit: "post_to_lex:beenden",				icon: "No" },
		// BackCancelDE: { 			text: "Beenden", 												type: "sidebarDone", 		emit: "defaultAnim", 	sEmit: "post_to_lex:beenden",				icon: "No" },

		// menuBackDE: {				text: "Zurück",				to: "/sceneVitals",					type: "sidebarDone",		sEmit: "post_to_lex:vitale",			icon: "Left",		  },

		// VitalDone: {				text: "Gemacht",			to: "/sceneVitals/takeVital",		type: "sidebarDone",		sEmit: "post_to_lex:show menu",			icon: "Done",		},
		
		// allBackDE: {				text: "Zurück",				to: "/",							type: "sidebarDone",								sEmit: "post_to_lex:Gemacht",				icon: "Left",		},
//DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE
    //Vital Type
    bloodPressureDE: {      text: "Blutdruck",                to: "/sceneVitals/takeVital/bloodPressure",      type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Blutdruck",                  icon: "BloodPressure",    },
    pulseOximeterDE: {      text: "Pulsoximeter",             to: "/sceneVitals/takeVital/bloodOxygen",        type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Pulsoximeter",               icon: "PulseOximeter",    },
    glucoseDE: {            text: "Blutzuckermessgerät",      to: "/sceneVitals/takeVital/glucose",            type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Blutzuckermessgerät",        icon: "Glucometer",    },
    temperatureDE: {        text: "Körpertemperatur",         to: "/sceneVitals/takeVital/temperature",        type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Körpertemperatur",           icon: "Temperature",    },
    weightDE: {             text: "Körpergewicht",            to: "/sceneVitals/takeVital/weight",             type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Körpergewicht",              icon: "Weight",    },
    airflowDE: {            text: "Luftvolumen",              to: "/sceneVitals/takeVital/airflow",            type: "sidebarButtonNextAvailable",      sEmit: "post_to_lex:Luftvolumen",                icon: "Spirometer",    },
//DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE


	TakeVitalDE: {				text: "Messe Vital",		to: "/sceneVitals/takeVital",		type: "sidebarButton1",								sEmit: "post_to_lex:messe vital",			icon: "Vitals",				id: "btn1",		},
    TakeHistoryDE: {			text: "Verlauf Anzeigen",	to: "/sceneVitals",					type: "sidebarButton2",		sEmit: "post_to_lex:Verlauf anzeigen",										icon: "VitalsHistory",		id: "btn2",		},
    ShowHistoryDE: {      text: "Verlauf Anzeigen",      to: "/sceneVitals",      type: "sidebarUnderTable1",      sEmit: "post_to_lex:Verlauf anzeigen",      icon: "VitalsHistory",      id: "btn12",    },

    
    ShowTutorialDE: {      text: "Anleitung",      to: "/sceneVitals/showTutorial",      type: "sidebarButton1",      sEmit: "post_to_lex:anleitung",      icon: "Tutorial",      id: "btn1",    },    
    VitalReadyDE: {          text: "Bereit",      to: "/sceneVitals/vitalReady",      type: "sidebarButton2",      sEmit: "post_to_lex:Bereit",      icon: "ThumbsUp",      id: "btn2",      startBluetooth: true,    },
    interpretReadyDE: {      text: "Bereit",      to: "/sceneVitals/takeVital/result",      type: "sidebarButton2",      sEmit: "VitalReceived",      icon: "ThumbsUp",      id: "btn2",    },
    VitalBackDE: {           text: "Zurück",      to: "/sceneVitals/takeVital",      type: "sidebarDone",      sEmit: "post_to_lex:zurück",      icon: "Left",    },
    VitalDoneDE: {      text: "Vitalzeichen",      to: "/sceneVitals/takeVital",      type: "sidebarDone",      sEmit: "post_to_lex:Vitalzeichen",      icon: "Done",    },
    HistoryDoneDE: {      text: "Vitalzeichen",      to: "/sceneVitals/historyDone",      type: "sidebarDone",      sEmit: "post_to_lex:Vitalzeichen",      icon: "Done",    },
   
    MenuBackDE: {      text: "Zurück",      to: "/sceneVitals",      type: "sidebarDone",      sEmit: "post_to_lex:Vitalzeichen",      icon: "Left",    },
    ShowMenuDE: {      text: "Vitalzeichen",      to: "/sceneVitals",      type: "sidebarUnderTable2",      sEmit: "post_to_lex:Vitalzeichen",      icon: "Done",    },

    AllDoneDE: {      text: "Gemacht",      to: "/",      type: "sidebarDone",      sEmit: "post_to_lex:Gemacht",      icon: "Done",    },
    AllBackDE: {				text: "Zurück",				to: "/",							type: "sidebarDone",								sEmit: "post_to_lex:zurück",				icon: "Left",		},
    
    YesDE: {      text: "Ja",      /*to:"/",	*/ type: "sidebarYes",      sEmit: "post_to_lex:ja",      icon: "Yes",    },
    NoDE: {      text: "Nein",      /*to:"/",   */ type: "sidebarNo",      sEmit: "post_to_lex:nein",      icon: "No",    },

    YesVitalResultDE: {      text: "Ja",      to: "/sceneVitals/takeVital/result",      type: "sidebarYes",      sEmit: "post_to_lex:ja",      icon: "Yes",    },
    NoVitalResultDE: {      text: "Nein",      to: "/sceneVitals",      type: "sidebarNo",      sEmit: "post_to_lex:nein",      icon: "No",    },

    NextDE: {      text: "Weiter",      type: "sidebarNext",      sEmit: "post_to_lex:Weiter",      icon: "Right",    },
    PreviousDE: {      text: "Vorherig",      type: "sidebarPrevious",      sEmit: "post_to_lex:Vorherig",      icon: "Left",    },



	//************** German FAE Scene ************************

	TutorialReadyButtonDE: {		text: "Bereit", 												type:"sidebarButton1", 	emit:"defaultAnim", 	sEmit:"post_to_lex:bereit", 		 		icon:"ThumbsUp"}, 		id:"btn1", //Start FAE Ready

	NotificationBarFAEDE: {	 																		type:"notifications", 		 																				}, 			id:"btnNotification",
	AddisonButtonDE: {	 			text: "Addison", 												type:"addison", 		emit:"defaultAnim", 													icon:"Addison"}, 		id:"btnAddison",

	FavoriteColorButtonDE: {		text: "Lieblingsfarbe", 										type:"sidebarButton1", 	emit:"defaultAnim", 	sEmit:"post_to_lex:lieblingsfarbe", 		 	icon:"Power"}, 			id:"btn1", //Favorite Color Button
	
	RedButtonDE: {					text: "Rot", 													type:"sidebarButton7", 	emit:"defaultAnim", 	sEmit:"post_to_lex:rot", 		 				 			},			id:"btn1", //Color Choice Button
	OrangeButtonDE: {				text: "Orange", 												type:"sidebarButton8", 	emit:"defaultAnim", 	sEmit:"post_to_lex:orange", 		 				 		},			id:"btn2", //Color Choice Button
	GreenButtonDE: {				text: "Grün", 													type:"sidebarButton9", 	emit:"defaultAnim", 	sEmit:"post_to_lex:grün", 		 				 			},			id:"btn3", //Color Choice Button
	BlueButtonDE: {					text: "Blau", 													type:"sidebarButton10", emit:"defaultAnim", 	sEmit:"post_to_lex:blau", 		 				 			},			id:"btn4", //Color Choice Button
	PurpleButtonDE: {				text: "Violett", 												type:"sidebarButton11", emit:"defaultAnim", 	sEmit:"post_to_lex:violett", 		 				 		},			id:"btn5", //Color Choice Button
	PinkButtonDE: {					text: "Rosa", 													type:"sidebarButton12", emit:"defaultAnim", 	sEmit:"post_to_lex:rosa", 		 				 			},			id:"btn6", //Color Choice Button

	TutorialPrivacyButtonOnDE: { text: "Privater Modus", type: "listenFAEDE", emit: "defaultAnim", sEmit: "post_to_lex:privater modus", }, id:"btn1",
	TutorialPrivacyButtonOffDE: { text: "Privater Modus", type: "stopListenFAEDE", emit: "defaultAnim", sEmit: "post_to_lex:privater modus", }, id:"btn2",

	YesButtonDE: {			text: "Ja", 													type:"sidebarYes", 		emit:"defaultAnim", 	sEmit:"post_to_lex:ja", 		 				icon:"Yes"}, 			id:"btn1", //Yes Button
	NoButtonDE: {			text: "Nein", 													type:"sidebarNo", 		emit:"defaultAnim", 	sEmit:"post_to_lex:nein", 		 				icon:"No"}, 			id:"btn2", //No Button

	//listenDE: { text: "Privater Modus", type: "listenDE", emit: "listen" },



		  bloodPressure: {			text: "Blood Pressure",			to: "/sceneVitals/takeVital/bloodPressure",			type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:bloodpressure",		icon: "BloodPressure",		  },
		  pulseOximeter: {			text: "Pulse Oximeter",			to: "/sceneVitals/takeVital/bloodOxygen",			type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:pulseox",			icon: "PulseOximeter",		  },
		  glucose: {				text: "Glucometer",				to: "/sceneVitals/takeVital/glucose",				type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:glucose",			icon: "Glucometer",		  },
		  temperature: {			text: "Temperature",			to: "/sceneVitals/takeVital/temperature",			type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:thermometer",		icon: "Temperature",		  },
		  weight: {					text: "Weight Scale",			to: "/sceneVitals/takeVital/weight",				type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:weight",			icon: "Weight",		  },		  
		  airflow: {				text: "Spirometer",				to: "/sceneVitals/takeVital/airflow",				type: "sidebarButtonNextAvailable",			sEmit: "post_to_lex:airflow",			icon: "Spirometer",		  },
	  
		  //Vitals Navigation
		  showHistory: {			text: "Review History",			to: "/sceneVitals",			type: "sidebarUnderTable1",			sEmit: "post_to_lex:Review History",			icon: "VitalsHistory",			id: "btn12",		  },
	  
		  showTutorial: {			text: "Tutorial",			to: "/sceneVitals/showTutorial",			type: "sidebarButton1",			sEmit: "post_to_lex:tutorial",			icon: "Tutorial",			id: "btn1",		  },
		  vitalReady: {			text: "Ready",			to: "/sceneVitals/vitalReady",			type: "sidebarButton2",			sEmit: "post_to_lex:ready",			icon: "ThumbsUp",			id: "btn2",			startBluetooth: true,		  },
		  interpretReady: {			text: "Ready",			to: "/sceneVitals/takeVital/result",			type: "sidebarButton2",			sEmit: "VitalReceived",			icon: "ThumbsUp",			id: "btn2",		  },
		  vitalBack: {			text: "Back",			to: "/sceneVitals/takeVital",			type: "sidebarDone",			sEmit: "post_to_lex:done",			icon: "Left",		  },
		  vitalDone: {			text: "Done",			to: "/sceneVitals/takeVital",			type: "sidebarDone",			sEmit: "post_to_lex:show menu",			icon: "Done",		  },
		  historyDone: {			text: "Done",			to: "/sceneVitals/historyDone",			type: "sidebarDone",			sEmit: "post_to_lex:done",			icon: "Done",		  },
	  
  
		  //tutorialButton: {			text: "Tutorial",			type: "sidebarButton4",			sEmit: "lexPlayScene:PowerOn",			icon: "Tutorial",		  },
		  lowCancel: {			text: "Back",			to: "/sceneVitals",			type: "sidebarDone",			sEmit: "post_to_lex:show menu",			icon: "Left",			emit: "camAddison",		  },
	  
		  menuBack: {			text: "Back",			to: "/sceneVitals",			type: "sidebarDone",			sEmit: "post_to_lex:show vital menu",			icon: "Left",		  },
		  showMenu: {			text: "Vitals",			to: "/sceneVitals",			type: "sidebarUnderTable2",			sEmit: "post_to_lex:Vitals",			icon: "Done",		  },
	  
		  allDone: {			text: "Done",			to: "/",			type: "sidebarDone",			sEmit: "post_to_lex:done",			icon: "Done",		  },
	  
		 // yes: {			text: "Yes",			/*to:"/",	*/ type: "sidebarYes",			sEmit: "post_to_lex:yes",			icon: "Yes",		  },
		 // no: {			text: "No",			/*to:"/",   */ type: "sidebarNo",			sEmit: "post_to_lex:no",			icon: "No",		  },
	  
		  yesVitalResult: {			text: "Yes",			to: "/sceneVitals/takeVital/result",			type: "sidebarYes",			sEmit: "post_to_lex:yes",			icon: "Yes",		  },
		  noVitalResult: {			text: "No",			to: "/sceneVitals",			type: "sidebarNo",			sEmit: "post_to_lex:no",			icon: "No",		  },
	  
		//  next: {			text: "Next",			type: "sidebarNext",			sEmit: "post_to_lex:next",			icon: "Right",		  },
		// previous: {			text: "Previous",			type: "sidebarPrevious",			sEmit: "post_to_lex:previous",			icon: "Left",


//DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-DE-




	});

	/**************************************************************************************
	 *																					  *
	 * 								    Element Groups									  *
	 * 																					  *
	 **************************************************************************************

	 Element groups.
	 This allows us to create, say, one group for the top bar, and hide and show that as it's own thing. */

	store.getActions().addMyElementGroups({
		// exampleSidebar      		: {elements : ["sampleButton1", "sampleButton2"]},
		// yesNo						: {elements : ["yes", "no"]},
		// yesNoLow					: {elements : ["yesLow", "noLow"]},
		// nextPrevious				: {elements : ["next", "previous"]},		
		// //Power On Groups
		// tableMedGroup			    : {elements : ["yesLow", "noLow", "next","previous", "medTable"]},
		// physician			    	: {elements : ["yesLow", "noLow", "next","previous", "physTable"]},		

		
		HomeSidebar: { elements: ["MedicationsButton", "VitalsButton", "TutorialButton", "MedicationsButtonDE", "VitalsButtonDE", "TutorialButtonDE"] },
	//	HomeMedSidebar      		: {elements : ["TakeMedsButton", "ManageMedsButton","BackButton", "PreviousMedsButton", "UpcomingMedsButton"]},
		moreFeaturesSidebar: { elements: ["MotionAnalysisButton", "DoneButton"] },
		MedsGroup: { elements: ["TakeMedsButton", "UpcomingMedsButton", "PreviousMedsButton", "MedTakeDone", "MedTakeReady", "MedPreviousDone", "MedUpcomingDone"]},
		MedsGroupDE: { elements: ["TakeMedsDE", "UpcomingMedsDE", "PreviousMedsDE", "MedTakeDoneDE", "MedTakeReadyDE", "MedPreviousDoneDE", "MedUpcomingDoneDE", "menuBackDE" ] },


		assistGroup: { elements: ["emergencyButton", "pocketMDButton", "supportButton", "tutorialButton", "lowCancel", "alexaButton"] },
		assistPowerOn: { elements: ["emergencyButton", "pocketMDButton", "supportButton", "backCancel"] },

		AssistGroup: { elements: ["EmergencyButton", "PocketMDButton", "SupportButton", "TutorialButton", "LowCancel", "AlexaButton"] },
		AssistPowerOn: { elements: ["EmergencyButton", "PocketMDButton", "SupportButton", "BackCancel"] },

		TutorialGroup: { elements: ["TutorialReadyButton", "TutorialReadyButtonDE"]},
		AddisonGroup:  { elements: ["AddisonButton", "AddisonButtonDE"], stick: true},
		NotificationGroup: { elements: ["NotificationBarFAE", "NotificationBarFAEDE"], stick: true},
		FavoriteColorGroup:  { elements: ["FavoriteColorButton", "FavoriteColorButtonDE"]},
		ColorGroup: { elements: ["RedButton", "OrangeButton", "GreenButton", "BlueButton", "PurpleButton", "PinkButton", "RedButtonDE", "OrangeButtonDE", "GreenButtonDE", "BlueButtonDE", "PurpleButtonDE", "PinkButtonDE"]},
		PrivacyGroup: { elements: ["TutorialPrivacyButtonOn", "TutorialPrivacyButtonOff", "TutorialPrivacyButtonOnDE", "TutorialPrivacyButtonOffDE"], stick: true},
		YesNoTutorialGroup: { elements: ["YesButton", "NoButton", "YesButtonDE", "NoButtonDE"]},
		listenGroup: { elements: ["listen", "listenDE"]/*, stick: true*/},
		//HomeGroup: { elements: ["Home", "HomeDE"]},


		takeHistoryGroup: { elements: ["takeVital", "takeHistory", "allBack"] },
		takeHistoryGroupDE: { elements: ["TakeVitalDE", "TakeHistoryDE", "AllBackDE", "MenuBackDE"] },
		takeVitalMenu: { elements: ["showTutorial", "vitalReady", "vitalBack","takeVitalDE", "showTutorialDE", "vitalReadyDE", "vitalBackDE"] },
		takeVitalReady: { elements: ["vitalReady", "vitalReadyDE"] },
		interpretVitalReady: { elements: ["interpretReady", "interpretReadyDE"] },
		doneVital: { elements: ["takeVital", "vitalDone", "takeVitalDE", "vitalDoneDE"] },
		doneHistory: { elements: ["showHistory", "showMenu", "showHistoryDE", "showMenuDE"] }, // this is what will show up with the response:
		readyHistory: { elements: ["historyReady", "historyReadyDE"] },

		//medications
		AlarmOffGroup: { elements: ['AlarmOffButton']},
		MedYesNoNotYet: { elements: ['MedTakeYes', 'MedTakeNo', 'MedTakeNotYet']},
		MedYesNoNotYetDE: { elements: ['MedTakeYesDE', 'MedTakeNoDE', 'MedTakeNotYetDE']},
		AlarmOffGroupDE: { elements: ['AlarmOffButtonDE']},
		
		//Vitals
		vitalTypes: {
			elements: [   
			  "bloodPressure",
			  "pulseOximeter",
			  "glucose",
			  "temperature",
			  "weight",
			  "airflow",
			],
		  },

		  vitalTypesDE: {
			elements: [   
			  "bloodPressureDE",
			  "pulseOximeterDE",
			  "glucoseDE",
			  "temperatureDE",
			  "weightDE",
			  "airflowDE",
			],
		  },
		  takeHistoryDE: { elements: ["TakeVitalDE", "TakeHistoryDE", "AllBackDE"] },
		  takeVitalMenuDE: { elements: ["ShowTutorialDE", "VitalReadyDE", "VitalBackDE"] },
		  takeVitalReadyDE: { elements: ["VitalReadyDE"] },
		  interpretVitalReadyDE: { elements: ["interpretReadyDE"] },
		  doneVitalDE: { elements: ["TakeVitalDE", "VitalDoneDE"] },
		  doneHistoryDE: { elements: ["ShowHistoryDE", "ShowMenuDE"] }, // this is what will show up with the response:
		  readyHistoryDE: { elements: ["HistoryReadyDE"] },
		  yesNoDE: { elements: ["YesDE", "NoDE"] },
		  // so we need to add the showMenu element to the groups that already exist
		  yesNoVitalResultDE: { elements: ["YesVitalResultDE", "NoVitalResultDE"] },
		  nextPreviousDE: { elements: ["NextDE", "PreviousDE"], stick: false },
		  nextDE: { elements: ["NextDE"], stick: false },
		  

	});

	//Create the topbar at the beginning of the scene.
	switch(store.getState().primaryLanguage){
		case "en":
		case "en-US":
		case "en-GB":
		case "en-AU":
			store.getActions().showGroupFunc("topBar");
			store.getActions().showGroupFunc("addisonHome");
			break
		case "de":
		case "de-AT":
		case "de-DE":
			store.getActions().showGroupFunc("topBarDE");
			store.getActions().showGroupFunc("addisonHomeDE");
			break
		default:
			console.warn ("Invalid language supplied for topBar and Home button");
	}
	

	lexSetup();
}// end setup

function lexSetup() {
	let lexSettings = {
		botAlias: 'dev',
		botName: 'MCP_HomeBot',
		userId: 'username' + Date.now(),
		accept: 'text/plain; charset=utf-8',
		contentType: 'text/plain; charset=utf-8',
		sessionAttributes: { patientGroup: JSON.stringify(window.groupData) }
	}

	logger.debug('setup home scene chatbot');
	Hub.dispatch("setupLex", lexSettings);

	Hub.dispatch("StartSpeechRec");

	//todo: switch to using home event
	//[LOCALIZATION REQUIRED]]
	setTimeout(() => {
		let data = 'home';
		switch(store.getState().primaryLanguage) {
			case 'en-US':
			case 'en-AU':
			case 'en-GB':
				data = 'home';
				break;
			case 'de-AT':
			case 'de-GE':
			case 'de-DE':
				data = 'startseite';
				break;
		}

		postToLex(data).catch(e => logger.error(e));
	}, 4000);

}

// Called when play mode stops.
//
function cleanup() {
	store.getActions().hideAllNonStickyGroups(null);
	store.getActions().removeMyElements(store.getState().elements);
	store.getActions().removeMyElementGroups(store.getState().groups);
}

export const HomeSceneElements = {
	setup,
	cleanup,
};