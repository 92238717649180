function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from "react";

var _ref2 = React.createElement("title", null, "Icon");

var _ref3 = React.createElement("path", {
  d: "M91,78.21,55.06,16a5.84,5.84,0,0,0-10.12,0L9,78.21A5.84,5.84,0,0,0,14.05,87H86A5.84,5.84,0,0,0,91,78.21Zm-37.1-2A5.29,5.29,0,0,1,50,77.54a5.43,5.43,0,0,1-3.94-1.35,6,6,0,0,1,0-7.64,5.52,5.52,0,0,1,4-1.3,5.35,5.35,0,0,1,3.89,1.33,5.88,5.88,0,0,1,0,7.58ZM53.75,62H46.37L44.82,32.46H55.3Z",
  fill: "#c1272d"
});

var SvgIconEmergencyRed = function SvgIconEmergencyRed(_ref) {
  var svgRef = _ref.svgRef,
      title = _ref.title,
      props = _objectWithoutProperties(_ref, ["svgRef", "title"]);

  return React.createElement("svg", _extends({
    viewBox: "0 0 100 100",
    ref: svgRef
  }, props), title === undefined ? _ref2 : title ? React.createElement("title", null, title) : null, _ref3);
};

var ForwardRef = React.forwardRef(function (props, ref) {
  return React.createElement(SvgIconEmergencyRed, _extends({
    svgRef: ref
  }, props));
});
export default __webpack_public_path__ + "static/media/Icon_EmergencyRed.5b8d0515.svg";
export { ForwardRef as ReactComponent };