import React from 'react';
import MotionUtilityTable from '../../SceneMotionAnalysis/MotionUtilityTable/MotionUtilityTable';

const SceneMotionAnalysisElementHandler = (elementAttributes, isHiding, handleClick) => {
  switch (elementAttributes.type) {
    case 'vidWalkAssessment':
      return (
        <MotionUtilityTable
          tableName="Walking Assessment"
          footerBox="Playing Tutorial..."
          url="https://addison-project-anim-files.s3.amazonaws.com/Videos/MotionAnalysis/Motion+Analysis+Tutorial_v02.mp4"
        />
      );
    case 'vidBalance':
      return (
        <MotionUtilityTable
          tableName="Balance"
          footer="Playing Tutorial..."
          url="https://addison-project-anim-files.s3.amazonaws.com/Videos/MotionAnalysis/Balance_V04.mp4"
        />
      );
    case 'vidLegCurls':
      return (
        <MotionUtilityTable
          tableName="Leg Curls"
          footer="Playing Tutorial..."
          url="https://addison-project-anim-files.s3.amazonaws.com/Videos/MotionAnalysis/KneeCurl_V04.mp4"
        />
      );
    case 'vidLegExtensions':
      return (
        <MotionUtilityTable
          tableName="Leg Extensions"
          footer="Playing Tutorial..."
          url="https://addison-project-anim-files.s3.amazonaws.com/Videos/MotionAnalysis/LegExtension_V04.mp4"
        />
      );

    default:
      return undefined;
  }
};

export default SceneMotionAnalysisElementHandler;
