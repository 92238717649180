/**
 * Medication Table
 *
 * @author Devonte Gabriel
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Hub } from 'aws-amplify';


const PrevMedicationTable = ({ header, data }) => {
  const [currentRowsShown, setCurrentRowsShown] = useState();
  useEffect(() => {
    if (!data) return;
    console.log(data[0]);
    console.log(Object.keys(data[0]));
    // fill missing rows with placeholders
    while (data.length % 4 !== 0) {
      data = [...data, { // eslint-disable-line react-hooks/exhaustive-deps
        filler1: '-',
        filler2: '-',
        filler3: '-',
        filler4: '-',
      },
      ];
    }
    // delete object properties that are not needed for the component
    data.forEach((item, i) => {
      delete item.timestamp;
      delete item.Dosage;
      delete item.username;
      delete item.Medication;
      delete item.Amount;
      if (item.Taken) {
        item.Line = i + 1;
      }
    });

    setCurrentRowsShown(data.slice(0, 4));
  }, [data]);
  // create onclick to nav

  if (!currentRowsShown) return null;
  return (
    <>
      <div className="med-table">
        <h1 className="med-table__title">
          {header}
          {' '}
        </h1>
        <div className="med-table__table-container">
          <div className="med-table__table">
            <div className="med-table__row--Previous med-table__row--header">
              {Object.keys(data[0]).slice(0, 4).map(key => <h2>{key}</h2>).reverse()}
            </div>
            {currentRowsShown.map((med, index) => (
              <div onClick={() => { Hub.dispatch('post_to_lex', { data: med.Line })}} className={`med-table__row--Previous ${index % 2 === 0 && 'med-table__row--even'}`}>
                {Object.keys(med).slice(0, 4).map((key,index) => <h3 key={med[index]}>{med[key]}</h3>).reverse()}
              </div>
            )) }
          </div>
          <div className="med-table__footer">

            <div className="med-table__date-time">
              <div className="med-table__date-time-header">
                <h2>
                  Status:
                </h2>
              </div>
              <h1> Previous Reminders</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrevMedicationTable;
