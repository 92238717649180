import React, {useState, useEffect} from 'react';
import {Hub} from 'aws-amplify';

export const AppScreenFader = props => {
  const [on, setOn] = useState(true);
  
  const _fadeInSplash = (capsule) => {
    setOn(true);
  };
  
  const _fadeOutSplash = (capsule) => {
    setOn(false);
  };
  useEffect(() => {
    
      Hub.listen('fadeInSplash', _fadeInSplash);
      Hub.listen('fadeOutSplash', _fadeOutSplash);
    Hub.listen('hideSplashScreen',_fadeOutSplash);

    return () => {
      Hub.remove('fadeInSplash', _fadeInSplash);
      Hub.remove('fadeOutSplash', _fadeOutSplash);
      Hub.remove('hideSplashScreen', _fadeOutSplash);
    };
  }, []);

  return <div className={`${on ? 'fadeContainer' : 'hidden'}`}></div>;
};
