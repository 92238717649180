import React from 'react';
import autobind from 'class-autobind';
import * as BABYLON from 'babylonjs'; //hack for Host system (would prefer ES6)
import HOST from 'amazon-sumerian-hosts/dist/host.babylon.js'; //Sumerian host system
import AWS from 'aws-sdk';
import 'babylonjs-loaders';
import {Auth, Hub, Logger} from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore';
import {
  handleWindowResize,
  initDefaultLight,
  initPipeline,
  initCamera,
  initSceneListeners,
  initVideoTextures,
  setupScene,
  setupSceneCaching,
  initParticles,  
  initHdrSwap,
  initClothingSwap,
  initAudio,
  initCuckooClock,
  initOwlTimer,
  getSceneLoaded,
  pullUserData,
  getVoiceFromLanguageCode,
  initPolly,
  disableTTS,
  enableTTS,
  initSceneOptimizer
  // setupIdleTween,
} from '../js';
import {SceneHandler, ChatbotObject, RecognitionHandler} from '../components/AssetsScene';
// import autoCreds from '../CredentialsModule';
//import CameraViewer from './Camera/CameraViewer';

const refreshPolly = async () => {
  const [creds] = await Promise.all([Auth.currentCredentials()]);
  const { accessKeyId, secretAccessKey, sessionToken } = creds;
  AWS.config.credentials = new AWS.Credentials({
    accessKeyId,
    secretAccessKey,
    sessionToken
  })

  const languageCode = store.getState().primaryLanguage;

  if(!languageCode) return;

  const voice = getVoiceFromLanguageCode(languageCode);

  //re-enable with the new voice
  initPolly();
  disableTTS();
  enableTTS({
    payload: {
      data: voice
    }
  })

}

/**
 * renders the canvas context, and handles initial setup of scene assets
 */
export default class AppScene extends React.Component {
  constructor(props) {
    super(props);
    autobind(this);
    this.canvasRef = React.createRef();
    this.debuggerRef = React.createRef();
    this.logger = new Logger('AppScene');
  } // end constructor

  async componentDidMount() {
    // await autoCreds.deploy('AppScene');
    const [session, creds, userData] = await Promise.all([Auth.currentSession(), Auth.currentCredentials(), pullUserData()]);
    RecognitionHandler.setup();
    ChatbotObject.setup();
    // setupSceneCaching();

    
    const THIRTY_MINUTES_MS = 1800000;
    setInterval( () => {
      refreshPolly();
    }, THIRTY_MINUTES_MS);

    SceneHandler.selectConfig(store.getState().primaryLanguage);
    
    console.log("creds: ", creds);

    const { accessKeyId, secretAccessKey, sessionToken } = creds;

    // Grab ref to the canvas containing the WebGL context
    const canvas = this.canvasRef.current;
    store.getActions().setCanvas(canvas);

    //create the engine, scene, and camera, and configure
    const engine = new BABYLON.Engine(canvas, true, {audioEngine: true}, true);
    const scene = setupScene(engine);
    const [defaultCamera] = initCamera(scene);

    //instantiate the camera and position it
    defaultCamera.attachControl(this.canvasRef.current, true);


    // const creds = await Auth.currentSession();
    // const { accessKeyId, secretAccessKey, sessionToken } = creds;

    const polly = new AWS.Polly({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region: window.ADDISON_REGION
    });
    const presigner = new AWS.Polly.Presigner({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region: window.ADDISON_REGION
    });
  
    // const HOST = await import('amazon-sumerian-hosts/dist/host.babylon.js');
    await HOST.aws.TextToSpeechFeature.initializeService(polly, presigner, window.AWS.VERSION);   

    // initPolly();
    initPipeline(scene);
    initDefaultLight(scene);
    initParticles(scene);
    initHdrSwap(scene);
    
    //init polly service and load the scene
    const [host] = await Promise.all([
      SceneHandler.loadSceneByName('Addison'),
      SceneHandler.loadSceneByName('Home'),
      SceneHandler.loadSceneByName('Vitals'),
      SceneHandler.loadSceneByName('Medications'),
      SceneHandler.loadSceneByName('Exterior'),
      SceneHandler.loadSceneByName('ActivityDetection'),
      SceneHandler.loadSceneByName('LearningArea'),
      SceneHandler.loadSceneByName('Tablet'),
    ]);
    
    /////////////////////// For testing only.  Remove after config update ////////////////////////////////////////////

  //   // Hair 
  //   const hairMat = scene.getMaterialByName("Hair_MAT_v01");
  //   const reflectionTexture = new BABYLON.CubeTexture("https://cdn.ecg-health.com/assets/environ/skyboxes/hotel_room_1k.hdr", scene);
  //   reflectionTexture.rotationY = 2.9635691;
  //   hairMat.reflectionTexture = reflectionTexture;
  //   hairMat.reflectionTexture.level = 0.01;
  //   hairMat.anisotropy.isEnabled = true;
  //   hairMat.anisotropy.intensity = 0.6;
  //   hairMat.environmentIntensity = 0.6;
    
  //   // Clothing
  //   const clothesMat = scene.getMaterialByName("Clothes_MAT_v01");
  //   clothesMat.roughness = 0.8;
  //   clothesMat.albedoColor = new BABYLON.Color3.FromHexString("#C7C7C7")
  //   clothesMat.reflectionTexture = reflectionTexture;

  //   // Skin
  //   const skinMat = scene.getMaterialByName("Skin_MAT_v01");
  //   skinMat.reflectionTexture = reflectionTexture;
  //   skinMat.environmentIntensity = 0.55;
  //   skinMat.albedoColor = new BABYLON.Color3.FromHexString("#E9DDD6");
  //   skinMat.clearCoat.indexOfRefraction = 1.3;

  //   // Eyes
  //   const eyesMat = scene.getMaterialByName("Eye_Inner_MAT_v01");
  //   const eyesReflectionTexture = new BABYLON.CubeTexture("https://cdn.ecg-health.com/assets/environ/skyboxes/hotel_room_1k.hdr", scene);
  //   eyesReflectionTexture.rotationY = 1.8675;
  //   eyesMat.reflectionTexture = eyesReflectionTexture
  //   eyesMat.reflectionTexture.level = 0.8;
  //   eyesMat.environmentIntensity = 0.8
  //   eyesMat.albedoColor = new BABYLON.Color3.FromHexString("#9B9B9B")
  //   eyesMat.indexOfRefraction = 1.41
  //   eyesMat.roughness = 0.01;

  //   // LOCALIZATION
  //   const greenEyesDiff = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Addison_EyeInner_Green_Diff_v01_03.jpg");
  //   const blueEyesDiff = new BABYLON.Texture("https://cdn.ecg-health.com/assets/textures/Addison_EyeInner_Diff_v01_03.jpg"); 

  //   if (store.getState().primaryLanguage === 'en' || store.getState().primaryLanguage.includes('en-')){
  //     eyesMat.albedoTexture = blueEyesDiff;
  //     eyesMat.albedoTexture.vScale = -1;


  //   } else if (store.getState().primaryLanguage === 'de' || store.getState().primaryLanguage.includes('de-')){
  //   eyesMat.albedoTexture = greenEyesDiff;
  //   eyesMat.albedoTexture.vScale = -1;
  //   hairMat.albedoColor = new BABYLON.Color3.FromHexString("#29211D");
  //   hairMat.metallicReflectanceColor =  new BABYLON.Color3.FromHexString("#544940");
  // }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    
    // window.loadedScenes['Exterior'].containers.forEach(container => container.addAllToScene());
    // window.loadedScenes['Tablet'].containers.forEach(container => container.addAllToScene());
    
    //the current scene is the Home scene; make it so #1
    store.getActions().setCurrentSceneName('Home');
    store.getActions().setCurrentParticleNames(['fireSystem', 'fireSystem2']);
    
    /**** set listeners, the default rendering pipeline (FXAA, sample count, etc), and setup the default scene light and Skybox lighting */
    initSceneListeners(scene, host, engine);
    initVideoTextures(scene);
    initClothingSwap(scene);
    initAudio(scene);
    initCuckooClock(scene);
    initOwlTimer(scene);
    initSceneOptimizer(scene);
    
    handleWindowResize(engine);
    // setupIdleTween(defaultCamera);

    engine.runRenderLoop(scene.render.bind(scene));
    engine.resize();
  }

  render() {
    return (
      <>
        <canvas id="renderCanvas" ref={this.canvasRef} />
        <div id="mydiv" ref={this.debuggerRef} />
      </>
    );
  }
} // end AppScene
