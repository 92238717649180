import { Auth, Analytics, Hub, Logger } from 'aws-amplify'; 

import AWS from 'aws-sdk/global';
import awsmobile from './aws-exports.js';
import { chooseConfig} from './js/chooseConfig';

const logger = new Logger('AutoAuthCognitoIdentityCredentials');


const { aws_content_delivery_bucket } = awsmobile;

const chooseAndSetEnv = () => {
  if (aws_content_delivery_bucket.includes('-dev') && !aws_content_delivery_bucket.includes('-staging')) {
    window.ADDISON_ENV = 'dev';
    window.ADDISON_REGION = 'us-east-1';
  }
  if (aws_content_delivery_bucket.includes('-staging')) {
    window.ADDISON_ENV = 'staging';
    window.ADDISON_REGION = 'us-west-2';
  }
  if (aws_content_delivery_bucket.includes('-prod')) {
    window.ADDISON_ENV = 'prod';
    window.ADDISON_REGION = 'us-east-1';
  }

  if (aws_content_delivery_bucket.includes('-germandev')) {
    window.ADDISON_ENV = 'german';
    window.ADDISON_REGION = 'eu-west-1';
  }

  return window.ADDISON_ENV;
};

const patchedConfig = () => {
  const env = chooseAndSetEnv();

  logger.debug('chosen env: ', env);

  if (env === 'dev' || env === 'staging' || env === 'german') {
    return {
      aws_cognito_identity_pool_id: 'us-east-1:84ae5c5e-8c9c-4282-953e-ab78156a0fd2',
      aws_cognito_region: 'us-east-1',
      aws_user_pools_id: 'us-east-1_BiApgaLXJ',
      aws_user_pools_web_client_id: '3lrikrm8j33itbgfbu99icjnog',
      oauth: {},
      aws_appsync_graphqlEndpoint: 'https://ahligu7qhrggti3ynofjojovee.appsync-api.us-east-1.amazonaws.com/graphql',
      aws_appsync_region: 'us-east-1',
      aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
    };
  }
  
  return {}; // nothing to patch
};

const patch = patchedConfig();

const patched = {
  ...awsmobile,
  ...patch,
};
/*
const chooseConfig = () => {
  const { aws_content_delivery_bucket } = awsmobile;

  if (
    aws_content_delivery_bucket.includes('-dev')
    && !aws_content_delivery_bucket.includes('-staging')
  ) {
    return introConfig;
  }
  if (aws_content_delivery_bucket.includes('-staging')) {
    return introConfigStaging;
  }
  if (aws_content_delivery_bucket.includes('-prod')) {
    return introConfigProd;
  }
  return null;
};
*/
const config = {
  Auth: {
    mandatorySignIn: true,
  }
};
// const LoginsIndex = 'cognito-idp.us-east-1.amazonaws.com/' + awsmobile.aws_user_pools_id;

// fix to make region-generic 2-4-20 jkeys
const LoginsIndex = `cognito-idp.${patched.aws_cognito_region}.amazonaws.com/${patched.aws_user_pools_id}`;

/**
 * Auth listener
 *
 * Gives us the signal that we have just been signed in. Currently, this means
 * we must deploy our autoCreds before any components begin making service
 * requests.  Since our autoCreds Promise attempts to line up all deployment
 * requests behind an initial deployment, we would like that initial deployment
 * to fire as soon as possible, then be picked up as soon as possible in the
 * components requiring service credentials via the componentDidMount callbacks.
 */
// const authListener = async (data) => {
//   switch (data.payload.event) {
//     case 'signIn':
//       // if Analytics was disabled before signIn, it can be re-enabled here
//       // and the signIn event can be resent
//       await autoCreds
//         .deploy()
//         .then((conf) => {
//           logger.debug('AUTOCREDS DEPLOYED IN SIGNIN');
//           Analytics.enable();
//         })
//         .catch((err) => {
//           logger.debug('autoCreds deployment error in signin:', err);
//         });
//       break;
//     default:
//       logger.warn('got to default case in CredentialsModule.authListener');
//   }
// }; // authListener

export const amplifyConfig = { ...awsmobile, ...config, ...patched };

// Hub.listen('auth', authListener);

/**
 * Auto Authenticating Cognito Identity Credentials
 *
 * Extends AWS.CognitoIdentityCredentials, adding deploy method, and overwriting
 * the refresh method
 *
 * @param config - {IdentityPoolId, UserPoolId, Logins[], etc.}
 *
 */
class AutoAuthCognitoIdentityCredentials extends AWS.CognitoIdentityCredentials {
  constructor(config) {
    // When UNAUTH call to getCredentialsForIdentity is made, the region
    // must still be validated.  We set the region in _clientConfig here
    // (request.service<cognito-identity>.config.region),
    // to help assure we can get our credentials in the refresh() method.
    super(config, { region: awsmobile.aws_project_region });

    const self = this;

    // deploying flag, to give initial async deployments common credentials
    //
    this.deploying = 0;

    // distinct (and/or longer) expiry window
    //
    this.expiryWindow = 2700;

    setInterval(async () => {
      if (this.deploying > 0 && this.needsRefresh()) {
        this.refresh((err) => {
          if (err) logger.debug('refresh error:', err);
        });
      }
    }, 2400000);

    // refresh method overwrite
    //
    this.refresh = function (callback) {
      // Get and refresh the cognito user session using its refresh token
      // Use the resulting id token (jwt) to update these credentials
      //
      Auth.currentUserPoolUser()
        .then((cogUser) => {
          const session = cogUser.getSignInUserSession();

          cogUser.refreshSession(session.getRefreshToken(), (err, session) => {
            if (err) {
              logger.debug('constructor: ERROR REFRESHING COGNITO SESSION', err);
            }
            self.params.Logins[LoginsIndex] = session.getIdToken().getJwtToken();
            //
            // ie. super.refresh
            self.createClients();
            self.data = null;
            self._identityId = null;
            self.getId((err) => {
              if (!err) {
                if (!self.params.RoleArn) {
                  self.getCredentialsForIdentity(callback);
                } else {
                  self.getCredentialsFromSTS(callback);
                }
              } else {
                self.clearIdOnNotAuthorized(err);
                callback(err);
              }
            });
          });
        })
        .catch((err) => {
          logger.debug('user pool error:', err);
        });
    }; // this.refresh override
  } // constructor

  async refreshAWS() {
    logger.debug('refreshing this + AWS.config.credentials object');
    Auth.currentUserPoolUser()
      .then((cogUser) => {
        const session = cogUser.getSignInUserSession();
        logger.debug('Refreshing Cognito session & AWS credentials');

        // AWS.config.region = 'us-east-1';
        AWS.config.region = awsmobile.aws_project_region;
        AWS.config.credentials = this;

        cogUser.refreshSession(session.getRefreshToken(), (err, cogsession) => {
          if (err) {
            logger.error('error refreshing session:', err);
          } else {
            AWS.config.credentials.params.Logins[
              `cognito-idp.${amplifyConfig.aws_project_region}.amazonaws.com/${amplifyConfig.aws_user_pools_id}`
            ] = cogsession.getIdToken().getJwtToken();
            AWS.config.credentials.refresh((err) => {
              if (err) {
                logger.error('error refreshing credentials:', err);
              } else {
                logger.debug('CREDENTIALS TOKEN SUCCESSFULLY UPDATED');
              }
            });
          }
        });
      })
      .catch((e) => {
        // do something
        logger.error('user pool error:', e);
      });
  }

  /*
   * deployYield
   *
   * This function is used to yield to the current deployment
   *
   * @pre - this object has a currently running deployment
   */
  deployYield(resolve, reject, source) {
    const self = this;

    return new Promise((res, rej) => {
      setTimeout(() => {
        if (self === AWS.config.credentials) {
          logger.debug('FINISHED YIELDING!', source);
          res(amplifyConfig);
          return;
        }
        logger.debug('STILL YIELDING!', source);
        self.deployYield(resolve, reject, source);
      }, 10);
    }).then((result) => {
      resolve(result);
    });
  }

  /*
   * deploy
   *
   * Wait for this promise before calling on any Amplify/AWS services
   * to ensure that those services get continually refreshing credentials
   */
  deploy(source = '') {
    // check if we are being deployed currently
    //
    if (this.deploying) {
      return new Promise((y, n) => {
        // begin yielding
        //
        this.deployYield(y, n, source).then((res) => {
          y(res);
        });
      });
    }
    // we were not deploying. Deploying now
    //
    this.deploying++;
    const self = this;
    return new Promise((resolve, reject) => {
      // check if we've been deployed previously...
      //
      if (self === AWS.config.credentials) {
        logger.debug('Promise through equality (unexpected)');
        resolve(amplifyConfig);
        return;
      }
      logger.debug('deployment count', this.deploying);

      // get user from session
      Auth.currentUserPoolUser()
        .then((cogUser) => {
          // get user's signInUserSession
          //
          const session = cogUser.getSignInUserSession();

          // refresh user's signInUserSession
          cogUser.refreshSession(session.getRefreshToken(), (err1, session) => {
            if (err1) {
              reject(new Error('ERROR REFRESHING COGNITO SESSION: ', err1));
            } else {
              // set credentials params
              self.params.Logins[LoginsIndex] = session.getIdToken().getJwtToken();

              // refresh credentials using refreshed idToken from signInUserSession
              //
              if (!self.needsRefresh()) {
                AWS.config.credentials = self;
                // credentials set
                //
                resolve(amplifyConfig);
              } else {
                self.refresh((err2) => {
                  if (err2) {
                    reject(new Error(`ERROR REFRESHING COGNITO CREDENTIALS: ${err2}`));
                  } else {
                    AWS.config.credentials = self;
                    // credentials set
                    //
                    resolve(amplifyConfig);
                  }
                }); // self.refresh
              } // self.needsRefresh
            } // else
          }); // session.refresh
        })
        .catch((err) => {
          self.deploying--;
          // occurs (for one) when not already signed in on page load
          reject(new Error(`UNABLE TO DEPLOY AUTO COGNITO CREDENTIALS: ${err}`));
        })
        .finally(() => {
          // deployed, or failed
          //                    self.deploying --;
        });
    }); // Promise
  } // deploy
} // AutoAuthCognitoIdentityCredentials

export const autoCreds = new AutoAuthCognitoIdentityCredentials({
  // either IdentityPoolId or IdentityId is required
  // See the IdentityPoolId param for AWS.CognitoIdentity.getID (linked below)
  // See the IdentityId param for AWS.CognitoIdentity.getCredentialsForIdentity
  // or AWS.CognitoIdentity.getOpenIdToken (linked below)
  IdentityPoolId: amplifyConfig.aws_cognito_identity_pool_id,
  UserPoolId: amplifyConfig.aws_user_pools_id,
  Logins: {
    [LoginsIndex]: 'undefined',
  },
  // optional, only necessary when application runs in a browser
  // and multiple users are signed in at once, used for caching
  // LoginId: ''
});

export default autoCreds;
