/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Auth, API, graphqlOperation, Logger, Hub } from 'aws-amplify';
import { useStoreState } from 'easy-peasy';
import { Router, navigate } from '@reach/router';
import axios from 'axios';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import { postBluetoothResult } from '../../js';

import * as subscriptions from '../../graphql/subscriptions';
import { VitalResult, ShowHistory, PairButton } from './components';

import { formatVitalsData, replaceMissingThreshold } from './defaults';
import getVitalsData from './getVitalsData';

import store from '../../_GlobalStateStore/GlobalStateStore';

import { usePulseOx, useSphygmo } from '../../hooks';

const logger = new Logger('SceneVitals.js');

const { onCreateAneltoEvents } = subscriptions;

window._nextAvailable = 0;

export const getThresholdData = async (setThresholdData) => {
  const formattedThresholdData = {};
  const { payload } = (await Auth.currentSession()).idToken;
  const xmit = payload['custom:xmitId'];
  try {
    const thresholdData = await axios.post(
      'https://o6dy65bp92.execute-api.us-east-1.amazonaws.com/prod',
      {
        xmitId: xmit,
      },
    );

    // create and array of thresholds with metadata
    thresholdData.data.Items.forEach((data) => {
      if (!data.vitalName) return; // skip results that don't have a vital type

      formattedThresholdData[data.vitalName.S] = {
        createdAt: data.createdAt ? data.createdAt.S : null,
        orbId: data.orbId ? data.orbId.S : null,
        localOwner: data.localOwner ? data.localOwner.S : null,
        maximum: data.maximum ? data.maximum.N : null,
        minimum: data.minimum ? data.minimum.N : null,
        thresholdCarePlanId: data.thresholdCarePlanId ? data.thresholdCarePlanId.S : null,
        vitalName: data.vitalName ? data.vitalName.S : null,
        xmitId: data.xmitId ? data.xmitId.S : null,
      };
    });

    // template object to assure no attr key ref is undefined
    const _thresholdData = {
      Oxygen: {},
      BPM: {},
      Systolic: {},
      Diastolic: {},
      Thermometer: {},
      Weight: {},
      Glucose: {},
      ...formattedThresholdData, // existing attr keys+values
    };
    Object.keys(_thresholdData).forEach((keyName) => {
      const minMax = replaceMissingThreshold(
        keyName,
        _thresholdData[keyName]
      );
      _thresholdData[keyName].minimum = minMax.minimum;
      _thresholdData[keyName].maximum = minMax.maximum;
    });
    // logger.debug('formattedThresholdData: ', formattedThresholdData);
    setThresholdData(_thresholdData);
    return _thresholdData;
  } catch (err) {
    logger.debug(err);
    return err;
  }
};

/**
 * convert the raw entity type string from Dialogflow to the english equivalent string (all vitals functions assume the english word)
 * [[LOCALIZATION REQUIRED]]
 * @param {string} vitalType the raw string coming from Dialogflow, like 'Blutdruck' 
 * @param {string} languageCode 'en-US', 'de-AT' as an example
 */
function getEnglishVitalTypeByLanguageCode(vitalType, languageCode) {
  //if English language
  if(languageCode === 'en' || languageCode.includes('en-')) {
    return vitalType;
  } 

  switch(vitalType) {
    case 'Blutdruck':
      return 'blood pressure';
    case 'Pulsoximeter':
      return 'oxygen levels';
    default:
      logger.debug('fix this with the other german vital types');
    }
}

export const createInput = (value, timestamp) => ({
  timestamp: moment.utc(timestamp),
  value: parseFloat(value),
});

const SceneVitals = ({ location }) => {
  const [vitalsData, setVitalsData] = useState(undefined);
  const [thresholdData, setThresholdData] = useState(undefined);
  const [isListening, setIsListening] = useState(false);
  const [minThreshold, setMinThreshold] = useState(undefined);
  const [maxThreshold, setMaxThreshold] = useState(undefined);
  const [localCurrentVitalType, setLocalCurrentVitalType] = useState(undefined);

  // eslint-disable-next-line no-unused-vars
  const [aneltoEventSubscription, setAneltoEventSubscription] = useState(undefined);

  const getData = async () => {
    const newPatient = await getVitalsData();
    setVitalsData(newPatient);
  };

  //bluetooth hooks
  const [poAvg, poPerc, startPo] = usePulseOx();
  const [pulseRate, o2Sat, perf] = poAvg || [0, 0, 0];

  const [sphygAvg, sphygPerc, startSphyg] = useSphygmo();
  const [systolic, diastolic, pulseRateBp] = sphygAvg || [0, 0, 0];

  useEffect( () => {
    const _postFakeResult = (capsule) => {
      postResult(capsule.payload.data);
    };
    Hub.listen('postFakeResult', _postFakeResult);
    return () => {
      Hub.remove('postFakeResult', _postFakeResult);
    }
  }, []);

  // [[LOCALIZATION REQUIRED]]
  const postResult = async (type) => {
    logger.debug('posting result');
    const timestamp = (new Date(Date.now())).toISOString();
    const _id = uuidv4();
    const url = 'https://b1owje2qri.execute-api.us-east-1.amazonaws.com/prod/anelto-events';
    
    //get the account associated with this user
    const session = await Auth.currentSession();
    const { payload } = session.idToken;
    
    const owner = payload['cognito:username'];
    const account = payload['custom:xmitId'];
    const deviceNumber = `${payload['cognito:username']}-${type}`

    const headers = {
      'Content-Type': 'application/json',
      'x-api-key': 'szn6oAjfpo3bMrcSb956u2NgJQzrU3M74Jhwi8We'
    }

    const body = {
      account,
      owner,
      aneltoEventsUserId: owner,
      event: 'vitals',
      eventcode: '0060',
      timestamp,
      vital: {
        _id,
        deviceNumber,
      }
    };

    //and store it in the payload
    if (payload.hasOwnProperty('custom:xmitId')) {
        body.account = payload['custom:xmitId'];
    }

    logger.debug('body: ', body);
    logger.debug('type: ', type);

    // [[LOCALIZATION REQUIRED]]
    switch(type) {
      case 'oxygen levels':
        case 'Pulsoximeter':
        body.vital.pulseox = {
          pulseavg: o2Sat,
          pulsehigh: o2Sat,
          pulselow: o2Sat,
        }

        body.vital.heartrate = {
          bpm: pulseRate,
          bpmhigh: pulseRate,
          bpmlow: pulseRate
        }
        break;
      case 'blood pressure':
        case 'Blutdruck':
        body.vital.bloodpressure = {
          units: 0,
          systolic,
          diastolic
        }

        body.vital.heartrate = {
          bpm: pulseRateBp,
          bpmhigh: pulseRateBp,
          bpmlow: pulseRateBp
        }
        break;
      case 'glucose':
      case 'temperature':
      case 'spirometer':
        logger.debug('glucometer, thermometer, and spirometer not currently supported');

        break;  
      default:
        logger.debug('invalid vital type specified for bluetooth callback');
    }


    try {
      //TODO: uncomment when sure it's ready to go (this is a live API)
      // const res = await axios.post(url, body, { headers });

      const res = await postBluetoothResult(body);

      logger.debug('res: ', res);
    } catch(e) {
      logger.debug('error posting Bluetooth vital result: ', e);
    }

  };

  const waitToRoute = () => {
    setTimeout( async () => {
      //wait four seconds for ingestion process to finish, then pull the latest data again, and set the route
      await getData();
      navigate('/sceneVitals/takeVital/result');
      Hub.dispatch('postChatbotEvent', { data: 'VITALS_RESULT_EVENT'});
    }, 4000);
  } 
  
  useEffect( () => {
    const _eff = async () => {
      console.log('poAvg: ', poAvg);
      console.log('poPerc: ', poPerc);
      
      if(poPerc === 1) {
        store.getActions().setIsBluetoothReading(true);
        await postResult('oxygen levels');
        waitToRoute();
      }
    }

    _eff();
  }, [poPerc])

  useEffect( () => {
    const _eff = async () => {
      console.log('sphygAvg: ', sphygAvg);
      console.log('sphygPerc: ', sphygPerc);
      
      if(sphygPerc === 1) {
        store.getActions().setIsBluetoothReading(true);
        await postResult('blood pressure');
        waitToRoute();
      }
    }

    _eff();
  }, [sphygPerc])

  //[[LOCALIZATION REQUIRED]]
  useEffect( () => {
    const _cb = (capsule) => {
      const data = capsule.payload.data; 
      switch(data) {
        case 'oxygen levels':
        case 'Pulsoximeter':
          store.getActions().setCurrentBluetoothCallback(startPo);
          break;
        case 'blood pressure':
        case 'Blutdruck':
          store.getActions().setCurrentBluetoothCallback(startSphyg);
          break;
        case 'glucose':
        case 'temperature':
        case 'spirometer':
          logger.debug('glucometer, thermometer, and spirometer are currently unsupported for bluetooth ingestion');

          break;  
        default:
          logger.debug('invalid vital type specified for bluetooth callback');
      }
    }
    
    Hub.listen('selectVital', _cb);
    return () => {
      Hub.remove('selectVital', _cb);
    }
  }, []);


  // reset vitals data each time vitalsSelect intent is started - DC
  // useEffect(() => {
  //   if (location.pathname !== '/sceneVitals/takeVital/result') setVitalsData(undefined);
  // }, [location]);

  /**
   * Fetch vitals and threshold data effect
   */
  useEffect(() => {
    getData();
    getThresholdData(setThresholdData);

    return () => {
      setVitalsData(undefined);
      setThresholdData(undefined);
      setLocalCurrentVitalType(undefined);
      window._nextAvailable = 0;
      store.getActions().user.setNextAvailable(0);
    };
  }, []);

  /**
   * Sumerian Listeners effect
   */
  useEffect(() => {
    Hub.listen('tabletState', (capsule) => {
      // logger.debug('payload to tabletState: ', payload);
      if (capsule.payload.data === 'tLenable') {
        setIsListening(true);
      } else {
        setIsListening(false);
      }
    });

    // logger.debug('setting showHistory listener in SceneVitals.js');
    Hub.listen('selectVital', async (capsule) => {
      logger.debug("WE MADE IT", "selectVital", capsule);
      // payload should be a string like "glucose" here;
      // they map to the VitalTypes slot type in the Vitals Scene lex bot config
      if (!capsule.payload.data) {
        logger.warn('showHistory event passed with null or undefined payload');
        return;
      }
      if (typeof capsule.payload.data !== 'string') {
        logger.warn('showHistory event passed with payload not of type string');
        return;
      }

      const _vitalType = getEnglishVitalTypeByLanguageCode(capsule.payload.data, store.getState().primaryLanguage);
      setLocalCurrentVitalType(_vitalType);
    });

    const _showHistory = async (capsule) => {
      // logger.debug('in showHistory listener, payload: ', payload);

      // payload should be a string like "glucose" here;
      // they map to the VitalTypes slot type in the Vitals Scene lex bot config
      if (!capsule.payload.data) {
        logger.warn('showHistory event passed with null or undefined payload');
        return;
      }
      if (typeof capsule.payload.data !== 'string') {
        logger.warn('showHistory event passed with payload not of type string');
        return;
      }

      const _vitalType = getEnglishVitalTypeByLanguageCode(capsule.payload.data, store.getState().primaryLanguage);
      setLocalCurrentVitalType(_vitalType);
    }

    Hub.listen('showHistory', _showHistory);

    // logger.debug('setting showVital listener in VitalResult.js');

    const _showVital = async (capsule) => {
      // logger.debug('in showVital listener, payload: ', payload);
      logger.debug("WE MADE IT", "showVital", capsule);

      // payload should be a string like "glucose" here;
      // they map to the VitalTypes slot type in the Vitals Scene lex bot config
      if (!capsule.payload.data) {
        logger.warn('showVital event passed with null or undefined payload');
        throw new Error('payload is null or undefined');
      }
      if (typeof capsule.payload.data !== 'string') {
        logger.warn('showVital event passed with payload not of type string');
        throw new Error('payload not of type string');
      }

      setLocalCurrentVitalType(capsule.payload.data);
      navigate('/sceneVitals/takeVital/result');
    };

    Hub.listen('showVital', _showVital);

    // cleanup function
    const cleanup = () => {
      logger.debug('SceneVitals unmounting, removing all listeners on showHistory channel...');
      Hub.remove('showHistory', _showHistory);
      Hub.remove('showVital', _showVital);
      setVitalsData(undefined);
      setLocalCurrentVitalType(undefined);
    };

    return cleanup;
  }, []);



  useEffect(() => {
    if (!localCurrentVitalType) return;
    logger.debug("WE MADE IT useEffect");

    let _minThreshold, _maxThreshold;


    setMinThreshold(_minThreshold);
    setMaxThreshold(_maxThreshold);
  }, [localCurrentVitalType]);

  useEffect(() => {
    if (!vitalsData) return;

    const setSubscription = async () => {
      const payload = (await Auth.currentSession()).idToken.payload;
      const xmit = payload['custom:xmitId'];
      // const owner = payload['cognito:username'];

      const subscribe = API.graphql(
        graphqlOperation(onCreateAneltoEvents, { Account: xmit }),
      ).subscribe({
        next: (data) => {
          const liveData = formatVitalsData(data);
          logger.debug('LIVE DATA: ', liveData);

          const item = data.value.data.onCreateAneltoEvents;

          switch (liveData.type) {
            case 'blood pressure':
            case 'bloodpressure':
              vitalsData.systolic.push(createInput(item.Systolic, item.TimeStamp));
              vitalsData.diastolic.push(createInput(item.Diastolic, item.TimeStamp));
              break;
            case 'oxygen levels':
            case 'pulseox':
              vitalsData.heartRate.push(createInput(item.BPM, item.TimeStamp));
              vitalsData.oxygen.push(createInput(item.PulseAvg, item.TimeStamp));
              break;
            case 'glucose':
              vitalsData.glucose.push(createInput(item.Value, item.TimeStamp));
              break;
            case 'thermometer':
            case 'temperature':
              vitalsData.temperature.push(createInput(item.Value, item.TimeStamp));
              break;
            case 'weight':
              vitalsData.weight.push(createInput(item.Value, item.TimeStamp));
              break;

            default:
              logger.debug('default case in forEach loop, item: ', item);
          }

          setVitalsData(vitalsData);
          navigate('/sceneVitals/takeVital/result')
        },
      });

      setAneltoEventSubscription(subscribe);
      return subscribe;
    };

    setSubscription();
  }, [vitalsData]);

  // cleanup effect to clear subscription
  useEffect(() => {
    if (!aneltoEventSubscription) return;

    return () => aneltoEventSubscription.unsubscribe();
  }, [aneltoEventSubscription]);

  // render
  return (
    <Router>
      <VitalResult
        path="takeVital/result"
        type={localCurrentVitalType}
        data={vitalsData}
        thresholdData={thresholdData}
        minThreshold={minThreshold}
        maxThreshold={maxThreshold}
        isListening={isListening}
        setLocalCurrentVitalType={setLocalCurrentVitalType}
      />
      <ShowHistory
        type={localCurrentVitalType}
        data={vitalsData}
        thresholdData={thresholdData}
        minThreshold={minThreshold}
        maxThreshold={maxThreshold}
        isListening={isListening}
        setLocalCurrentVitalType={setLocalCurrentVitalType}
        path="history/*"
      />
    </Router>
  );
};

SceneVitals.propTypes = { };

export default SceneVitals;
