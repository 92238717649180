/* eslint-disable react/prop-types */

import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { Hub } from 'aws-amplify';
import './MotionUtilityTable.scss';
import store from '../../../_GlobalStateStore/GlobalStateStore';

const activeListening = () => {
  store.getActions().eventManager.emit({ channel: 'StopPrivacyMode' });
  setTimeout(() => {
    store.getActions().eventManager.emit({ channel: 'activeListen' });
  }, 200);
};
const privacyMode = () => {
  store.getActions().eventManager.emit({ channel: 'StartPrivacyMode' });
};
/**
 * Video Table
 *
 * table used for displaying videos and/or jsx components such as <img/> component
 * @author Isaiah Fielder
 * @param {String} tableName header for name of the table
 * @param {String} footerBox value to be displayed on the footer
 * @param {String} url video endpoint to play if passed
 * @param {String} leftDisplayBox jsx to display in the left box only displays if receives value
 * @param {String} rightDisplayBox jsx to display in the right box only displays if receives value
 * @param {String} leftBoxColor background color of left box
 * @param {String} rightBoxColor background color of right box
 */
const MotionUtilityTable = ({
  tableName = '',
  footerBox = '',
  url = '',
  leftDisplayBox = null,
  rightDisplayBox = null,
  leftBoxColor,
  rightBoxColor,
}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(true);

  /* We only want to apply the following
  side effects if the hub is present
  else fire off an empty effect  */
  useEffect(() => {
    if (url) {
      Hub.listen('pauseVideo', () => {
        setIsVideoPlaying(false);
      });
    }
  });

  useEffect(() => {
    if (url) {
      Hub.listen('playVideo', () => {
        setIsVideoPlaying(true);
      });
    }
  });
  return (
    <div className="containers__left-center">
      <div className="generic-table">
        <h1 className="generic-table__title">{tableName}</h1>
        {url ? (
          <div className="boxes_center" style={{ backgroundColor: 'white', marginBottom: '2%' }}>
            <ReactPlayer
              url={url}
              controls
              height="93%"
              width="97%"
              playing={isVideoPlaying}
              onPause={() => {
                setIsVideoPlaying(false);
                activeListening();
              }}
              onPlay={() => {
                setIsVideoPlaying(true);
                privacyMode();
              }}
            />
          </div>
        ) : (
          <div className="boxes_center">
            <div
              className="boxes_center-1"
              style={
                leftBoxColor && {
                  backgroundColor: `${leftBoxColor}`,
                }
              }
            >
              {leftDisplayBox}
            </div>
            <div
              className="boxes_center-2"
              style={
                rightBoxColor && {
                  backgroundColor: `${rightBoxColor}`,
                }
              }
            >
              {rightDisplayBox}
            </div>
          </div>
        )}
        <div className="generic-table__footer">
          <div className="generic-table__addison" />
          <div className="generic-table__tutorial_status">
            {/* By default the footer text says paused if video is not playing */}
            <h1>{isVideoPlaying ? footerBox : 'Paused'}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MotionUtilityTable;
