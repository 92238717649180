import PropTypes from 'prop-types';
import React from 'react';
// import { homeSceneConfig } from '../../config';

const Home = () => {
  return (
    <div data-testid="home-testid">
    </div>
  );
};
Home.propTypes = {
  location: PropTypes.object,
};

export default Home;
