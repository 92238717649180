// ShowHistory.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { nullableObjectPropValidator, nullableStringPropValidator } from '../../../js/localUtils';
import TwoResultChart from './TwoResultChart';

const ShowHistory = ({ type, data, thresholdData }) => {
  // lol
  return (
    <TwoResultChart
      type={type}
      data={data}
      thresholdData={thresholdData}
    />
  );
};

ShowHistory.propTypes = {
  type: nullableStringPropValidator,
  data: nullableObjectPropValidator,
  isListening: PropTypes.bool.isRequired,
};

export default ShowHistory;
