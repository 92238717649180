import store from '../../_GlobalStateStore/GlobalStateStore';

export function setActiveCamera(capsule) {    
    const { payload } = capsule;
    const name = payload.data;

    const node = window._scene.getNodeByName(name);

    if (!node) {
      return;
    }

    store.getActions().setCamera(node);

    window._scene.activeCamera = node;
    window._scene.activeCamera.attachControl(store.getState().canvas);
}