export function setHostPosition(capsule) {
    const { payload } = capsule;
    
    const { x, y, z } = payload.data;
    
    const node = window._scene.getNodeByID('Addison');
    
    node.position.x = typeof x === 'number' ? x : node.position.x;
    node.position.y = typeof y === 'number' ? y : node.position.y;
    node.position.z = typeof x === 'number' ? z : node.position.z;    
}