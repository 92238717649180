/*

   .d8888b. 8888888 8888888b.  8888888888 888888b.         d8888 8888888b.  
  d88P  Y88b  888   888  "Y88b 888        888  "88b       d88888 888   Y88b 
  Y88b.       888   888    888 888        888  .88P      d88P888 888    888 
   "Y888b.    888   888    888 8888888    8888888K.     d88P 888 888   d88P 
      "Y88b.  888   888    888 888        888  "Y88b   d88P  888 8888888P"  
        "888  888   888    888 888        888    888  d88P   888 888 T88b   
  Y88b  d88P  888   888  .d88P 888        888   d88P d8888888888 888  T88b  
   "Y8888P" 8888888 8888888P"  8888888888 8888888P" d88P     888 888   T88b

*/

// The font for these things  --  http://patorjk.com/software/taag/#p=display&f=Colossal  --
// The smaller banners are    -- http://patorjk.com/software/taag/#p=display&f=Banner3&t=Type%20Something%20 --
// Other small banner         -- http://patorjk.com/software/taag/#p=display&f=Ivrit&t=NOTIFICATION

import React from 'react';
import Icon from '../Icon.js';
import SFX from '../SFX.js';
import { Logger } from 'aws-amplify';

const SidebarElementHandler = (elementAttributes, isHiding, handleClick) => {
  const logger = new Logger('SidebarElementHandler');
  //General Icon Placement
  let curIcon = Icon(elementAttributes.icon);

  if (curIcon !== undefined) curIcon = (<div style={{ height: 'auto', width: '16%', lineHeight: '10%', margin: '0.8vw', minWidth: "16%" }}>{curIcon}</div>);
  else curIcon = (<div style={{ margin: '0.0vw' }}></div>);

  let elementText = (<div style={{ margin: 'auto' }}>{elementAttributes.text}</div>);

  if (!isHiding) {
    //Creation sound effect.
    if (elementAttributes.enterFX != null) {
      logger.debug(elementAttributes.enterFX);
      SFX(elementAttributes.enterFX);
    }
    // else { //Default sfx case
    //   SFX("slidein");
    // }
  }

  switch (elementAttributes.type) {

    /*

    888b     d888 8888888 888b    888 8888888       .d8888b. 8888888 8888888b.  8888888888 888888b.         d8888 8888888b.  
    8888b   d8888   888   8888b   888   888        d88P  Y88b  888   888  "Y88b 888        888  "88b       d88888 888   Y88b 
    88888b.d88888   888   88888b  888   888        Y88b.       888   888    888 888        888  .88P      d88P888 888    888 
    888Y88888P888   888   888Y88b 888   888         "Y888b.    888   888    888 8888888    8888888K.     d88P 888 888   d88P 
    888 Y888P 888   888   888 Y88b888   888            "Y88b.  888   888    888 888        888  "Y88b   d88P  888 8888888P"  
    888  Y8P  888   888   888  Y88888   888              "888  888   888    888 888        888    888  d88P   888 888 T88b   
    888   "   888   888   888   Y8888   888        Y88b  d88P  888   888  .d88P 888        888   d88P d8888888888 888  T88b  
    888       888 8888888 888    Y888 8888888       "Y8888P" 8888888 8888888P"  8888888888 8888888P" d88P     888 888   T88b 
                                                                                                                         
    */


    /**
     * MINI-SIDEBAR
     * sidebar button #1, top
     */
    case "miniSidebarButton1":
      return (
        <button
          className={
            /* CLASSES */
            `elmSmallText elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '14vh',
            width: '20vw',
            height: '5vw',
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {elementAttributes.text}
        </button>
      );

    /**
     * MINI-SIDEBAR
     * sidebar button #2, middle
     */
    case "miniSidebarButton2":
      return (
        <button
          className={
            /* CLASSES */
            `elmSmallText elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '22vh',
            width: '20vw',
            height: '5vw',
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {elementAttributes.text}
        </button>
      );

    /**
     * MINI-SIDEBAR
     * sidebar button #3, bottom
     */
    case "miniSidebarButton3":
      return (
        <button
          className={
            /* CLASSES */
            `elmSmallText elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '30vh',
            width: '20vw',
            height: '5vw',
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {elementAttributes.text}
        </button>
      );

    /**
     * MINI-SIDEBAR
     * sidebar button #4
     */
    case "miniSidebarButton4":
      return (
        <button
          className={
            /* CLASSES */
            `elmSmallText elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '38vh',
            width: '20vw',
            height: '5vw',
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {elementAttributes.text}
        </button>
      );

    /**
     * MINI-SIDEBAR
     * sidebar button #5
     */
    case "miniSidebarButton5":
      return (
        <button
          className={
            /* CLASSES */
            `elmSmallText elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '46vh',
            width: '20vw',
            height: '5vw',
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {elementAttributes.text}
        </button>
      );

    /**
     * MINI-SIDEBAR
     * sidebar button #6
     */
    case "miniSidebarButton6":
      return (
        <button
          className={
            /* CLASSES */
            `elmSmallText elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '54vh',
            width: '20vw',
            height: '5vw',
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {elementAttributes.text}
        </button>
      );

    /*

      8888888b.  8888888888 .d8888b.        .d8888b. 8888888 8888888b.  8888888888 888888b.         d8888 8888888b.  
      888   Y88b 888       d88P  Y88b      d88P  Y88b  888   888  "Y88b 888        888  "88b       d88888 888   Y88b 
      888    888 888       888    888      Y88b.       888   888    888 888        888  .88P      d88P888 888    888 
      888   d88P 8888888   888              "Y888b.    888   888    888 8888888    8888888K.     d88P 888 888   d88P 
      8888888P"  888       888  88888          "Y88b.  888   888    888 888        888  "Y88b   d88P  888 8888888P"  
      888 T88b   888       888    888            "888  888   888    888 888        888    888  d88P   888 888 T88b   
      888  T88b  888       Y88b  d88P      Y88b  d88P  888   888  .d88P 888        888   d88P d8888888888 888  T88b  
      888   T88b 8888888888 "Y8888P88       "Y8888P" 8888888 8888888P"  8888888888 8888888P" d88P     888 888   T88b 
                                                                                                               
    */

    /**
     * SIDEBAR
     * sidebar button #1, top
     */
    case "sidebarButton1":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '14vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );
    case "sidebarButton1Red":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRed elmAnimPopIn
              ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '14vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );
    /**
     * SIDEBAR
     * sidebar button #2, middle
     */
    case "sidebarButton2":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '32vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /**
     * SIDEBAR
     * sidebar button #3, bottom
     */
    case "sidebarButton3":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '50vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /**
     * SIDEBAR
     * sidebar button #4, bottom
     */
    case "sidebarButton4":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '30vw',
            top: '14vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /**
     * SIDEBAR
     * sidebar button #5, middle
     */
    case "sidebarButton5":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '30vw',
            top: '32vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /**
     * SIDEBAR
     * sidebar button #6, bottom
     */
    case "sidebarButton6":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '30vw',
            top: '50vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

/**
     * SIDEBAR
     * sidebar button for FAVORITE COLOR ONLY #7, top
     */
    case "sidebarButton7":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnColorRed elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '14vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

      case "sidebarButton8":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnColorOrange elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '32vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

      case "sidebarButton9":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnColorGreen elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '50vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

      case "sidebarButton10":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '30vw',
            top: '14vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

      case "sidebarButton11":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnColorPurple elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '30vw',
            top: '32vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

      case "sidebarButton12":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnColorPink elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '30vw',
            top: '50vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );
      

      
   /**
     * SIDEBAR
     * sidebar button alexa, bottom
     */
    case "alexaButton":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnAmazonBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '30vw',
            top: '32vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /**
     * SIDEBAR
     * sidebar under table #1, bottom left
     */
    case "sidebarUnderTable1":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '25vw',
            top: '65vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );
    /**
     * SIDEBAR
     * sidebar under table #2, bottom right
     */
    case "sidebarUnderTable2":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnPurple elmAnimPopIn elmBtnRounded
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '66vh',
            width: '20vw',
            height: '7vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    // Start Assessment Button for Motion Analysis Scene
    case "sidebarButton3Green":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnGreen elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '50vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );


    /*

  Y88b   d88P 8888888888 .d8888b.        d88P 888b    888  .d88888b.  
   Y88b d88P  888       d88P  Y88b      d88P  8888b   888 d88P" "Y88b 
    Y88o88P   888       Y88b.          d88P   88888b  888 888     888 
     Y888P    8888888    "Y888b.      d88P    888Y88b 888 888     888 
      888     888           "Y88b.   d88P     888 Y88b888 888     888 
      888     888             "888  d88P      888  Y88888 888     888 
      888     888       Y88b  d88P d88P       888   Y8888 Y88b. .d88P 
      888     8888888888 "Y8888P" d88P        888    Y888  "Y88888P"  
                                                                  

    */

    /**
     * SIDEBAR
     * yes
     */
    case "sidebarYes":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnGreen elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '14vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /**
     * SIDEBAR
     * no
     */
    case "sidebarNo":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnRed elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '32vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    case "sidebarNotYet":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnYellow elmAnimPopIn
              ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '50vh',              // <<<<<<<<<<<<<<<<<<< loW
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );
    /**
     * SIDEBAR
     * yes low
     */


    case "sidebarYesLow":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnGreen elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '50vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /**
     * SIDEBAR
     * no low
     */
    case "sidebarNoLow":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnRed elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '68vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );
    /*

    8888888b.   .d88888b.  888b    888 8888888888 
    888  "Y88b d88P" "Y88b 8888b   888 888        
    888    888 888     888 88888b  888 888        
    888    888 888     888 888Y88b 888 8888888    
    888    888 888     888 888 Y88b888 888        
    888    888 888     888 888  Y88888 888        
    888  .d88P Y88b. .d88P 888   Y8888 888        
    8888888P"   "Y88888P"  888    Y888 8888888888

    */


    /**
     * SIDEBAR
     * done
     */
    case "sidebarDone":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnPurple elmAnimPopIn elmBtnRounded
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '5000000000',
            right: '4vw',
            top: '66vh',
            width: '20vw',
            height: '7vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );
    /*

888888b.                     888      
888  "88b                    888      
888  .88P                    888      
8888888K.   8888b.   .d8888b 888  888 
888  "Y88b     "88b d88P"    888 .88P 
888    888 .d888888 888      888888K  
888   d88P 888  888 Y88b.    888 "88b 
8888888P"  "Y888888  "Y8888P 888  888 

    */


    /**
     * SIDEBAR
     * done
     */
    case "sidebarBack":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnPurple elmAnimPopIn elmBtnRounded
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '66vh',
            width: '20vw',
            height: '7vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );
    /*

        888b    888 8888888888 Y88b   d88P 88888888888     d88P 8888888b.  8888888b.  8888888888 888     888 
        8888b   888 888         Y88b d88P      888        d88P  888   Y88b 888   Y88b 888        888     888 
        88888b  888 888          Y88o88P       888       d88P   888    888 888    888 888        888     888 
        888Y88b 888 8888888       Y888P        888      d88P    888   d88P 888   d88P 8888888    Y88b   d88P 
        888 Y88b888 888           d888b        888     d88P     8888888P"  8888888P"  888         Y88b d88P  
        888  Y88888 888          d88888b       888    d88P      888        888 T88b   888          Y88o88P   
        888   Y8888 888         d88P Y88b      888   d88P       888        888  T88b  888           Y888P    
        888    Y888 8888888888 d88P   Y88b     888  d88P        888        888   T88b 8888888888     Y8P     
                                                                                                      
    */
    case "sidebarNext":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '14vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    case "sidebarPrevious":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
              ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '32vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /* 
    88888888888       888               888b     d888               888          
        888           888               8888b   d8888               888          
        888           888               88888b.d88888               888          
        888   8888b.  888  888  .d88b.  888Y88888P888  .d88b.   .d88888 .d8888b  
        888      "88b 888 .88P d8P  Y8b 888 Y888P 888 d8P  Y8b d88" 888 88K      
        888  .d888888 888888K  88888888 888  Y8P  888 88888888 888  888 "Y8888b. 
        888  888  888 888 "88b Y8b.     888   "   888 Y8b.     Y88b 888      X88 
        888  "Y888888 888  888  "Y8888  888       888  "Y8888   "Y88888  88888P'                                                                 
    
    // The font for these things  --  http://patorjk.com/software/taag/#p=display&f=Colossal  --
    // The smaller banners are    -- http://patorjk.com/software/taag/#p=display&f=Banner3&t=Type%20Something%20 --

     */
    case "sidebarTakeMeds":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
              ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '50vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );  //end of TakeMeds

    /* 
       .d8888b.        d8888 888b    888  .d8888b.  8888888888 888      
      d88P  Y88b      d88888 8888b   888 d88P  Y88b 888        888      
      888    888     d88P888 88888b  888 888    888 888        888      
      888           d88P 888 888Y88b 888 888        8888888    888      
      888          d88P  888 888 Y88b888 888        888        888      
      888    888  d88P   888 888  Y88888 888    888 888        888      
      Y88b  d88P d8888888888 888   Y8888 Y88b  d88P 888        888      
      "Y8888P" d88P     888 888    Y888  "Y8888P"  8888888888 88888888                                                                
      
      // The font for these things  --  http://patorjk.com/software/taag/#p=display&f=Colossal  --
      // The smaller banners are    -- http://patorjk.com/software/taag/#p=display&f=Banner3&t=Type%20Something%20 --

       */
    case "sidebarCancel":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
              ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '14vh',        // <<<<<<<<<<<<<<<<< High
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );  //end of TakeMeds

    /*
    888       888        d8888 888      888    d8P              d8888  .d8888b.   .d8888b.      
    888   o   888       d88888 888      888   d8P              d88888 d88P  Y88b d88P  Y88b     
    888  d8b  888      d88P888 888      888  d8P              d88P888 Y88b.      Y88b.          
    888 d888b 888     d88P 888 888      888d88K              d88P 888  "Y888b.    "Y888b.       
    888d88888b888    d88P  888 888      8888888b            d88P  888     "Y88b.     "Y88b.     
    88888P Y88888   d88P   888 888      888  Y88b          d88P   888       "888       "888     
    8888P   Y8888  d8888888888 888      888   Y88b        d8888888888 Y88b  d88P Y88b  d88P d8b 
    888P     Y888 d88P     888 88888888 888    Y88b      d88P     888  "Y8888P"   "Y8888P"  Y8P 
    */
    case "sidebarMyWalkAssessment":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnGreen elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '14vh',         // <<<<<<<<<<<<<<<<< High
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );  // end MyWalkAssessment
    /*
    888b     d888 Y88b   d88P      8888888888 8888888 88888888888 
    8888b   d8888  Y88b d88P       888          888       888     
    88888b.d88888   Y88o88P        888          888       888     
    888Y88888P888    Y888P         8888888      888       888     
    888 Y888P 888     888          888          888       888     
    888  Y8P  888     888          888          888       888     
    888   "   888     888          888          888       888     
    888       888     888          888        8888888     888    
    */

    case "sidebarMyFitness":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnRed elmAnimPopIn
              ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '32vh',              // <<<<<<<<<<<<<<<<< Mid
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /*
    8888888b.  8888888888 8888888b.  888             d8888 Y88b   d88P      88888888888 888     888 88888888888 
    888   Y88b 888        888   Y88b 888            d88888  Y88b d88P           888     888     888     888     
    888    888 888        888    888 888           d88P888   Y88o88P            888     888     888     888     
    888   d88P 8888888    888   d88P 888          d88P 888    Y888P             888     888     888     888     
    8888888P"  888        8888888P"  888         d88P  888     888              888     888     888     888     
    888 T88b   888        888        888        d88P   888     888              888     888     888     888     
    888  T88b  888        888        888       d8888888888     888              888     Y88b. .d88P     888     
    888   T88b 8888888888 888        88888888 d88P     888     888              888      "Y88888P"      888    
    */

    case "sidebarReplayTutorial":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnRed elmAnimPopIn
        ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '32vh',              // <<<<<<<<<<<<<<<<< Mid
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /*
     .d8888b. 88888888888     d8888 8888888b. 88888888888             d8888  .d8888b.   .d8888b.      
    d88P  Y88b    888        d88888 888   Y88b    888                d88888 d88P  Y88b d88P  Y88b     
    Y88b.         888       d88P888 888    888    888               d88P888 Y88b.      Y88b.          
     "Y888b.      888      d88P 888 888   d88P    888              d88P 888  "Y888b.    "Y888b.       
        "Y88b.    888     d88P  888 8888888P"     888             d88P  888     "Y88b.     "Y88b.     
          "888    888    d88P   888 888 T88b      888            d88P   888       "888       "888     
    Y88b  d88P    888   d8888888888 888  T88b     888           d8888888888 Y88b  d88P Y88b  d88P d8b 
     "Y8888P"     888  d88P     888 888   T88b    888          d88P     888  "Y8888P"   "Y8888P"  Y8P
    */
    case "sidebarStartAssessment":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnYellow elmAnimPopIn
              ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '50vh',              // <<<<<<<<<<<<<<<<<<< loW
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /*
    888888b.         d8888 888             d8888 888b    888  .d8888b.  8888888888 
    888  "88b       d88888 888            d88888 8888b   888 d88P  Y88b 888        
    888  .88P      d88P888 888           d88P888 88888b  888 888    888 888        
    8888888K.     d88P 888 888          d88P 888 888Y88b 888 888        8888888    
    888  "Y88b   d88P  888 888         d88P  888 888 Y88b888 888        888        
    888    888  d88P   888 888        d88P   888 888  Y88888 888    888 888        
    888   d88P d8888888888 888       d8888888888 888   Y8888 Y88b  d88P 888        
    8888888P" d88P     888 88888888 d88P     888 888    Y888  "Y8888P"  8888888888
    */
    case "sidebarBalancingMovements":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnGreen elmAnimPopIn
        ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '14vh',         // <<<<<<<<<<<<<<<<< High
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );  // end MyWalkAssessment
    /*
    888      8888888888 .d8888b.        .d8888b.  888     888 8888888b.  888      
    888      888       d88P  Y88b      d88P  Y88b 888     888 888   Y88b 888      
    888      888       888    888      888    888 888     888 888    888 888      
    888      8888888   888             888        888     888 888   d88P 888      
    888      888       888  88888      888        888     888 8888888P"  888      
    888      888       888    888      888    888 888     888 888 T88b   888      
    888      888       Y88b  d88P      Y88b  d88P Y88b. .d88P 888  T88b  888      
    88888888 8888888888 "Y8888P88       "Y8888P"   "Y88888P"  888   T88b 88888888
    */

    case "sidebarLegCurls":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnRed elmAnimPopIn
          ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '32vh',              // <<<<<<<<<<<<<<<<< Mid
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );
    /*
    888      8888888888 .d8888b.       8888888888 Y88b   d88P 88888888888 
    888      888       d88P  Y88b      888         Y88b d88P      888     
    888      888       888    888      888          Y88o88P       888     
    888      8888888   888             8888888       Y888P        888     
    888      888       888  88888      888           d888b        888     
    888      888       888    888      888          d88888b       888     
    888      888       Y88b  d88P      888         d88P Y88b      888     
    88888888 8888888888 "Y8888P88      8888888888 d88P   Y88b     888    
    */
    case "sidebarLegExtensions":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnYellow elmAnimPopIn
              ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '50vh',              // <<<<<<<<<<<<<<<<<<< loW
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );
    /*
    8888888b.  8888888888 8888888b.  888             d8888 Y88b   d88P 
    888   Y88b 888        888   Y88b 888            d88888  Y88b d88P  
    888    888 888        888    888 888           d88P888   Y88o88P   
    888   d88P 8888888    888   d88P 888          d88P 888    Y888P    
    8888888P"  888        8888888P"  888         d88P  888     888     
    888 T88b   888        888        888        d88P   888     888     
    888  T88b  888        888        888       d8888888888     888     
    888   T88b 8888888888 888        88888888 d88P     888     888    
    */
    case "sidebarReplay":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnGreen elmAnimPopIn
        ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '4vw',
            top: '14vh',         // <<<<<<<<<<<<<<<<< High
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );  // end MyWalkAssessment





    default:
      return undefined;

  }
}

export default SidebarElementHandler;