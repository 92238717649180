/*

88888888888     d8888 888888b.   888      8888888888
    888        d88888 888  "88b  888      888
    888       d88P888 888  .88P  888      888
    888      d88P 888 8888888K.  888      8888888
    888     d88P  888 888  "Y88b 888      888
    888    d88P   888 888    888 888      888
    888   d8888888888 888   d88P 888      888
    888  d88P     888 8888888P"  88888888 8888888888



 .d8888b. 8888888 8888888b.  8888888888 888888b.         d8888 8888888b.
d88P  Y88b  888   888  "Y88b 888        888  "88b       d88888 888   Y88b
Y88b.       888   888    888 888        888  .88P      d88P888 888    888
 "Y888b.    888   888    888 8888888    8888888K.     d88P 888 888   d88P
    "Y88b.  888   888    888 888        888  "Y88b   d88P  888 8888888P"
      "888  888   888    888 888        888    888  d88P   888 888 T88b
Y88b  d88P  888   888  .d88P 888        888   d88P d8888888888 888  T88b
 "Y8888P" 8888888 8888888P"  8888888888 8888888P" d88P     888 888   T88b


*/

// The font for these things  --  http://patorjk.com/software/taag/#p=display&f=Colossal  --
// The smaller banners are    -- http://patorjk.com/software/taag/#p=display&f=Banner3&t=Type%20Something%20 --

import React from 'react';
import Icon from '../Icon.js';
import SFX from '../SFX.js';
import { Logger } from 'aws-amplify';

const TableSidebarElementHandler = (elementAttributes, isHiding, handleClick) => {
  const logger = new Logger('TableSidebarElementHandler');
  //General Icon Placement
  let curIcon = Icon(elementAttributes.icon);

  if(curIcon !== undefined) curIcon = (<div style={{height:'auto', width: '16%', lineHeight: '10%', margin:'0.8vw', minWidth:"16%"}}>{curIcon}</div>);
  else curIcon = (<div style={{margin:'0.0vw'}}></div>);

  let elementText = (<div style={{margin:'auto'}}>{elementAttributes.text}</div>);

  if(!isHiding){
    //Creation sound effect.
    if (elementAttributes.enterFX !== null) {
      logger.debug(elementAttributes.enterFX);
      SFX(elementAttributes.enterFX);
    } else { //Default sfx case
      SFX("slidein");
    }
  }

  switch (elementAttributes.type) {


    /*

      8888888b.  8888888888 .d8888b.        .d8888b. 8888888 8888888b.  8888888888 888888b.         d8888 8888888b.
      888   Y88b 888       d88P  Y88b      d88P  Y88b  888   888  "Y88b 888        888  "88b       d88888 888   Y88b
      888    888 888       888    888      Y88b.       888   888    888 888        888  .88P      d88P888 888    888
      888   d88P 8888888   888              "Y888b.    888   888    888 8888888    8888888K.     d88P 888 888   d88P
      8888888P"  888       888  88888          "Y88b.  888   888    888 888        888  "Y88b   d88P  888 8888888P"
      888 T88b   888       888    888            "888  888   888    888 888        888    888  d88P   888 888 T88b
      888  T88b  888       Y88b  d88P      Y88b  d88P  888   888  .d88P 888        888   d88P d8888888888 888  T88b
      888   T88b 8888888888 "Y8888P88       "Y8888P" 8888888 8888888P"  8888888888 8888888P" d88P     888 888   T88b

    */

    /**
     * SIDEBAR
     * sidebar button #1, top
     */
    case "tableSidebarButton1":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '5vw',
            top: '14vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display:'flex',
            alignItems:'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
        {curIcon}{elementText}
        </button>
      );

    /**
     * SIDEBAR
     * sidebar button #2, middle
     */
    case "tableSidebarButton2":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '5vw',
            top: '32vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display:'flex',
            alignItems:'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /**
     * SIDEBAR
     * sidebar button #3, bottom
     */
    case "tableSidebarButton3":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '5vw',
            top: '50vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display:'flex',
            alignItems:'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

      /*

    Y88b   d88P 8888888888 .d8888b.        d88P 888b    888  .d88888b.
     Y88b d88P  888       d88P  Y88b      d88P  8888b   888 d88P" "Y88b
      Y88o88P   888       Y88b.          d88P   88888b  888 888     888
       Y888P    8888888    "Y888b.      d88P    888Y88b 888 888     888
        888     888           "Y88b.   d88P     888 Y88b888 888     888
        888     888             "888  d88P      888  Y88888 888     888
        888     888       Y88b  d88P d88P       888   Y8888 Y88b. .d88P
        888     8888888888 "Y8888P" d88P        888    Y888  "Y88888P"


      */

    /**
     * SIDEBAR
     * yes
     */
    case "tableSidebarYes":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnGreen elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '5vw',
            top: '14vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display:'flex',
            alignItems:'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /**
     * SIDEBAR
     * no
     */
    case "tableSidebarNo":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnRounded elmBtnRed elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '5vw',
            top: '32vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display:'flex',
            alignItems:'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

      case "tableSidebarNotYet":
        return (
          <button
            className={
              /* CLASSES */
              `elmBtnLarge elmBtnRounded elmBtnYellow elmAnimPopIn
              ${isHiding ? 'elmAnimPopOut' : ''}`
            }
            style={{
              /* STYLES */
              position: 'absolute',
              zIndex: '500000',
              right: '5vw',
              top: '50vh',
              width: '24vw',
              height: '8vw',

              /*  FLEX  */
              display:'flex',
              alignItems:'center'
            }}
            onClick={() => handleClick(elementAttributes)}
          >
            {curIcon}{elementText}
          </button>
        );

      /*

      8888888b.   .d88888b.  888b    888 8888888888
      888  "Y88b d88P" "Y88b 8888b   888 888
      888    888 888     888 88888b  888 888
      888    888 888     888 888Y88b 888 8888888
      888    888 888     888 888 Y88b888 888
      888    888 888     888 888  Y88888 888
      888  .d88P Y88b. .d88P 888   Y8888 888
      8888888P"   "Y88888P"  888    Y888 8888888888

      */


    /**
     * SIDEBAR
     * done
     */
    case "tableSidebarDone":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnPurple elmAnimPopIn elmBtnRounded
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '7vw',
            top: '70vh',
            width: '23vw',
            height: '7vw',

            /*  FLEX  */
            display:'flex',
            alignItems:'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

    /*

        888b    888 8888888888 Y88b   d88P 88888888888     d88P 8888888b.  8888888b.  8888888888 888     888
        8888b   888 888         Y88b d88P      888        d88P  888   Y88b 888   Y88b 888        888     888
        88888b  888 888          Y88o88P       888       d88P   888    888 888    888 888        888     888
        888Y88b 888 8888888       Y888P        888      d88P    888   d88P 888   d88P 8888888    Y88b   d88P
        888 Y88b888 888           d888b        888     d88P     8888888P"  8888888P"  888         Y88b d88P
        888  Y88888 888          d88888b       888    d88P      888        888 T88b   888          Y88o88P
        888   Y8888 888         d88P Y88b      888   d88P       888        888  T88b  888           Y888P
        888    Y888 8888888888 d88P   Y88b     888  d88P        888        888   T88b 8888888888     Y8P

    */
    case "tableSidebarNext":
      return (
        <button
          className={
            /* CLASSES */
            `elmBtnLarge elmBtnBlue elmAnimPopIn
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '5vw',
            top: '14vh',
            width: '24vw',
            height: '8vw',

            /*  FLEX  */
            display:'flex',
            alignItems:'center'
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}{elementText}
        </button>
      );

      case "tableSidebarPrevious":
        return (
          <button
            className={
              /* CLASSES */
              `elmBtnLarge elmBtnBlue elmAnimPopIn
              ${isHiding ? 'elmAnimPopOut' : ''}`
            }
            style={{
              /* STYLES */
              position: 'absolute',
              zIndex: '500000',
              right: '5vw',
              top: '32vh',
              width: '24vw',
              height: '8vw',

              /*  FLEX  */
              display:'flex',
              alignItems:'center'
            }}
            onClick={() => handleClick(elementAttributes)}
          >
            {curIcon}{elementText}
          </button>
        );

    default:
      return undefined;

  }
}

export default TableSidebarElementHandler;
