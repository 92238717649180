/* eslint-disable max-len */
/*
88888888888 .d88888b.  8888888b.  888888b.         d8888 8888888b.
    888    d88P" "Y88b 888   Y88b 888  "88b       d88888 888   Y88b
    888    888     888 888    888 888  .88P      d88P888 888    888
    888    888     888 888   d88P 8888888K.     d88P 888 888   d88P
    888    888     888 8888888P"  888  "Y88b   d88P  888 8888888P"
    888    888     888 888        888    888  d88P   888 888 T88b
    888    Y88b. .d88P 888        888   d88P d8888888888 888  T88b
    888     "Y88888P"  888        8888888P" d88P     888 888   T88b
*/
import React, { useEffect, useState } from 'react';

import { useStoreState, useStoreActions } from 'easy-peasy';
import { actionButton, Hub } from 'aws-amplify';
import Switch from 'react-switch';
import { multiply } from 'lodash';
import { data, div } from '@tensorflow/tfjs';
import { DivideBlock } from 'babylonjs';
import { CloudSearch } from 'aws-sdk';
import { clearConfigCache } from 'prettier';
import NotificationBar from '../../TopLevel/NotificationBar';
import Icon from '../Icon.js';

const TopbarElementHandler = (elementAttributes, isHiding, handleClick) => {
  // const eventManager = useStoreState(state => state.eventManager);

  const lang = useStoreState(state => state.primaryLanguage);
  const isRecording = useStoreState(state => state.isRecording);
  const toggleIsRecording = useStoreActions(actions => actions.toggleIsRecording);
  const [privacyMode, setPrivacyMode] = useState(false);
  const [isListening, setListening] = useState(false);

  // console.log('Language is : ', lang);

  const toggleMic = () => {
    toggleIsRecording();
    Hub.dispatch('elmAction', isRecording ? 'privacyOn' : 'privacyOff');
    Hub.dispatch(isRecording ? 'StartPrivacyMode' : 'StopPrivacyMode');
    setPrivacyMode(!!isRecording);
    // Hub.remove('VocalPrivacyMode');
  };

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _vocalPrivacyMode = async () => {
      toggleMic();
    };

    Hub.listen('VocalPrivacyMode', _vocalPrivacyMode);

    // eslint-disable-next-line no-underscore-dangle
    const _setListening = () => {
      setListening(true);
    };
    Hub.listen('setListening', _setListening);
    // // eslint-disable-next-line no-console

    // eslint-disable-next-line no-underscore-dangle
    const _setNotListening = () => {
      setListening(false);
    };
    Hub.listen('setIsNotListening', _setNotListening);

    return () => {
      Hub.remove('VocalPrivacyMode', _vocalPrivacyMode);
      Hub.remove('setListening', _setListening);
      Hub.remove('setIsNotListening', _setNotListening);
    };
  });

  // Icons
  let curIcon = Icon(elementAttributes.icon);
  if (curIcon !== undefined) {
    curIcon = (
      // eslint-disable-next-line react/jsx-filename-extension
      <div
        style={{
          height: 'auto',
          width: '3vw',
          lineHeight: '10%',
          margin: 'auto',
          minWidth: '16%',
        }}
      >
        {curIcon}
      </div>
    );
  } else curIcon = <div style={{ margin: '0.0vw' }} />;

  // Assign the text
  const elementText = <div style={{ margin: 'auto' }}>{elementAttributes.text}</div>;


  const textSwitch = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    fontSize: 16,
    fontFamily: 'Publica Play',
    color: '#fff',
  };

  let myLocalCssClass;
  let privacyText;
  let addisonIconBar;
  let verticalLinePrivacy;
  let verticalLine;

  const textBar = isListening ? 'Listening' : !isRecording ? 'PrivacyOn' : 'addison';
  const textBarDE = isListening ? 'Hören' : !isRecording ? 'Privatsphäre' : 'addison';

  const addisonBar = (
    <div
      className={
        isListening ? 'addisonTextListen ' : !isRecording ? 'addisonTextPrivacyOn' : 'addisonText'
      }
    >
      {textBar}
    </div>
  );
  const addisonBarDE = (
    <div
      className={
        isListening
          ? 'addisonTextListenDE '
          : !isRecording
            ? 'addisonTextPrivacyOn'
            : 'addisonTextDE'
      }
    >
      {textBarDE}
    </div>
  );

  if (lang === 'en' || lang.includes('en-')) {
    myLocalCssClass = addisonBar;
    privacyText = <div className="privacyText">Privacy:</div>;
    addisonIconBar = <div className={isListening ? 'addisonIconListening underline--Listening ' : !isRecording ? 'addisonIconPrivacy underline--PrivacyOn' : 'addisonIcon'}>{curIcon}</div>
    verticalLinePrivacy =  <div className="verticalLinePrivacy" style={{ margin: 'auto' }} />;
    verticalLine = <div className="verticalLine" style={{ margin: 'auto' }}> {elementAttributes.border} </div>
  } else {
    myLocalCssClass = addisonBarDE;
    privacyText = <div className="privacyTextDE">Privater Modus:</div>;
    addisonIconBar = <div className={isListening ? 'addisonIconListeningDE underline--ListeningDE ' : !isRecording ? 'addisonIconPrivacyDE underline--PrivacyOnDE' : 'addisonIconDE'}>{curIcon}</div>
    verticalLinePrivacy = <div className="verticalLinePrivacyDE" style={{ margin: 'auto' }} />;
    verticalLine = <div className="verticalLineDE" style={{ margin: 'auto' }}> {elementAttributes.border} </div>
  }

  switch (elementAttributes.type) {
    /**
     * TOP BAR
     * assist button, top bar, left
     */


    /**
     * TOP BAR
     * SWITCHE TO assit button to right of Addison button
     */
    case 'Call':
      return (
        // eslint-disable-next-line react/button-has-type
        <button
          className={
            /* CLASSES */
            // `elmBtnPermanent elmBtnAssist elmAnimSlideDown
            // ${isHiding ? 'elmAnimPopOut' : ''}`
            `elmBtnPermanent elmBtnAssist 
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            left: '31vw',
            width: '10vw',
            height: '6vh',
            top: '1vh',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}
          {elementText}
        </button>
      );

      //* *** THIS COMMENT FOR GERMAN DEMO PURPOSES */
      // case 'asssitDE':
      //   return (
      //     // eslint-disable-next-line react/button-has-type
      //     <button
      //       className={
      //         /* CLASSES */
      //         // `elmBtnPermanent elmBtnAssist elmAnimSlideDown
      //         // ${isHiding ? 'elmAnimPopOut' : ''}`
      //         `elmBtnPermanent elmBtnAssist
      //         ${isHiding ? 'elmAnimPopOut' : ''}`
      //       }
      //       style={{
      //         /* STYLES */
      //         position: 'absolute',
      //         zIndex: '500000',
      //         left: '50vw',
      //         width: '10vw',
      //         height: '6vh',
      //         top: '1vh',

      //         /*  FLEX  */
      //         display: 'flex',
      //         alignItems: 'center',
      //       }}
      //       onClick={() => handleClick(elementAttributes)}
      //     >
      //       {curIcon}
      //       {elementText}

      //     </button>
      //   );
      /**
     * ADDISON
     * Addison button, top bar, left-mid
     */

    /**
     * SWITCHED TO ADDISON to LEFT
     */
    case 'addison':
      return (
        <>
          {/* <div className={toggleIsListening ? 'addisonText' : 'addisonListen'}> */}
          <div>
            {addisonIconBar}
            {myLocalCssClass}
          </div>
        </>
      );

    /**
     * TERTIARY
     * -
     */
    case 'tertiary':
      return (
        // eslint-disable-next-line react/button-has-type
        <button
          className={
            /* CLASSES */
            `elmBtnPermanent elmBtnBlue elmAnimSlideDown
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            right: '15vw',
            width: '20vw',
            height: '6vh',
            top: '0vh',
            color: 'red',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}
          {elementText}
        </button>
      );


    case 'help':
      return (
        // eslint-disable-next-line react/button-has-type
        <button
          className={
            /* CLASSES */
            // `elmBtnPermanent elmBtnAssist elmAnimSlideDown
            // ${isHiding ? 'elmAnimPopOut' : ''}`
            `elmBtnPermanent elmBtnAssist 
            ${isHiding ? 'elmAnimPopOut' : ''}`
          }
          style={{
            /* STYLES */
            position: 'absolute',
            zIndex: '500000',
            left: '19vw',
            width: '10vw',
            height: '6vh',
            top: '1vh',

            /*  FLEX  */
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={() => handleClick(elementAttributes)}
        >
          {curIcon}
          {elementText}
          {verticalLinePrivacy}
          {verticalLine}


        </button>
      );

      //* *** THIS COMMENT FOR GERMAN DEMO PURPOSES */
      // case 'helpDE':
      //   return (
      //   eslint-disable-next-line react/button-has-type
      //     <button
      //       className={
      //           /* CLASSES */
      //           `elmBtnPermanent elmBtnAssist elmAnimSlideDown
      //           ${isHiding ? 'elmAnimPopOut' : ''}`
      //           `elmBtnPermanent elmBtnAssist
      //           ${isHiding ? 'elmAnimPopOut' : ''}`
      //         }
      //       style={{
      //         /* STYLES */
      //         position: 'absolute',
      //         zIndex: '500000',
      //         left: '19vw',
      //         width: '10vw',
      //         height: '6vh',
      //         top: '1vh',

    //         /*  FLEX  */
    //         display: 'flex',
    //         alignItems: 'center',
    //       }}
    //       onClick={() => handleClick(elementAttributes)}
    //     >
    //       {curIcon}
    //       {elementText}
    //       {verticalLinePrivacy}
    //       {verticalLine}
    //     </button>
    //   );
    case 'listen':
      return (
        <>
          {privacyText}
          <div id="btnmic-root">
            <label>
              <span>this switch</span>
              <Switch
                className="react-switch"
                onChange={toggleMic}
                checked={privacyMode}
                checkedIcon={<div style={textSwitch}>On</div>}
                uncheckedIcon={<div style={textSwitch}>Off</div>}
                height={30}
                width={70}
                offColor="#4C5B66"
                onColor="#FF6600"
                onHandleColor="#FF9900"
                offHandleColor="#064077"
              />
            </label>
          </div>
        </>
      );

    case 'listenDE':
      return (
        <>
          {verticalLinePrivacy}
          {verticalLine}
          {privacyText}
          <div id="btnmic-root">
            <label>
              <span>this switch</span>
              <Switch
                className="react-switchDE"
                onChange={toggleMic}
                checked={privacyMode}
                checkedIcon={<div style={textSwitch}>ein</div>}
                uncheckedIcon={<div style={textSwitch}>aus</div>}
                height={30}
                width={70}
                offColor="#4C5B66"
                onColor="#FF6600"
                onHandleColor="#FF9900"
                offHandleColor="#064077"
              />
            </label>
          </div>
        </>
      );

    case 'notifications':
      return <NotificationBar />;

    default:
      return undefined;
  }
};

export default TopbarElementHandler;
