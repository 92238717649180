import { Observable, fromEventPattern } from 'rxjs';
// import { DataEvent } from 'types/bluetooth';

const bleConnect = async (serviceId, characteristicId, otherServices) => {
  if (!navigator.bluetooth)
    throw new Error(
      `Bluetooth not supported in this browser, context isn't secured with Https, or domain is disassociated from its origin.`,
    );
  const device = await navigator.bluetooth.requestDevice({
    filters: [{ services: [serviceId] }, ...(otherServices?.map((s) => ({ services: [s] })) || [])],
  });

  const server = device.gatt && await device.gatt.connect();
  if (!server) throw new Error(`Device does not possess a gatt server`);

  const service = await server.getPrimaryService(serviceId);

  const characteristic = await service.getCharacteristic(characteristicId);
  await characteristic.startNotifications();

  const listen = (handler) => {
    characteristic.addEventListener('characteristicvaluechanged', handler);
  };

  const stopListening = (handler) => {
    characteristic.removeEventListener('characteristicvaluechanged', handler);
    device.gatt && device.gatt.disconnect();
  };

  return fromEventPattern(listen, stopListening);
};

export default bleConnect;