import { Logger, Hub } from 'aws-amplify';
// import { BehaviorUtils } from '../behaviors/BehaviorUtils';
import store from '../_GlobalStateStore/GlobalStateStore';

const logger = new Logger('AFKAnimationBehavior');

const machineVars = {
  path: 'tabletType',
}

let currentLayer = new Set();
// let currentAnimDetails = [];

// const animations = [];

function setup() {
  function* getNext() {
    logger.debug("in getNext");
    while (cancel === false) {
      if (machineVars.path === 'tabletType') {
        yield ['Emote', 'tabletWatch_in'];
        yield ['Gesture', 'tabletWatch'];
        yield ['Emote', 'tabletWatch_out'];
      } else {
        yield ['Emote', 'tabletWatch_in'];
        yield ['Gesture', 'tabletWatch_look'];
        yield ['Gesture', 'tabletWatch'];
        yield ['Gesture', 'tabletWatch_silly'];
        yield ['Emote', 'tabletWatch_out'];
      }
    }
    return;
  }
  const nextGenerator = getNext();


  let afk1Init = false, afk2Init = false;
  let cancel = false;
  let currentAnim;
  Hub.listen('AFK1', async () => {
    if (afk1Init === true) return;
    else afk1Init = true;

    Hub.dispatch('disablePOI');

    const _handleCancel = async () => {
      cancel = true;
      // currentAnim && currentAnim.cancel();
      store.getState().host.AnimationFeature.playAnimation('Emote', 'tabletWatch_out');
      currentLayer.forEach(([layer, clipName]) => {
        // store.getState().host.AnimationFeature.setAnimationBlendWeight(layer, clipName, "blend name", 0);
        store.getState().host.AnimationFeature.stopAnimation(layer, clipName);
      });
      Hub.dispatch('enablePOI');
      await store.getState().host.AnimationFeature.playAnimation('Base', 'stand_idle');
      return Promise.resolve();
    }

    Hub.listen('AFKCancel', _handleCancel);
    Hub.listen('defaultAnim', _handleCancel);

    while (cancel === false) {
      const [layer, clipName] = nextGenerator.next().value;
      layer !== 'Emote' && currentLayer.add([layer, clipName]);
      currentAnim = store.getState().host.GestureFeature.playGesture(layer, clipName);
      // currentAnimDetails = [layer, clipName];
      await currentAnim;
    }

    Hub.remove('AFKCancel', _handleCancel);
    Hub.remove('defaultAnim', _handleCancel);
    cancel = false;
    afk1Init = false;
    logger.debug('exiting AFK1');
  });


  Hub.listen('AFK2', async () => {
    if (afk2Init === true) return;
    else afk2Init = true;

    Hub.dispatch('disablePOI');

    const _handleCancel = () => {
      cancel = true;
      currentAnim && currentAnim.cancel();
      store.getState().host.AnimationFeature.playAnimation('Base', 'stand_idle',);
      Hub.dispatch('enablePOI');
      return Promise.resolve();
    }

    Hub.listen('AFKCancel', _handleCancel);
    Hub.listen('defaultAnim', _handleCancel);


    while (cancel === false) {
      const [layer, clipName] = nextGenerator.next().value; //TODO: change to the correct generator
      currentAnim = await store.getState().host.GestureFeature.playGesture(layer, clipName);
    }

    Hub.remove('AFKCancel', _handleCancel);
    Hub.remove('defaultAnim', _handleCancel);
    cancel = false;
    afk2Init = false;
    logger.debug('exiting AFK2');
  });
}
export const AFKAnimationBehavior = { setup };