import * as BABYLON from 'babylonjs';
import { Hub, Logger } from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore'


export async function initOwlTimer(scene) {
    const logger = new Logger('initOwlTimer.js');

    // Owl Meshes
    const owlTimerHead = scene.getMeshByName("OwlTimer_Head")
    owlTimerHead.alwaysSelectAsActiveMesh = true
    const owlTimerBody = scene.getMeshByName("OwlTimer_Body")
    owlTimerBody.alwaysSelectAsActiveMesh = true
    const owlTimerScreen = scene.getMeshByName("OwlTimer_Screen")
    owlTimerScreen.alwaysSelectAsActiveMesh = true


    // Owl animation groups
    const owlTimerIntro = scene.getAnimationGroupByName("OwlTimer_Intro")
    const owlTimerWindDown = scene.getAnimationGroupByName("OwlTimer_WindDown")
    const owlTimerLoop = scene.getAnimationGroupByName("OwlTimer_Loop");
    const owlTimerOutro = scene.getAnimationGroupByName("OwlTimer_Outro");
    owlTimerIntro.stop();

    // Wind Down Speed
    owlTimerWindDown.speedRatio = 1;


    // Cuckoo Clock Alarm Sound
    const owlTimerAlarm = new BABYLON.Sound("",
        "https://cdn.ecg-health.com/assets/sounds/OwlTimer_NoAddison_v02.wav", scene, null, {
        loop: true,
        autoplay: false,
        volume: 0.5
    });

    // Sprite Sheet
    const countDownTexture = scene.getMaterialByName("ChestDisplay_MAT").albedoTexture

    const moveSpriteRight = function () {
        countDownTexture.uOffset += 0.25;
        count -= 1;
        countDownTimer();
        console.log("Count: ", count)
    }

    const moveSpriteDown = function () {
        countDownTexture.uOffset += 0.25;
        countDownTexture.vOffset += -0.25;
        count -= 1;
        countDownTimer();
        console.log("Count: ", count)
    }

    const moveSpriteReset = function () {
        count = 15;
        countDownTexture.uOffset += 0.25;
        countDownTexture.vOffset += -0.25;
    }

    const windDown = function () {
        owlTimerWindDown.play(false);
    }


    // Countdown Logic
    let count = 15

    const countDownTimer = function () {
        if (count == 12 || count == 8 || count == 4) {
            setTimeout(moveSpriteDown, 1000);
        } else if (count > 0) {
            setTimeout(moveSpriteRight, 1000);
        } else if (count == 0) {
            console.log("Countdown ended");
            _owlTimerLoop();
        }
    }


    // Owl Animation
    const _owlTimerStart = () => {
        owlTimerLoop.stop();
        owlTimerIntro.play(false);
        countDownTimer();
        setTimeout(windDown, 2500)
    };
    const _owlTimerLoop = () => {
        owlTimerLoop.stop();
        owlTimerAlarm.stop();
        owlTimerLoop.play(true);
        owlTimerAlarm.play();
    };
    const _owlTimerStop = () => {
        owlTimerLoop.stop();
        owlTimerAlarm.stop();
        owlTimerOutro.play(false);
        setTimeout(moveSpriteReset, 2000)

    };


    // Listener
    const _owlTimer = async (capsule) => {
        const { payload } = capsule;

        logger.debug(`Owl timer ${payload.data}`)

        switch (payload.data) {
            case 'start':
                _owlTimerStart();
                break;
            case 'loop':
                _owlTimerLoop();
                break;
            case 'stop':
                _owlTimerStop();
                break;
            default:
                logger.debug('Unlisted owl timer state :', payload.data);
        }
    }


    // Clock listener
    Hub.listen('owlTimer', _owlTimer);

}