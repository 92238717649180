import * as BABYLON from 'babylonjs';
import { Hub, Logger } from 'aws-amplify';
import { WeatherManager } from '../WeatherManager';
import moment from 'moment';
// import cron from 'cron';
const CronJob = require('cron').CronJob;
export async function initHdrSwap(scene) {
    const logger = new Logger('initHdrSwap.js');

    // Fade In Anim
    var fadeIn = new BABYLON.Animation("fadeIn", "visibility", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
    // Fade Out Anim
    var fadeOut = new BABYLON.Animation ("fadeOut", "visibility", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONTYPE_FLOAT);
    
    var fadeIn_keys = [];
    fadeIn_keys.push({ frame: 0, value: 0 })
    fadeIn_keys.push({ frame: 120, value: 1 })
    fadeIn.setKeys(fadeIn_keys)
    
    var fadeOut_keys = [];
    fadeOut_keys.push({ frame: 0, value: 1 })
    fadeOut_keys.push({ frame: 120, value: 0 })
    fadeOut.setKeys(fadeOut_keys)


    //define all the skyboxes
    const clearMorningSkyboxTexture = new BABYLON.HDRCubeTexture("https://cdn.ecg-health.com/assets/environ/skyboxes/clearMorning_v04.hdr", scene, 512);
    const clearDaySkyboxTexture = new BABYLON.HDRCubeTexture("https://cdn.ecg-health.com/assets/environ/skyboxes/kloppenheim_06_1k.hdr", scene, 512);
    const clearSunsetSkyboxTexture = new BABYLON.HDRCubeTexture("https://cdn.ecg-health.com/assets/environ/skyboxes/clearSunset_v04.hdr", scene, 512);
    const clearNightSkyboxTexture = new BABYLON.HDRCubeTexture("https://cdn.ecg-health.com/assets/environ/skyboxes/clearNight_v04.hdr", scene, 512);
    const cloudyMorningSkyboxTexture = new BABYLON.HDRCubeTexture("https://cdn.ecg-health.com/assets/environ/skyboxes/kloppenheim_06_1k.hdr", scene, 512);
    const cloudyDaySkyboxTexture = new BABYLON.HDRCubeTexture("https://cdn.ecg-health.com/assets/environ/skyboxes/cloudyDay_v04.hdr", scene, 512);
    const austriaDaySkyboxTexture = new BABYLON.HDRCubeTexture("https://cdn.ecg-health.com/assets/environ/skyboxes/kloetzle_blei_2k.hdr", scene, 512);
    const cloudySunsetSkyboxTexture = new BABYLON.HDRCubeTexture("https://cdn.ecg-health.com/assets/environ/skyboxes/cloudyDay_v04.hdr", scene, 512);
    const cloudyNightSkyboxTexture = new BABYLON.HDRCubeTexture("https://cdn.ecg-health.com/assets/environ/skyboxes/cloudyDay_v04.hdr", scene, 512);
    // invisible image
    // const invisibleImage = new BABYLON.Texture("https://cdn.ecg-health.com/assets/environ/skyboxes/invisibleImage.png", scene);
    

    //keep a reference to the "active" or "current" HDR
    let currentHdr, nextHdr, currentName;
    //grab the initial HDR based on the time and weather conditions of the page load
    // const currTime = new Date(Date.now());
    const currTime = moment();
    const currHour = moment().hour();
    const weatherStatus = await WeatherManager.getWeather();
    logger.debug('weatherStatus: ', weatherStatus);
    //every day at 6 am, transition to either the clear morning or cloudy morning skybox
    const nightToMorningTask = new CronJob('0 6 * * *', async () => {
        const weatherStatus = await WeatherManager.getWeather();
        _hideHDR();
        //TODO: if multiple weather types, change this to a switch statement to play the correct HDR texture
        if (weatherStatus === 'Clear') {
            _clearMorningHdr();
        } else {
            _cloudyMorningHdr();
        }
    });
    //every day at 1 pm, transition to either the clear day or cloudy day skybox
    const morningToDayTask = new CronJob('0 13 * * *', async () => {
        const weatherStatus = await WeatherManager.getWeather();
        _hideHDR();
        //TODO: if multiple weather types, change this to a switch statement to play the correct HDR texture
        if (weatherStatus === 'Clear') {
            _clearDayHdr();
        } else {
            _cloudyDayHdr();
        }
    });
    //every day at 4 pm, transition to either the clear sunset or cloudy sunset skybox
    const dayToSunsetTask = new CronJob('0 16 * * *', async () => {
        const weatherStatus = await WeatherManager.getWeather();
        _hideHDR();
        //TODO: if multiple weather types, change this to a switch statement to play the correct HDR texture
        if (weatherStatus === 'Clear') {
            _clearSunsetHdr();
        } else {
            _cloudySunsetHdr();
        }
    });
    //every day at 7 pm, transition to either the clear night or cloudy night skybox
    const sunsetToNightTask = new CronJob('0 19 * * *', async () => {
        const weatherStatus = await WeatherManager.getWeather();
        _hideHDR();
        //TODO: if multiple weather types, change this to a switch statement to play the correct HDR texture
        if (weatherStatus === 'Clear') {
            _clearNightHdr();
        } else {
            _cloudyNightHdr();
        }
    });

    //set the initial ("current") HDR 
    if (currHour >= 6 && currHour <= 13) {           /** 6am - 1pm */
        if (weatherStatus === 'Clear') {
            currentHdr = clearMorningSkyboxTexture;
            currentName = 'clearMorningSkybox';
            nextHdr = clearDaySkyboxTexture;
        } else {
            currentHdr = cloudyMorningSkyboxTexture;
            currentName = 'cloudyMorningSkybox';
            nextHdr = cloudyDaySkyboxTexture;
        }
    } else if (currHour > 13 && currHour <= 16) {    /** 1pm - 4pm */
        if (weatherStatus === 'Clear') {
            currentHdr = clearDaySkyboxTexture;
            currentName = 'clearDaySkybox';
            nextHdr = clearSunsetSkyboxTexture;
        } else {
            currentHdr = cloudyDaySkyboxTexture;
            currentName = 'cloudyDaySkybox';
            nextHdr = clearSunsetSkyboxTexture;
        }
    } else if (currHour > 16 && currHour <= 19) {    /** 4pm - 7pm */
        if (weatherStatus === 'Clear') {
            currentHdr = clearSunsetSkyboxTexture;
            currentName = 'clearSunsetSkybox';
            nextHdr = clearNightSkyboxTexture;
        } else {
            currentHdr = cloudySunsetSkyboxTexture;
            currentName = 'cloudySunsetSkybox';
            nextHdr = cloudyNightSkyboxTexture;
        }
    } else {                                        /** 7pm - 6am */
        if (weatherStatus === 'Clear') {
            currentHdr = clearNightSkyboxTexture;
            currentName = 'clearNightSkybox';
            nextHdr = clearMorningSkyboxTexture;
        } else {
            currentHdr = cloudyNightSkyboxTexture;
            currentName = 'cloudyNightSkybox';
            nextHdr = cloudyMorningSkyboxTexture;
        }
    }
    logger.debug('creating initial skybox');
    //create the skyboxes, the current one and the next one (need both for smooth alpha transitions)
    let currentSkybox = scene.createDefaultSkybox(currentHdr, true, 300);
    let nextSkybox;
    // let nextSkybox = scene.createDefaultSkybox(nextHdr, true, 300);
    // nextSkybox.alpha = 0;
    //calculate the setTimeout to play the next HDR transition
    let secondsUntilTransition;

    //every 5 minutes, check the weather and update the HDR texture / do the alpha fades automatically 
    setInterval(() => {
        const condition = WeatherManager.getCurrentCondition();
        if (condition === 'Clear') {
            switch (currentName) {
                case 'clearMorningSkybox':
                case 'cloudyMorningSkybox':
                    currentSkybox.dispose();
                    currentSkybox = scene.createDefaultSkybox(clearMorningSkyboxTexture, true, 300);
                    break;
                default:
                    break;
            }
        } else {
        }
    }, 30000);
    //keep a list of all HDRs, so we can loop over the list (filtered by the new HDR) if we need to
    let allHdrs = [clearMorningSkyboxTexture, clearDaySkyboxTexture, clearSunsetSkyboxTexture, clearNightSkyboxTexture, cloudyMorningSkyboxTexture, cloudyDaySkyboxTexture, cloudySunsetSkyboxTexture, cloudyNightSkyboxTexture, austriaDaySkyboxTexture]
    // Hide Hdr
    const _hideHDR = () => {

        logger.debug('fading out the current skybox');
        scene.beginDirectAnimation(currentSkybox, [fadeOut], 0, 120, false)
        setTimeout( () => {
            currentSkybox.dispose();
            const skyBoxMaterial = scene.getMaterialByName("skyBox")
            skyBoxMaterial && skyBoxMaterial.dispose();
        }, 2200);
    }
    const _resetCurrentSkybox = () => {
        //reset the current skybox back to the nextSkybox
        setTimeout( () => {
            currentSkybox && currentSkybox.dispose();
            currentSkybox = nextSkybox.clone('SkyBox');
            nextSkybox && nextSkybox.dispose();
            nextSkybox = undefined;
        }, 2200);
    };
    //transition between skybox A and skybox B (A is current)
    //create skybox B
    //set skybox B visibility to 0
    //tween skybox A visibility to 0
    //tween skybox B visibility to 1
    //dispose of skybox A
    //rename skybox B to be skybox A


    // Clear Skies
    const _clearMorningHdr = () => {
        nextSkybox && nextSkybox.dispose();
        nextSkybox = scene.createDefaultSkybox(clearMorningSkyboxTexture, true, 300)
        clearMorningSkyboxTexture.visibility = 0;
        scene.beginDirectAnimation(clearMorningSkyboxTexture, [fadeIn], 0, 120, false)
        _resetCurrentSkybox();
    };
    const _clearDayHdr = () => {
        nextSkybox && nextSkybox.dispose();
        nextSkybox = scene.createDefaultSkybox(clearDaySkyboxTexture, true, 300)
        clearDaySkyboxTexture.visibility = 0;
        scene.beginDirectAnimation(clearDaySkyboxTexture, [fadeIn], 0, 120, false)
        _resetCurrentSkybox();
    };
    const _clearSunsetHdr = () => {
        nextSkybox && nextSkybox.dispose();
        nextSkybox = scene.createDefaultSkybox(clearSunsetSkyboxTexture, true, 300)
        clearSunsetSkyboxTexture.visibility = 0;
        scene.beginDirectAnimation(clearSunsetSkyboxTexture, [fadeIn], 0, 120, false)
        _resetCurrentSkybox();
    };
    const _clearNightHdr = () => {
        nextSkybox && nextSkybox.dispose();
        nextSkybox = scene.createDefaultSkybox(clearNightSkyboxTexture, true, 300)
        clearNightSkyboxTexture.visibility = 0;
        scene.beginDirectAnimation(clearNightSkyboxTexture, [fadeIn], 0, 120, false)
        _resetCurrentSkybox();
    };

    // Cloudy Skies
    const _cloudyMorningHdr = () => {
        nextSkybox && nextSkybox.dispose();
        nextSkybox = scene.createDefaultSkybox(cloudyMorningSkyboxTexture, true, 300)
        cloudyMorningSkyboxTexture.visibility = 0;
        scene.beginDirectAnimation(cloudyMorningSkyboxTexture, [fadeIn], 0, 120, false)
        _resetCurrentSkybox();
    };
    const _cloudyDayHdr = () => {
        nextSkybox && nextSkybox.dispose();
        nextSkybox = scene.createDefaultSkybox(cloudyDaySkyboxTexture, true, 300)
        cloudyDaySkyboxTexture.visibility = 0;
        scene.beginDirectAnimation(cloudyDaySkyboxTexture, [fadeIn], 0, 120, false)
        _resetCurrentSkybox();
    };
    const _cloudySunsetHdr = () => {
        nextSkybox && nextSkybox.dispose();
        nextSkybox = scene.createDefaultSkybox(cloudySunsetSkyboxTexture, true, 300)
        cloudySunsetSkyboxTexture.visibility = 0;
        scene.beginDirectAnimation(cloudySunsetSkyboxTexture, [fadeIn], 0, 120, false)
        _resetCurrentSkybox();
    };
    const _cloudyNightHdr = () => {
        nextSkybox && nextSkybox.dispose();
        nextSkybox = scene.createDefaultSkybox(cloudyNightSkyboxTexture, true, 300)
        cloudyNightSkyboxTexture.visibility = 0;
        scene.beginDirectAnimation(cloudyNightSkyboxTexture, [fadeIn], 0, 120, false)
        _resetCurrentSkybox();
    };
    const _austrianDayHdr = () => {
        nextSkybox && nextSkybox.dispose();
        nextSkybox = scene.createDefaultSkybox(austriaDaySkyboxTexture, true, 300)
        austriaDaySkyboxTexture.visibility = 0;
        scene.beginDirectAnimation(austriaDaySkyboxTexture, [fadeIn], 0, 120, false)
        _resetCurrentSkybox();
    };
    

    const _startHdrSwap = async (capsule) => {
        const { payload } = capsule;
        _hideHDR();
        logger.debug(`fading in hdr for ${payload.data} env type`)
        switch (payload.data) {
            case 'clearMorning':
                _clearMorningHdr();
                break;
            case 'clearDay':
                _clearDayHdr();
                break;
            case 'clearSunset':
                _clearSunsetHdr();
                break;
            case 'clearNight':
                _clearNightHdr();
                break;
            case 'cloudyMorning':
                _cloudyMorningHdr();
                break;
            case 'cloudyDay':
                _cloudyDayHdr();
                break;
            case 'cloudySunset':
                _cloudySunsetHdr();
                break;
            case 'cloudyNight':
                _cloudyNightHdr();
                break;
            case 'austrianDay':
                _austrianDayHdr();
                break;
                
            default:
                logger.debug('invalid weather type for particle support');
        }
    }

    Hub.listen('hdrSwap', _startHdrSwap);
    // Hub.listen('')
    Hub.listen('cancelHdr', _hideHDR);
}