import { length } from "file-loader";


// eslint-disable-next-line import/prefer-default-export
export function formatTime() {
  const date = new Date();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = `${hours}:${minutes} ${ampm}`;
  return strTime;
}

export function displayDoubleDigit(number) {
  if (number < 10) {
    return `0${number.toString()}`;
  }
  return number.toString();
}

/**
// eslint-disable-next-line max-len
* @param standardTime parameter to get the time in standard format and converting to military time.
*/
export function toMilitaryTime(standardTime) {
  let hour = Number(standardTime.slice(0, 1)) % 12;
  let minute = standardTime.slice(2, 4);
  let extension = standardTime.slice(5);

  if (
    standardTime.slice(0, 2) === '10' ||
        standardTime.slice(0, 2) === '11' ||
        standardTime.slice(0, 2) === '12'
  ) {
    hour = Number(standardTime.slice(0, 2)) % 12;
    minute = standardTime.slice(3, 5);
    extension = standardTime.slice(6);
  }
  //console.log('Slice is ', standardTime.slice(0, 2));
  if (extension === 'pm') {
    hour += 12;
  }
  const militaryTime = `${displayDoubleDigit(hour)}:${minute}`;
  return militaryTime;
}


export function getCurrentTime() {
  let currentTime = formatTime();
  currentTime = toMilitaryTime(currentTime);
  return currentTime;
}


export const timeActivity = {
  startTime: '08:00',
  endTime: '23:00',
};
