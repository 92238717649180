/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState, useLayoutEffect } from 'react';
// import { hub } from 'aws-amplify';
import { Router } from '@reach/router';
import './MedicationCompliance.scss';
import fetchPMUMedicationReminder from '../../js/fetchPMUMedicationReminder';
import fetchPMUMedsHistory from '../../js/fetchPMUMedsHistory';
import MedicationTable from './components/MedicationTable';
import PrevMedicationTable from './components/PrevMedicationTable';
import UpcomingMedicationTable from './components/UpcomingMedicationTable';

require('@tensorflow/tfjs-backend-webgl');


const MedicationCompliance = () => {
  const [medicationData, setMedicationData] = useState();
  const [medicationHistory, setMedicationHistory] = useState();

  useEffect(() => {
    fetchPMUMedicationReminder()
      .then((res) => {
        const detail = [];
        for (const med of res) {
          for (const details of med.MedicationDetail) {
            detail.push(details);
          }
        }
        console.log(detail);
        setMedicationData(detail);
        // setMedicationData(res[0].MedicationDetail);
        // console.log(res[0]);
        // res.foreach(med=>{ med.MedicationDetail.map()})
      });
    fetchPMUMedsHistory()
      .then((res) => {
        console.log(res);
        setMedicationHistory(res);
      });
  }, []);
  useLayoutEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: {} })
      .then(success)
    // get the result of success and do a set state isHandToMouth set and do some ui stuff
      .catch((e) => {
        console.error(e);
      });
  });

  const success = async (stream) => {
    const video = document.getElementById('video');
    video.srcObject = stream;
    video.addEventListener('play', () => {
      // eslint-disable-next-line no-console
      console.log('video has started');
      console.log(stream);
    });
  };

  return (
    <Router>
      <MedicationTable
        path="/take-medications/ready"
        data={medicationData}
        header="Medications Reminder"
      />
      <PrevMedicationTable
        path="/previous-medications"
        data={medicationHistory}
        header="Previous Medications Reminder"
      />
      <UpcomingMedicationTable
        path="/upcoming-medications"
        data={medicationData}
        header="Upcoming Medications Reminder"
      />
      <MedicationTable
        path="/previous-medications/*"
        data={medicationData}
        header="Medications Reminder"
      />

    </Router>
  );
};
export default MedicationCompliance;
