/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * MoodChart
 *
 * @author Sierra Guerrero
 */

import React from 'react';

import store from '../../../_GlobalStateStore/GlobalStateStore';

const sendToSumerian = (mood) => {
  store.getActions().eventManager.emit({ channel: 'post_to_lex', data: mood });
};

const MoodChart = () => (
  <div>
    <div className="moodChart-container">
      <div className="moodChart-title-container"><span className="moodChart-title"> How are you today?</span></div>
      <div className="moodChart-content">

        <div
          data-testid="happy-button"
          onClick={() => sendToSumerian('happy')}
          className="moodChart-happy"
          role="button"
        >
          <p>
            <img alt="happy emoji" className="moodChart-img" src="https://addison-project-anim-files.s3.amazonaws.com/Addison+/Addison+Emojis+png/AddisonEmoji_happy.png" />
          HAPPY
          </p>
        </div>

        <div
          onClick={() => sendToSumerian('sad')}
          className="moodChart-sad"
          role="button"
        >
          <p>
            <img alt="sad emoji" className="moodChart-img" src="https://addison-project-anim-files.s3.amazonaws.com/Addison+/Addison+Emojis+png/AddisonEmoji_sad.png" />
          SAD
          </p>
        </div>

        <div
          data-testid="sick-button"
          onClick={() => sendToSumerian('sick')}
          className="moodChart-sick"
          role="button"
        >
          <p>
            <img alt="sick emoji" className="moodChart-img" src="https://addison-project-anim-files.s3.amazonaws.com/Addison+/Addison+Emojis+png/AddisonEmoji_sick.png" />
          SICK
          </p>
        </div>

        <div
          onClick={() => sendToSumerian('neutral')}
          className="moodChart-neutral"
          role="button"
        >
          <p>
            <img alt="neutral emoji" className="moodChart-img" src="https://addison-project-anim-files.s3.amazonaws.com/Addison+/Addison+Emojis+png/AddisonEmoji_neutral.png" />
           NEUTRAL
          </p>
        </div>

        <div
          data-testid="sad-button"
          onClick={() => sendToSumerian('tired')}
          className="moodChart-tired"
          role="button"
        >
          <p>
            <img alt="tired emoji" className="moodChart-img" src="https://addison-project-anim-files.s3.amazonaws.com/Addison+/Addison+Emojis+png/AddisonEmoji_tired.png" />
           TIRED
          </p>
        </div>

        <div
          onClick={() => sendToSumerian('angry')}
          className="moodChart-angry"
          role="button"
        >
          <p>
            <img alt="angry emoji" className="moodChart-img" src="https://addison-project-anim-files.s3.amazonaws.com/Addison+/Addison+Emojis+png/AddisonEmoji_angry.png" />
          ANGRY
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default MoodChart;
