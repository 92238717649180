/**
 * 		SceneHandler - Scene loading utilities for addisoncare
 * 		@author {jkeys}
 * 		@date 8-11-20
 **/

import * as BABYLON from 'babylonjs'; //hack for Host system (would prefer ES6)
import 'babylonjs-loaders'; //GLTF and GLB loaders
import { Logger, Hub } from 'aws-amplify';
import store from '../../_GlobalStateStore/GlobalStateStore';
// import { loadConfigFromS3 } from '../../js/loadConfigFromS3'; //TODO: refactor this to use the new scene config format and naming convention
import {
  loadCharacter,
  createHost,
  setupLightsAndShadows,
  initLightmaps,
  initMaterial,
  initParticle
} from '../../js';
import { SSMLObject } from '../AssetsScene/SSMLListener';
import { HomeSceneElements } from '../../elements';
import { BehaviorManager } from '../../behaviors';

const logger = new Logger('SceneHandler');
window.loadedScenes = {};

/**
 * handle transitions between scenes (typically emitted by an SSML event)
 */
export class SceneHandler {
  sceneConfigs = [];

  /**
   * set the singleton member to the correct config file, and returns the config file 
   * @param {string} languageCode 'en-US', 'de-AT', etc 
   */
  static selectConfig(languageCode) {
    switch (languageCode) {
      case 'en-US':
      case 'en-AU':
      case 'en-GB':
        this.sceneConfigs = require('./babylonSceneConfigs.json');
        break;
      case 'de-DE':
      case 'de-AT':
        this.sceneConfigs = require('./babylonSceneConfigs_de.json');
    }

    return this.sceneConfigs;
  }

  /**
   * get the config file by provided name
   * @param {string} name
   */
  static getConfigByName(name) {
    //TODO: replace this with reference to store.getState().sceneConfigs
    return this.sceneConfigs.find(val => val.name === name);
  }

  /**
   * load the assets specified in an AddisonCare (Babylon) configuration file
   * @param {object} sceneConfig
   */
  static async loadSceneAssets(sceneConfig) {
    //display the splash screen
    const scenesToLoadBefore = store.getState().scenesToLoad;
    if (scenesToLoadBefore === 0) {
      store.getActions().setDisplayLoadingScreen(true);
    }
    //keep a semaphore, so we don't stop displaying the loading screen until the last actively loading scene finishes
    store.getActions().setScenesToLoad(scenesToLoadBefore + 1);
    const scene = window._scene;
    let host;

    const prettyName = sceneConfig.name;
    window.loadedScenes[prettyName] = {
      containers: [],
      particles: undefined,
    };
    // logger.debug('loading scene assets for sceneConfig: ', sceneConfig);

    for (const file of sceneConfig.files) {
      const {
        rootUrl,
        materials,
        scaling,
        position,
        rotation,
        lights,
        lightmaps,
        name,
        enabled,
        meshesOnly,
        role,
        transformOrder,
        rotationOrder,
        backFaceCulling,
        f0factor,
        boundingBox,
        boxFrontColor,
        tabletHover,
        hoverSpeed,
        hoverHeight
      } = file;

      if (role !== 'character') {
        try {
          // const result = await BABYLON.SceneLoader.AppendAsync(rootUrl, undefined, window._scene);
          const result = await BABYLON.SceneLoader.LoadAssetContainerAsync(
            rootUrl,
            undefined,
            scene
          );
          // logger.debug(result);

          const rootNode = new BABYLON.TransformNode(name, scene);
          const rootMesh = result.createRootMesh();
          rootMesh.name = name + 'Transform';
          rootMesh.setParent(rootNode);

          if (meshesOnly && meshesOnly === true) {
            result.materials.forEach(node => node.dispose());
            result.textures.forEach(node => node.dispose());
            result.lights.forEach(node => node.dispose());
          }

          //backface culling
          if (backFaceCulling && Array.isArray(backFaceCulling) && backFaceCulling.length !== 0) {
            for (const matName of backFaceCulling) {
              //const mat = scene.getMaterialByName(matName)
              const mat = result.materials.find(
                value => value.id === matName || value.name === matName
              );
              if (mat) mat.backFaceCulling = true;
            }
          }

          //f0 factor
          if (f0factor && Array.isArray(f0factor) && f0factor.length !== 0) {
            for (const matName of f0factor) {
              const mat = result.materials.find(
                value => value.id === matName || value.name === matName
              );
              if (mat) {
                mat.metallicF0Factor = 0.3;
              }
            }
          }

          //bounding box
          if (boundingBox && Array.isArray(boundingBox) && boundingBox.length !== 0) {
            for (const meshName of boundingBox) {
              const mesh = result.meshes.find(
                value => value.id === meshName || value.name === meshName
              );
              if (mesh) {
                mesh.showBoundingBox = true;
                scene.getBoundingBoxRenderer().showBackLines = false;
                const [x, y, z] = boxFrontColor;
                scene.getBoundingBoxRenderer().frontColor = new BABYLON.Color3(x, y, z);
              }
            }
          }

          //tablet hover
          if (tabletHover && tabletHover !== false) {
            var time = 0;
            scene.registerBeforeRender(function () {
              const tabletMesh = scene.getMeshByName("TabletSoloRootTransform");
              if (tabletMesh) tabletMesh.position.z = (Math.sin(time / hoverSpeed) / hoverHeight) / 2
              time += 0.1;
            });
          }

          //special materials
          if (materials) {
            const _matPromises = materials.map(mat => {
              const hdr = mat.hdrTexture === true ? window._hdrTexture : undefined;
              return initMaterial(result, mat, hdr);
            })

            await Promise.all(_matPromises);

            // for (const material of materials) {
            //   const hdr = material.hdrTexture === true ? window._hdrTexture : undefined;
            //   initMaterial(result, material, hdr);
            // }
          }

          //dereference the position and rotation
          const [xPos, yPos, zPos] = position;
          const [xRot, yRot, zRot] = rotation;
          const [xSca, ySca, zSca] = scaling;

          //apply the transforms in the prescribed order
          for (const type of transformOrder) {
            switch (type.toUpperCase()) {
              case 'POSITION':
                rootNode.position.x = typeof xPos === 'number' ? xPos : 0;
                rootNode.position.y = typeof yPos === 'number' ? yPos : 0;
                rootNode.position.z = typeof zPos === 'number' ? zPos : 0;

                break;
              case 'ROTATION':
                for (const rotAxis of rotationOrder) {
                  const lowerAxis = rotAxis.toLowerCase();
                  const selectedRotation =
                    lowerAxis === 'x' ? xRot : lowerAxis === 'y' ? yRot : zRot;
                  rootNode.rotation[lowerAxis] = selectedRotation;
                }
                break;
              case 'SCALING':
                rootNode.scaling.x = typeof xSca === 'number' ? xSca : 1;
                rootNode.scaling.y = typeof ySca === 'number' ? ySca : 1;
                rootNode.scaling.z = typeof zSca === 'number' ? zSca : 1;
                break;
              default:
                logger.warn('invalid type specified in transform order');
            }
          }

          //add lights and shadows
          lights &&
            lights.forEach(lightConfig => {
              setupLightsAndShadows(scene, lightConfig);
            }); //end lights processing

          lightmaps && initLightmaps(result, lightmaps);

          for (const mesh of result.meshes) {
            mesh.receiveShadows = true;
          }

          for (const mat of result.materials) {
            mat.maxSimultaneousLights = 10;
          }

          result.scene = scene;
          if (enabled === true) {
            result.addAllToScene();
          }
          //store a reference to the asset container, to make switching environments simpler
          window.loadedScenes[prettyName].containers.push(result);
        } catch (e) {
          // logger.debug('error loading env: ', e);
        }
      } else if (role === 'character') {
        // logger.debug('loading character and animations for: ', sceneConfig);
        //load character and create host if its character role

        const { animationConfig, pollyConfig } = file;
        const {
          gestureConfigFile,
          poiConfigFile,
          animationPath,
          animationFiles,
          audioAttachJoint,
          lookJoint,
        } = animationConfig;
        const { voice, voiceEngine } = pollyConfig;

        //add lights and shadows

        //assume character file only has a single light
        const { shadowGenerator } = setupLightsAndShadows(scene, lights[0]);

        const { character, clips, bindPoseOffset } = await loadCharacter(
          scene,
          rootUrl,
          animationPath,
          animationFiles,
          shadowGenerator,
          materials
        );

        store.getActions().setCharacter(character);

        // Read the gesture config file. This file contains options for splitting up
        // each animation in gestures.glb into 3 sub-animations and initializing them
        // as a QueueState animation.

        const [gestureConfig, poiConfig] = await Promise.all([
          fetch(`${animationPath}/${gestureConfigFile}`),
          fetch(`${animationPath}/${poiConfigFile}`)
        ]).then((values) => {
          return Promise.all([values[0].json(), values[1].json()]);
        });

        console.log("gestureConfig: ", gestureConfig);
        console.log("poiConfig: ", poiConfig);

        // const gestureConfig = await fetch(`${animationPath}/${gestureConfigFile}`).then(response =>
        //   response.json()
        // );

        const children = character.getDescendants(false);
        const audioAttach = children.find(child => child.name === audioAttachJoint);
        const lookTracker = children.find(child => child.name === lookJoint);

        window._audioAttach = audioAttach;

        // const poiConfig = await fetch(`${animationPath}/${poiConfigFile}`).then(response =>
        //   response.json()
        // );

        store.getActions().setPoiConfig(poiConfig);
        store.getActions().setGestureConfig(gestureConfig);

        const [
          idleClips,
          lipsyncClips,
          gestureClips,
          emoteClips,
          faceClips,
          blinkClips,
          poiClips,
        ] = clips;

        host = await createHost(
          character,
          audioAttach,
          voice,
          voiceEngine,
          idleClips[0],
          faceClips[0],
          lipsyncClips,
          gestureClips,
          gestureConfig,
          emoteClips,
          blinkClips,
          poiClips,
          poiConfig,
          lookTracker,
          bindPoseOffset,
          scene,
          window._defaultCamera
        );
        //store the host in redux
        store.getActions().setHost(host);
        window._addisonHost = host;

        scene.onBeforeAnimationsObservable.add(() => {
          host.update();
        });

        const hostNode = new BABYLON.TransformNode('HostDummyNode', scene);
        hostNode.metadata = { host };

        //setup ssml and the home scene
        SSMLObject.setup();
        HomeSceneElements.setup();

        scene.getMeshByName('AddisonRoot').alwaysSelectAsActiveMesh = true;
        scene.getMeshByName('Brows_GEO').alwaysSelectAsActiveMesh = true;
        scene.getMeshByName('Clothes_GEO').alwaysSelectAsActiveMesh = true;
        scene.getMeshByName('Hair_GEO').alwaysSelectAsActiveMesh = true;
        scene.getMeshByName('Eye_Inner_GEO').alwaysSelectAsActiveMesh = true;
        scene.getMeshByName('Lashes_GEO').alwaysSelectAsActiveMesh = true;
        scene.getMeshByName('Skin_GEO').alwaysSelectAsActiveMesh = true;
        scene.getMeshByName('Pendant_GEO').alwaysSelectAsActiveMesh = true;

        store.getActions().setBehaviorManager(new BehaviorManager());
      } //end of character

      if (role === 'environment') {
        const { skybox, postprocess, ambientOcclusion, particles } = sceneConfig;

        //TODO: fix the config to either reference as an object instead of array, 
        //or fix logic here to respect it being an array.
        if (particles) {
          // logger.debug('particles from config: ', particles);
          await initParticle(particles[0], scene);
          window.loadedScenes[prettyName].particles = particles[0];
          // for (const particleSys of particles) {
          //   await initParticle(particleSys, scene);
          //   window.loadedScenes[prettyName].particles.push(particleSys);
          // }
        }


        if (postprocess) {
          const postProcess = new BABYLON.ImageProcessingPostProcess(
            postprocess.name,
            1.0,
            undefined,
            undefined,
            window._engine
          );
          if (postprocess.vignetteEnabled && postprocess.vignetteEnabled === true) {
            postProcess.vignetteWeight = postprocess.vignetteWeight;
            const [x, y, z] = postprocess.vignetteColor;
            postProcess.vignetteColor = new BABYLON.Color3(x, y, z);
            postProcess.vignetteEnabled = postprocess.vignetteEnabled;
          }
          if (postprocess.contrast && postprocess.contrast !== 1) {
            postProcess.contrast = postprocess.contrast;
          }
        }
      } //end of environment
    }

    const scenesToLoadAfter = store.getState().scenesToLoad - 1;
    store.getActions().setScenesToLoad(scenesToLoadAfter);
    if (scenesToLoadAfter === 0) {
      //TODO: fix the pop-in; in the meantime hide it by delaying the hide
      setTimeout(() => {
        // logger.debug('hiding splash screen');
        Hub.dispatch('hideSplashScreen');
      }, 1500);
    }

    if (sceneConfig.type === 'character_rig') return host;
    return Promise.resolve();
  }

  /**
   * shows a cached environment
   * @param {string} name - string like "Home" or "Addison" to load a specific root node
   */
  static showEnvironment(name) {
    Hub.dispatch('fadeInSplash');

    const FADE_TIME = 5000;
    // const JITTER = 100;

    if (!name) {
      logger.warn('no name passed into showEnvironment');
      return;
    } else if (!SceneHandler.isSceneCachedByName(name)) {
      logger.warn('trying to show an environment that does not exist: ', name);
      return;
    }

    // logger.debug('store.getState().currentParticleSystems: ', store.getState().currentParticleSystems);

    if (store.getState().currentParticleSystems) {
      for (const sys of store.getState().currentParticleSystems) {
        sys.dispose();
      }
    }

    console.log("window.loadedScenes[name]: ", window.loadedScenes[name]);

    //if particle system is associated with the loaded scene, call initParticle 
    if (window.loadedScenes[name].particles) {
      // for (const config of window.loadedScenes[name].particles) {
      initParticle(window.loadedScenes[name].particles, window._scene);
      // }
    }

    //Create the topbar at the beginning of the scene.
    switch (store.getState().primaryLanguage) {
      case "en":
      case "en-US":
      case "en-GB":
      case "en-AU":
        store.getActions().showGroupFunc("topBar");
        store.getActions().showGroupFunc("addisonHome");
        break
      case "de":
      case "de-AT":
      case "de-DE":
        store.getActions().showGroupFunc("topBarDE");
        store.getActions().showGroupFunc("addisonHomeDE");
        break
      default:
        console.warn("Invalid language supplied for topBar and Home button");
    }

    //and re-enable the cached scene
    setTimeout(() => {
      //hide all cached scenes
      SceneHandler.unloadAllScenes();
      SceneHandler.enableCachedSceneByName(name);
    }, FADE_TIME / 2);

    setTimeout(() => {
      Hub.dispatch('fadeOutSplash');
    }, FADE_TIME);
  }

  /**
   * load scene from cache (window.loadedScenes[name]) if it exists, or loads it over network if it doesn't
   * @param {string} name - string like "Home" or "Addison" to load a specific root node
   */
  static async loadSceneByName(name) {
    // logger.debug('window.loadedScenes: ', window.loadedScenes);

    //don't transition if this is the current scene
    if (store.getState().currentSceneName === name) {
      // logger.debug('scene already loaded, not transitioning');
      return;
    }
    store.getActions().setCurrentSceneName(name);

    //if scene already loaded, just showEnvironment;
    if (window.loadedScenes[name]) {
      return SceneHandler.showEnvironment(name);
    }

    let selected = this.getConfigByName(name);

    if (!selected) {
      logger.warn('no scene found');
      return;
    }

    // logger.debug('selected config: ', selected);
    return SceneHandler.loadSceneAssets(selected);
  }

  /**
   * load scene from cache
   * @param {string} name - string like "Home", "Vitals" or "Addison"
   */
  static enableCachedSceneByName(name) {
    if (!window.loadedScenes[name]) {
      logger.warn('cannot enable cached scene that does not exist: ', name);
      return;
    }

    console.log("store.getState().currentParticleSystems: ", store.getState().currentParticleSystems);

    store.getActions().setCurrentParticleNames([]);

    store.getState().currentParticleSystems.forEach(sys => sys.dispose());

    store.getActions().setCurrentParticleSystems([]);

    window.loadedScenes[name].containers.forEach(container => container.addAllToScene());
    // window.loadedScenes[name].particles.forEach(config => initParticle(config, window._scene));

    if (window.loadedScenes[name].particles) {
      initParticle(window.loadedScenes[name].particles, window._scene);
    }

    // if (name === 'Vitals') {
    //   const decorSmall = window._scene.getMaterialByName('DecorSmall_MAT');
    //   if (decorSmall != null) decorSmall.vScale = -1;
    // }
  }

  /**
   * determine if the scene is cached
   * @param {string} name - string like "Home", "Vitals" or "Addison"
   */
  static isSceneCachedByName(name) {
    return window.loadedScenes[name] ? true : false;
  }

  /**
   * remove cached scene (if it exists)
   * @param {string} name - string like "Home" or "Addison" to load a specific root node
   */
  static unloadSceneByName(name) {
    const loadedScene = window.loadedScenes[name];
    if (loadedScene) {
      loadedScene.containers.forEach(container => container.removeAllFromScene());
    }
  }

  /**
   * remove (disable in Babylon) all cached scene
   */
  static unloadAllScenes() {
    for (const key in window.loadedScenes) {
      //do not unload Addison or the Tablet
      if(key === 'Addison') continue;
      const containers = window.loadedScenes[key].containers;
      containers.forEach(container => container.removeAllFromScene());
    }
  }

  static stopAllParticles() {
    store.getState().currentParticleSystems.forEach(sys => sys.dispose());
  }

  static async loadParticlesByName(name) {
    if (window.loadedScenes[name]) {
      for (const config of window.loadedScenes[name].particles) {
        await initParticle(config, window._scene);
      }
    }
  }
}
