const typeToPretty = {
  systolic: 'Systolic',
  diastolic: 'Diastolic',
  bloodpressure: 'Systolic / Diastolic',
  glucose: 'Glucose',
  heartRate: 'Beats per Minute',
  pulseox: 'Oxygen levels / BPM',
  oxygen: 'Oxygen',
  weight: 'Weight',
  temperature: 'Temperature',
  thermometer: 'Temperature',
  'blood pressure': 'Blood Pressure',
  'oxygen levels': 'Pulse Oximeter',
};

export default typeToPretty;
