/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/jsx-no-comment-textnodes */

/* eslint-disable func-call-spacing */

/** CameraViewer.js
 * sep 2020
 * @author Luis Mendoza
 */

import React, { useEffect, useState } from 'react';
// eslint-disable-next-line no-unused-vars
import * as tf from '@tensorflow/tfjs';
import { Hub } from 'aws-amplify';
import * as posenet from '@tensorflow-models/posenet';
import storeActivity from '../../js/storeActivity';
import { timeActivity, getCurrentTime } from '../../js/timeConverter';

let currentTime;

/**
 * CameraViewer component to show camera into addison home scene.
 */
const CameraViewer = async () => {
  const [net, setNet] = useState();


  useEffect(() => {
    const _eff = async () => {
      // eslint-disable-next-line no-constant-condition
      const net = await posenet.load({ scale: 0.8 });
      setNet(net);
    };

    _eff();
  }, []);

  function success(stream) {
    const video = document.getElementById('video');
    video.srcObject = stream;
    setInterval(() => {
      currentTime = getCurrentTime();
      // eslint-disable-next-line max-len
      if (currentTime < timeActivity.endTime && currentTime >= timeActivity.startTime) {
        // eslint-disable-next-line func-names
        video.onprogress = function () {
          // eslint-disable-next-line no-console
          // console.log('video has started');

          // eslint-disable-next-line no-use-before-define
          estimatePoseOnVideo(video);

          // Api goes here.
        };
      }
    }, 1000);
  }

  // eslint-disable-next-line consistent-return
  async function estimatePoseOnVideo(videoStream) {
    // eslint-disable-next-line no-console
    const pose = await net.estimateSinglePose(videoStream);
    // eslint-disable-next-line max-len
    if (currentTime < timeActivity.endTime && currentTime >= timeActivity.startTime) {
      // load the posenet model from a checkpoint
      // console.log('Pose score', pose.score);
      if (pose.score > 0.6) {
        console.log('!!!! pose detected', pose);
        Hub.dispatch('playSpeech', { data: 'I detected your activity' });
        storeActivity();
        // eslint-disable-next-line no-use-before-define
        timeActivity.endTime = currentTime;
      }
      return pose;
    }
  }

  useEffect(() => {
    if (!net) return;

    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(success)
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, [net, success]);

  return (
    <>
      // eslint-disable-next-line jsx-a11y/media-has-caption // eslint-disable-next-line
      jsx-a11y/media-has-caption
      <video autoPlay id="video" />
    </>
  );
};
export { CameraViewer };
