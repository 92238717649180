const toFahrenheit = kelvin => Math.round((kelvin - 273) * (9 / 5) + 32);
const toCelsius = kelvin => Math.round(kelvin - 273);

export const getWeather = results => results && results.data;
export const getCondition = weatherResults =>
  weatherResults &&
  weatherResults.weather &&
  weatherResults.weather[0] &&
  weatherResults.weather[0].main;
  


    let getTemperatureF = weatherResults => weatherResults
    && weatherResults.main
    && weatherResults.main.temp
    && toFahrenheit(weatherResults.main.temp);
   
     
    let getTemperatureC = weatherResults => weatherResults
    && weatherResults.main
    && weatherResults.main.temp
    && toCelsius(weatherResults.main.temp);
    
  
export {getTemperatureF};
export {getTemperatureC};

