import React from 'react';
import { ScatterChart, XAxis, YAxis, Tooltip, Scatter, ResponsiveContainer, Dot, ReferenceArea, CartesianGrid } from 'recharts';
import moment from 'moment';
import { Logger } from 'aws-amplify';

import { calculateDomain, normalRanges, typeToPretty, typeToUnit } from '../defaults';
// import { NoVitalsToDisplay } from './Placeholders.jsx';
import CustomTooltip from '../components/CustomTooltip';

import './_VitalsScatterChart.scss';

const logger = new Logger('VitalsScatterChart');



const VitalsScatterChart = ({
    data, // patient data
    type, // vital type
}) => {
    if (!type || !data) {
        logger.debug("condition failed, type: ", type, " data: ", data);
        return (
            <p className="placeholderStyle">Loading Vitals Chart</p>
        )
    } 


    const domain = calculateDomain(data[type], (normalRanges[type].high - normalRanges[type].low) * .5);

    return (
        <div className='index__container'>
      <div className='index__window'>
        <h1 className='index__window-header'>{typeToPretty[type]} / Time</h1>
        <div className='index__window-content'>
          <div className='index__window-left-column'>
            <div className='index__window-legend'>
              <h1 className='index__window-header'>Legend</h1>
              <div className='index__window-legend-line'>
                <div className='index__window-legend-dot' />
                <p className='index__window-legend-label'>Data</p>
              </div>

              <div className='index__window-legend-line'>
                <div className='index__window-legend-dot index__windows-legend-dot--alt-1' />
                <p className='index__window-legend-label'>Average</p>
              </div>

              <div className='index__window-legend-line'>
                <div className='index__window-legend-dot index__windows-legend-dot--alt-2' />
                <p className='index__window-legend-label'>Abnormal</p>
              </div>
            </div>
          </div>
          <div className='vitals-1atter-chart__container'>
            <ResponsiveContainer width='100%' height='100%'>
              <ScatterChart>
               <CartesianGrid strokeDasharray="10 10" />
                <XAxis 
                  type="number" 
                  dataKey='timestamp'
                  tickFormatter={(data) => moment(data).format('M/D')}
                  name="time"  
                  axisLine={false} 
                  tickLine={false} 
                  stroke='white' 
                  tickMargin={10}
                  domain={[domain.dataMinX, domain.dataMaxX]}
                />

                <YAxis 
                  type="number"
                  dataKey='value' 
                  name={type} 
                  axisLine={false} 
                  tickLine={false} 
                  stroke='white' 
                  tickMargin={10} 
                  domain={[domain.dataMinY, domain.dataMaxY]}
                  scale="linear"
                />;
                

                {/* Average Box */}
                (<ReferenceArea 
                  x1={domain.dataMinX} 
                  x2={domain.dataMaxX} 
                  y1={domain.dataMinY} 
                  y2={domain.dataMaxY} 
                  fillOpacity={.1} 
                  strokeWidth={5} 
                  fill='white' 
                  stroke='#009245' 
                />) 
                <Tooltip 
                  cursor={false} 
                  coordinate={{ x: 300, y: 0}} 
                  content={<CustomTooltip unit={typeToUnit[type]} />}
                  active={true}
                />

                  <Scatter 
                    name={typeToPretty[type]}
                    data={data[type]}  
                    shape={(props) =>
                      <Dot 
                        className='vitals-scatter-chart__dot' 
                        fill={
                          props.value < normalRanges[type].low || props.value > normalRanges[type].high ? '#ef5656' : 'white'
                        }
                        r={10} 
                        {...props} 
                      />
                    }
                  />
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
    )
};




export default VitalsScatterChart;
