import { I18n } from "aws-amplify";
import store from '../../_GlobalStateStore/GlobalStateStore';

const thresholdResponses = [
  { key: 'above', value: "This is above threshold" },
  { key: 'below', value: "This is below threshold" },
  { key: 'within', value: "This is within threshold" }
];

export const vitalsInterpretation = function (typeValue, typeThreshold) {

  if (typeThreshold) {
    if (typeValue > typeThreshold.maximum) {
      return thresholdResponses[0];
    }
    if (typeValue < typeThreshold.minimum) {
      return thresholdResponses[1];
    } else { return thresholdResponses[2]; }

  } else {
    return 0;
  }
} //vitalsInterpretation


const vitalsRandom = function (key) {
  let responseArray = {
    above: [
      "Let's work on lowering that."
    ],
    below: [
      "Let's work on raising that."
    ],
    within: [
      "Hakuna Matata!",
      "Looks good!",
      "Keep doing what you're doing!"
    ]
  }
  // returning an empty string would lead to an error
  if (!responseArray[key])
    return "I'm not sure what to think of that."; 
  return responseArray[key][Math.floor(Math.random() * responseArray[key].length)];
} // vitalsRandom

export const vitalsAttributes = function(pData, tData, type) {
  let attributes = {
    "vitalType": type,
    "vitalValue": "",
    "vitalReading": "...",
    "vitalRandom": "",
  };
  let typeData;
  let typeValue;
  let vitalInterpret;
  switch (type) {
    case 'oxygen':
    case 'oxygen levels':
      typeData = pData['oxygen'];
      if(!typeData || typeData.length === 0) {
        break;
      }
      typeValue = parseFloat(typeData[typeData.length - 1].value);
      attributes["vitalReading"] = `${typeValue} ${I18n.get('percent')}`;
      attributes.vitalValue = {
        oxygen: typeValue
      };
      vitalInterpret = vitalsInterpretation(typeValue, tData['Oxygen']);

      typeData = pData['heartRate'];
      if(!typeData || typeData.length === 0) {
        break;
      }
      typeValue = parseFloat(typeData[typeData.length - 1].value);
      attributes["vitalReading"] += `, ${I18n.get('at')} ${typeValue} ${I18n.get('Beats per Minute')}`;
      attributes.vitalValue = {
        ...attributes.vitalValue,
        bpm: typeValue
      };
      if (vitalInterpret.key === 'within') {
          vitalInterpret = vitalsInterpretation(typeValue, tData['BPM']);
      }
      break;
    case 'blood pressure':
      let typeDataD = pData['diastolic'];
      let typeDataS = pData['systolic'];
      if(!typeDataD || !typeDataS || typeDataD.length === 0 || typeDataS.length === 0) {
        break;
      }
      let typeValueD = parseFloat(typeDataD[typeDataD.length - 1].value);
      let typeValueS = parseFloat(typeDataS[typeDataS.length - 1].value);
      attributes["vitalReading"] = `${typeValueS} ${I18n.get('over')} ${typeValueD}`;
      
      attributes.vitalValue = {
        systolic: typeValueS,
        diastolic: typeValueD,
      }
      vitalInterpret = vitalsInterpretation(typeValueS, tData['Systolic']);
      if (vitalInterpret.key === 'within') {
        vitalInterpret = vitalsInterpretation(typeValueD, tData['Diastolic']);
      }
      break;
    case 'temperature':
        typeData = pData['temperature'];
        if(!typeData || typeData.length === 0) {
          break;
        }
        
        typeValue = parseFloat(typeData[typeData.length - 1].value);
        //jkeys 11-19-20 -- only Americans use fahrenheit, so assume celsius for everyone else
        //[[LOCALIZATION REQUIRED]]
        if(store.getState().primaryLanguage === 'en' || store.getState().primaryLanguage.includes('en-')) {
          attributes["vitalReading"] = `${typeValue} degrees fahrenheit`;
        } else {
          typeValue = (typeValue - 32.0) * 5.0 / 9.0; // kgs = lbs / 2.205
          attributes['vitalReading'] = `${typeValue} ${I18n.get('Degree')} ${I18n.get('Celsius')}`;
        }

        attributes.vitalValue = typeValue;
        vitalInterpret = vitalsInterpretation(typeValue, tData['Thermometer']);
        break;
      case 'weight':
        typeData = pData['weight'];
        if(!typeData || typeData.length === 0) {
          break;
        }
        typeValue = parseFloat(typeData[typeData.length - 1].value);
        attributes.vitalValue = typeValue;

        //jkeys 11-19-20 -- only Americans use pounds, so assume kilograms for everyone else
        //[[LOCALIZATION REQUIRED]]
        if(store.getState().primaryLanguage === 'en' || store.getState().primaryLanguage.includes('en-')) {
          attributes["vitalReading"] = `${typeValue} pounds`;
        } else {
          typeValue = typeValue / 2.205; // kgs = lbs / 2.205
          attributes['vitalReading'] = `${typeValue} kilograms`;
        }

        vitalInterpret = vitalsInterpretation(typeValue, tData['Weight']);
        break;
      case 'glucose':
        typeData = pData['glucose'];
        if(!typeData || typeData.length === 0) {
          break;
        }
        typeValue = parseFloat(typeData[typeData.length - 1].value);
        attributes["vitalReading"] = `${typeValue} mg/dL`;
        attributes.vitalValue = typeValue;
        vitalInterpret = vitalsInterpretation(typeValue, tData['Weight']);
        break;
    default:

      break;
  }// end switch
  if (! vitalInterpret){
    attributes["vitalInterpret"] = "I'm having trouble interpreting the result.";
    attributes["vitalRandom"] = "Please refer to the values shown on the display.";
  }else {
    attributes["vitalInterpret"] = vitalInterpret.value;
    attributes["vitalRandom"] = vitalsRandom(vitalInterpret.key);
  }
  return attributes;
}