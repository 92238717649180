//AppSplashScreen.jsx

import React, { Component } from 'react';
import { Hub, Logger } from 'aws-amplify';
import store from '../../../src/_GlobalStateStore/GlobalStateStore';

const logger = new Logger('Elm_FullscreenVideo');

export default class Elm_FullscreenVideo extends Component {
  constructor(props) {
    super(props);
    store.getActions().eventManager.emit({ channel: 'StopSpeechRec' });
    this.state = {
      on: true,
      url: this.props.url,
    };
    //this.setState({url : this.props.url});
    logger.debug('in App constructor, this.state:', this.state);
  }

  hideSelf(event) {
    event.preventDefault();
    store.getActions().eventManager.emit({ channel: 'LoadingComplete' });
    store.getActions().eventManager.emit({ channel: 'StartSpeechRec' });

    //this.setState({on:false});
    logger.debug('Got Here');
    let payload = {};
    payload.elements = [];
    payload.elements.push({ type: 'fullscreenVideo' });

    Hub.dispatch('hideAmplifyElement', payload);
  }

  render() {
    // let on = true;
    //        <video style={{zIndex: "100000"}} id={"splash-container"} onEnded={(event) => {event.preventDefault(); this.setState({on:false});}} autoPlay muted>

    return (
      <div data-testid="elm-fullscreen-video-testid" className={this.state.on ? 'Video-Container' : 'hidden'}>
        <video
          style={{ zIndex: '100000' }}
          id={'splash-container'}
          onEnded={event => this.hideSelf(event)}
          autoPlay
        >
          <source src={this.state.url} type={'video/mp4'} />
        </video>
      </div>
    );
  }
}
