import store from '../../_GlobalStateStore/GlobalStateStore';

export function setCameraPosition(capsule) {
    const camera = store.getState().camera;
    const { payload } = capsule;

    const { x, y, z } = payload.data;

    camera.position.x = typeof x === 'number' ? x : camera.position.x;
    camera.position.y = typeof y === 'number' ? y : camera.position.y;
    camera.position.z = typeof x === 'number' ? z : camera.position.z;
}