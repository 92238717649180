const typeToUnit = {
  temperature: '°F',
  thermometer: 'Temperature',
  heartRate: 'BPM',
  pulseox: '%',
  'oxygen levels': '%',
  systolic: '',
  diastolic: ' mmHg',
  oxygen: '%',
  weight: 'lbs',
  glucose: 'mg/dL', 
};

export default typeToUnit;
