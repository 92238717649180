import * as BABYLON from 'babylonjs';
import {Logger} from 'aws-amplify';
import store from '../_GlobalStateStore/GlobalStateStore';

/**
 * Takes the defined config object and creates (or references) a light in the scene, and setups shadows and other options
 * @param {BABYLON.Scene} scene 
 * @param {object} lightConfig 
 */
export function setupLightsAndShadows(scene, lightConfig) {
  const logger = new Logger('LightsAndShadows');
  const {
    type,
    name,
    enabled,
    filter,
    filterQuality,
    create,
    bias,
    normalBias,
    darkness,
    position,
    direction,
    intensity,
    shadows,
    useBlurShadowmap,
    blurKernel,
    diffuse,
    groundColor,
    specular,
    autoCalcShadows
  } = lightConfig;

  //dereference the position and rotation
  const [xPosLight, yPosLight, zPosLight] = position;
  const [xDirLight, yDirLight, zDirLight] = direction;

  const posVec = new BABYLON.Vector3(xPosLight, yPosLight, zPosLight);
  const dirVec = new BABYLON.Vector3(xDirLight, yDirLight, zDirLight);

  //TODO: add support for light range
  const DEFAULT_RANGE = 100;

  //if create is specified, do not create a new light; just look up the existing one by ID (name)
  let newLight;
  switch (type.toUpperCase()) {
    case 'POINT':
      newLight = create ? new BABYLON.PointLight(name, posVec, scene) : scene.getNodeByName(name);
      newLight.range = DEFAULT_RANGE;
      break;
    case 'DIRECTIONAL':
      newLight = create
        ? new BABYLON.DirectionalLight(name, dirVec, scene)
        : scene.getNodeByName(name);
      break;
    case 'SPOT':
      newLight = create ? new BABYLON.SpotLight(name, posVec, scene) : scene.getNodeByName(name);
      newLight.range = DEFAULT_RANGE;
      break;
    case 'HEMISPHERIC':
      newLight = create
        ? new BABYLON.HemisphericLight(name, dirVec, scene)
        : scene.getNodeByName(name);
      break;
    default:
      logger.warn('invalid or unsupported light type specified on config');
      return;
  }

  /*** set common properties ***/

  //set intensity and other properties
  newLight.intensity = intensity;

  newLight.setEnabled(enabled);

  if (diffuse) {
    const [difX, difY, difZ] = diffuse;
    newLight.diffuse = new BABYLON.Color3(difX, difY, difZ);
  }
  if (groundColor) {
    const [difX, difY, difZ] = groundColor;
    newLight.groundColor = new BABYLON.Color3(difX, difY, difZ);
  }
  if (specular) {
    const [specX, specY, specZ] = specular;
    newLight.specular = new BABYLON.Color3(specX, specY, specZ);
  }

  if(darkness) {
    newLight.darkness = darkness;
  }

  /*** setup shadows ***/
  let shadowGen;
  if (shadows === true) {
    const shadowGenerator = new BABYLON.ShadowGenerator(1024, newLight);
    shadowGenerator.useBlurExponentialShadowMap = useBlurShadowmap;
    shadowGenerator.blurKernel = blurKernel;
    store.getActions().addShadowGenerator(shadowGenerator);
    shadowGen = shadowGenerator;
    newLight.autoCalcShadowZBounds = autoCalcShadows

    shadowGenerator.usePoissonSampling = true;
    if (normalBias && typeof normalBias === 'number') shadowGenerator.normalBias = normalBias;
    if (bias && typeof bias === 'number') shadowGenerator.bias = bias;
    if (filter && typeof filter === 'string') shadowGenerator.filter = filter;
    if (filterQuality && typeof filterQuality === 'number')
      shadowGenerator.filterQuality = filterQuality;

    for (const mesh of scene.meshes) {
      mesh.receiveShadows = true;
    }

    shadowGenerator.getShadowMap().renderList = scene.meshes;
  }

  return {shadowGenerator: shadowGen, light: newLight};
}
