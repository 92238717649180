import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';
import HOST from 'amazon-sumerian-hosts/dist/host.babylon.js'; //Sumerian host system

export async function initPolly() {
    const creds = await Auth.currentSession();
    const { accessKeyId, secretAccessKey, sessionToken } = creds;

    const polly = new AWS.Polly({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region: window.ADDISON_REGION
    });
    const presigner = new AWS.Polly.Presigner({
      accessKeyId,
      secretAccessKey,
      sessionToken,
      region: window.ADDISON_REGION
    });
  
    // const HOST = await import('amazon-sumerian-hosts/dist/host.babylon.js');
    await HOST.aws.TextToSpeechFeature.initializeService(polly, presigner, window.AWS.VERSION);   
  }
  