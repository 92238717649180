/**
 * @author {jkeys}
 */

import React, {useState, useEffect} from 'react';
import {displaySentence} from './DisplayText';
import {Hub} from 'aws-amplify';
import parse from 'html-react-parser';

/**
 * renders the captioned chatbot response to the page
 */
const Captions = () => {
  const [text, setText] = useState('');   //local state, this is what will be rendered to the screen

  useEffect(() => {
    const handleSentenceEvent = event => {
      // eslint-disable-next-line max-len
      setText(parse(displaySentence(event.payload.data))); //grab the transformed string, then parse it from HTML into JSX, then set the local state
    };
    Hub.listen('sentenceEvent', handleSentenceEvent);
    return () => { Hub.remove('sentenceEvent', handleSentenceEvent )};
  }, []);

  // if (text[0] > 50) {
  //   setText('Too much words!');
  // }
  console.log('!!! EVENT: ', text);
  return (
    <h1 id="captionGradient">
      <div className="sceneCaptions">{text}</div>
    </h1>
  );
};

export default Captions;
