/**
 *
 *    A component that will display the latest vital result received
 *
 * @author jkeys
 * @date 8-19-2019
 */

import React, { useEffect, useState } from 'react';
import { Hub, Logger, I18n } from 'aws-amplify';

import { typeToPretty, typeToUnit } from '../utils';
import { vitalsAttributes, vitalsInterpretation } from '../vitalsLanguage';
import { thresholdTypeFromDataType } from '../defaults';

import store from '../../../_GlobalStateStore/GlobalStateStore';

import '../_SceneVitals.scss';

const VitalResult = ({
  data,
  type,
  thresholdData,
  setLocalCurrentVitalType,
}) => {
  const logger = new Logger('VitalResult');
  //every time the component mounts, reset the sentEvent flag
  const [sentEvent, setSentEvent] = useState(false);

  useEffect(() => {
    const logger = new Logger('VitalResult');
    if (data) {
      logger.debug('Data In VitalResult', data, type);
      store.getActions().setLatestVitalResult(data);
      //TODO jkeys 11-4-20: debug vitalAttributes
      
      const attributes = vitalsAttributes(data, thresholdData, type);
      store.getActions().setLatestVitalAttributes(attributes);
      // logger.debug('Attributes: ', attributes);
      // Hub.dispatch('updateSessionAttributes', attributes);

      if(!store.getState().isBluetoothReading) {
        // setTimeout(() => Hub.dispatch('post_to_lex', { data: 'vitals result' }), 400);
        setTimeout( () => {
          Hub.dispatch('postChatbotEvent', { data: 'VITALS_RESULT_EVENT'});
        }, 400);
      }

    }
  }, [data, thresholdData, type]);
  if (!data || !type) {
    // return <NoVitalsToDisplay />;
    return null;
  }

  logger.debug('in vitalresult, type: ', type);
  logger.debug('in vitalresult, data: ', data);

  const title = typeToPretty[type];
  const dataType = []; const latestData = []; const
    unit = [];
  let separator = '';

  if (type === 'oxygen levels') {
    dataType.push('oxygen');
    dataType.push('heartRate');
    separator = ' - ';
  } else if (type === 'blood pressure') {
    dataType.push('systolic');
    dataType.push('diastolic');
    separator = '/';
  } else {
    dataType.push(type);
  }
  dataType.forEach((dataTypeValue, dataTypeKey) => {
    latestData.push(data[dataTypeValue][data[dataTypeValue].length - 1]);
    unit.push(typeToUnit[dataTypeValue]);
  });

  if (!latestData || latestData.length === 0 || !latestData[0]) {
    return null;
  }

  logger.debug('title:', title);
  let relativeToThreshold = vitalsInterpretation(latestData[0].value, thresholdData[thresholdTypeFromDataType(dataType[0])]);
  if (latestData[1]) {
    if (relativeToThreshold.key === 'within') {
      relativeToThreshold = vitalsInterpretation(latestData[0].value, thresholdData[thresholdTypeFromDataType(dataType[1])]);
    }
  }
  // TODO: refactor isNormalResult to use user-specified thresholds
  // and, eventually, machine learning predicted thresholds

  // const latestData = {
  //   timestamp: {
  //     value: "2019-12-17"
  //   },
  //   value: "98.5",
  //   unit: '0F'
  // }
  // latestData.value;
  // latestData.timestamp.value;
  // logger.debug('setting showHistory listener in SceneVitals.js');
  Hub.listen('selectVital', async ({ payload }) => {
    const { data } = payload;
    logger.debug('WE MADE IT 2', 'selectVital', data);
    // payload should be a string like "glucose" here;
    // they map to the VitalTypes slot type in the Vitals Scene lex bot config
    if (!data) {
      logger.warn('showHistory event passed with null or undefined payload');
      return;
    }
    if (typeof data !== 'string') {
      logger.warn('showHistory event passed with payload not of type string');
      return;
    }

    setLocalCurrentVitalType(data);
  });
  const retakeVital = () => {
    Hub.dispatch('post_to_lex', { data: 'retake' });
  };
  return (
    <>
      <div className="ContainerResult ContainerGradient VitalResultSize">
        <div className="TitleVital">{title}</div>
        <hr className="LineTitle" />
        <div className="resultValue">
          {latestData[0].value}
          {unit[0]}
          {latestData[1] ? `${separator}${latestData[1].value}${unit[1]}` : ''}
        </div>
        <div className="result-goodbad">
          <div className="result-icon">
            <span style={{ width: '100%' }}>
              {relativeToThreshold.key === 'within'
                ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <title>Icon</title>
                    <path d="M35.63,87.1,18.75,90.6a46.33,46.33,0,0,0-7.46,2A1.38,1.38,0,0,1,9.4,91.32q0-19.36,0-38.72a1.37,1.37,0,0,1,.92-1.3c5.66-1.87,11.93-1.85,17.26-4.53.63-.32,1.4-.67,2-.28a1.9,1.9,0,0,1,.63,1C34.47,59.42,38,71.8,37.86,84.43a2.86,2.86,0,0,1-.44,1.85,3,3,0,0,1-1.79.82" fill="#39B54A" />
                    <path d="M38,83c6.32.64,18.33,2.72,24.62,1.92,2.75-.36,12.71-.58,13.53-.25a8.31,8.31,0,0,0,10.51-9.33,1.07,1.07,0,0,1,.51-1.07A7.18,7.18,0,0,0,88.6,63.12,1,1,0,0,1,88.41,62a9.64,9.64,0,0,0-.48-9.13c-1.26-2,.13-4.59.38-7a7.38,7.38,0,0,0-6.07-7.77c-2.36-.67-6,.92-8.38,1.3-5.11.82-8.56.24-13.54-1.22-2-.59-4.42-2-3.6-4.27A3.59,3.59,0,0,1,58.48,32c6.81-3.68,11.71-11.66,10.14-19.27a7.28,7.28,0,0,0-2.88-4.83c-1.63-1-4.14-.76-5.07.94A6.92,6.92,0,0,0,60.1,11a16.08,16.08,0,0,1-3.79,7.81c-3.44,3.86-8.54,5.86-12.18,9.52C38.67,33.81,35.59,44,29.81,49.11" fill="none" stroke="#39B54A" strokeLinecap="square" strokeMiterlimit="1" strokeWidth="5" />
                  </svg>
                )
                : (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <title>Icon</title>
                    <path d="M91,78.21,55.06,16a5.84,5.84,0,0,0-10.12,0L9,78.21A5.84,5.84,0,0,0,14.05,87H86A5.84,5.84,0,0,0,91,78.21Zm-37.1-2A5.29,5.29,0,0,1,50,77.54a5.43,5.43,0,0,1-3.94-1.35,6,6,0,0,1,0-7.64,5.52,5.52,0,0,1,4-1.3,5.35,5.35,0,0,1,3.89,1.33,5.88,5.88,0,0,1,0,7.58ZM53.75,62H46.37L44.82,32.46H55.3Z" fill="#F7931E" />
                  </svg>
                )}

            </span>
          </div>
          <span className={relativeToThreshold.key === 'within' ? 'resultText resultNormal' : 'resultText resultAbnormal'}>{relativeToThreshold.key === 'within' ? 'Normal' : 'Abnormal'}</span>
        </div>
        <div className="retakeButton" onClick={retakeVital}>
          <div className="retakeButtonIcon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
              <title>Icon</title>
              <path d="M88.58,20.77a1.77,1.77,0,0,0-1.26.38l-8,6.14a38.5,38.5,0,1,0,1.53,43.18,5,5,0,0,0-8.47-5.32,28.52,28.52,0,1,1-1-31.76l-8.05,6.2a1.82,1.82,0,0,0-.7,1.23A1.86,1.86,0,0,0,63,42.17a1.82,1.82,0,0,0,1.11.67l21.7,3.67.4,0a2.38,2.38,0,0,0,2.35-2l1.67-21.83A1.83,1.83,0,0,0,88.58,20.77Z" fill="#fff" />
            </svg>
          </div>
          <span className="buttonText">{I18n.get('Retake')}</span>
        </div>
      </div>
      <div className="ContainerResultBlur ActualBlur VitalResultBlurSize" />
    </>
  );
};

export default VitalResult;
