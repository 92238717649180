import AWS from 'aws-sdk';
import { Auth, Logger } from 'aws-amplify';
// import autoCreds from '../CredentialsModule';

const logger = new Logger('ConnectInit');

let embedded = false;

export async function initConnect() {
  try {
    const session = await Auth.currentSession();
    await Auth.currentCredentials();
    const { payload } = session.idToken;

    window.username = payload.email;
    window.account = payload['custom:xmitId'];
    window.rapidNumber = payload['custom:rapidPhone'];
    window.connectNumber = payload['custom:connectPhone'];
    await getSessionToken();

    // set the lambda payload for connect
    window._sessionParams = {
      username: payload.email,
    };

    // refresh login set to every hour
    // this can be replaced to happenen when addison help is invoked but needs to finish before going to help scene
    setInterval(() => {
      getSessionToken().then(_ => logger.debug('refreshed OneLogin session token')).catch(e => logger.error('failed to refresh session token: ', e));
    }, 3600000);
  } catch (e) {
    logger.warn('error initializing call functionality: ', e);
  }
}

async function getSessionToken() {
  // await autoCreds.deploy('Connect');
  const creds = await Auth.currentCredentials();
  logger.debug('creds: ', creds);
  const lambda = new AWS.Lambda({
    region: window.ADDISON_REGION,
    sessionToken: creds.sessionToken,
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey,
  });

  const sessionParams = window._sessionParams || {
    username: window.username,
  };

  const PhoneSession = {
    InvocationType: 'RequestResponse',
    FunctionName: 'arn:aws:lambda:us-east-1:479226928101:function:get-one-login-token-function-64L88P2O89HE', // jkeys 5-4-20,
    Payload: JSON.stringify(sessionParams),
  };

  PhoneSession.Payload = JSON.stringify(sessionParams);

  try {
    const lambdaData = await lambda.invoke(PhoneSession).promise();
    // logger.debug("lambdaData.Payload: ", lambdaData.Payload);
    const authData = JSON.parse(lambdaData.Payload).data;
    logger.debug('This is the app id -- ', authData.login_id);
    window.session_token = authData.session_token;
    window.appId = authData.login_id;
    const data = JSON.stringify({
      session_token: window.session_token,
    });

    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.addEventListener('readystatechange', function () {
      try {
        if (this.readyState === 4) {
          // waits for token from lambda then calls login redirect which is in an iframe
          embedded === false ? embedapp() : refreshLogin();
        }
      } catch (e) {
        logger.warn('error embedding iframe for OneLogin, call functionality disabled: ', e);
      }
    });

    //  session_via_api_token places cookie in browser allowing lambda to login to connect and use softphone
    xhr.open('POST', 'https://ecg-dev.onelogin.com/session_via_api_token', true);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader('cache-control', 'no-cache');

    xhr.send(data);
  } catch (e) {
    logger.warn(e);
  }
}

async function embedapp() {
  try {
    const x = document.createElement('IFRAME');
    // x.setAttribute("src", "https://ecg-dev.onelogin.com/client/apps/select/243283649");
    x.setAttribute('src', `https://ecg-dev.onelogin.com/client/apps/select/${window.appId}`);
    x.setAttribute('id', 'OneLoginURL');
    x.setAttribute('style', 'display:none; visibility:hidden; width:0; height:0; border:0; border:none');
    document.body.appendChild(x);
    embedded = true;
  } catch (e) {
    logger.warn('error creating OneLogin iframe');
  }
}

function refreshLogin() {
  try {
    const loginReplace = document.querySelector('IFRAME');
    loginReplace.remove();
    setTimeout(embedapp, 3750);
  } catch (e) {
    logger.warn('error refreshing oneLogin credentials: ', e);
  }
}
