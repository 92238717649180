/**
 * returns the voice used for the Polly speech generation, as a string
 * @param {string} languageCode the IETF language code, e.g. 'en-US' for American English,
 * or 'de-AT' for Austrian German
 * @returns 'Salli' | 'Marlene' 
 */
export function getVoiceFromLanguageCode(languageCode) {
    let _voice = 'Salli';
  
    // [[LOCALIZATION REQUIRED]]
    switch(languageCode) {
      case 'en-US':
      case 'en-AU':
      case 'en-GB':
      case 'en':
        _voice = 'Salli';
        break;
      case 'de-AT':
      case 'de-GE':
      case 'de-DE':
        _voice = 'Marlene';
        break;
      case 'de':
        _voice = 'Marlene';
        break;
      default:
        _voice = 'Salli';
    }
  
    return _voice;
  }